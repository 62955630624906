import React from 'react';

import { Link } from 'react-router-dom'
import axios from 'axios';
import auth from '../../../services/authentication'
import { Container, Row, Col, Card } from 'react-bootstrap'
import './channelList.css'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import ChannelLoader from '../../HomeSection/HomeComponent/Loader/ChannelLoader'
import MobileChannelLoader from '../../HomeSection/HomeComponent/Loader/MobileChannelLoader';
import MetaTags from 'react-meta-tags';
import {
  isMobile,
  isIOS,
  isAndroid,
  isTablet,
  browserName
} from "react-device-detect";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-122455299-1', { standardImplementation: true });


export default class ChannelList extends React.Component {
  state = {
    channels: [],
    Loading: true
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    axios.get(`https://prod.aawaz.com/web/channel-details/?all=True`, { auth: auth })
      .then(res => {
        const channels = res.data;
        this.setState({ channels: channels, Loading: false });
      })
  }

  render() {
    return (
      <>
        <MetaTags>
          <title>{"aawaz.com - Audio, Podcast, Stories in Hindi and English"}</title>
          <meta name="description" content={"aawaz.com is the largest spoken-word audio and podcast network in Indian languages. Listen to 100% Original Audio content in English and Hindi."} />
          <meta property="og:title" content={"aawaz.com - Audio, Podcast, Stories in Hindi and English"} />
          <meta property="og:image" content={"https://storage.googleapis.com/aawaz-stateless/2019/09/4c3e7c5e-aawaz-112x112.png"} />
        </MetaTags>
        {this.state.Loading ?
          <div style={{ textAlign: 'center' }}>
            {isMobile ? <MobileChannelLoader /> : <ChannelLoader />}
          </div>
          :
          <div>
            <Container className="cat_page-content">
              <h2 className="page-title">All Channels</h2>
              <Row>
                {this.state.channels.map(channel =>
                  <Col lg={3} xs={6} key={channel.uniqueSlug} className="category-grid2" >
                    <div className="ChannelBox" style={{}}>
                      <Link to={`/channel/` + channel.shortUrl}>
                        <img src={channel.featuredImageUrl ? browserName === "Mobile Safari" || browserName === "Safari" ? channel.featuredImageUrl.replace("webp", "jpg") : channel.featuredImageUrl : null} className="channel-image" />
                        <p className="cat-sec-name1">{channel.title}</p>
                      </Link>
                    </div>
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        }

      </>

    )
  }
}