import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Harshmariwala from './img/clients/GuestHarshmariwala.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestHarshmariwala extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={Harshmariwala} alt="Harshmariwala" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Harsh Mariwala,Founder & Chairman,Marico Ltd.</Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted"> Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        Harsh Mariwala, Founder and Chairman of Marico Ltd. is a business leader like no other. With a career spanning 3 decades and having overseen the growth of numerous much-loved household Indian brands like Parachute, Saffola, Livon and Kaya just to name a few,his journey has stories that can fill an entire book. On this special episode, he gives listeners a glimpse into his role, and shares candidly his hits and misses and life lessons with host Deepali Nair, on this episode, which also marks one year of the show! Popularly known as the CEO of CEOS, Harsh truly has a completely grounded perspective on life and disarming honesty, we rarely witness. Parachuting his way into the lives of so many people he also discusses his newly released book that he co-authored - 'Harsh Realities’ and the principles and values that have carried him to being who and where he is today.
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2021/08/BeingCeo-Mr.Harsh-Mariwala.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                    <Card.Text>Last updated 3 mins ago</Card.Text>

                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestHarshmariwala);