import React from "react";
import axios from "axios";
import auth from "../../../services/authentication";
import { Link } from "react-router-dom";
import { Row, Col, Container, Card } from "react-bootstrap";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import CategoryLoader from "../../HomeSection/HomeComponent/Loader/CategoryLoader";
import MobileChannelLoader from "../../HomeSection/HomeComponent/Loader/MobileChannelLoader";
import MobileCategiryLoader from "../../HomeSection/HomeComponent/Loader/MobileCategiryLoader";
import MetaTags from "react-meta-tags";
import {
  isMobile,
  isIOS,
  isAndroid,
  isTablet,
  browserName,
} from "react-device-detect";
import ReactGA from "react-ga";

ReactGA.initialize("UA-122455299-1", { debug: false });

class CategoryList extends React.Component {
  state = {
    categories: [],
    Loading: true,
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    axios
      .get(`https://prod.aawaz.com/api/category/`, { auth: auth })
      .then((res) => {
        const categories = res.data;
        this.setState({ categories: categories, Loading: false });
      });
  }

  render() {
    return (
      <>
        <MetaTags>
          <title>
            {"aawaz.com - Audio, Podcast, Stories in Hindi and English"}
          </title>
          <meta
            name="description"
            content={
              "aawaz.com is the largest spoken-word audio and podcast network in Indian languages. Listen to 100% Original Audio content in English and Hindi."
            }
          />
          <meta
            property="og:title"
            content={"aawaz.com - Audio, Podcast, Stories in Hindi and English"}
          />
          <meta
            property="og:image"
            content={
              "https://storage.googleapis.com/aawaz-stateless/2019/09/4c3e7c5e-aawaz-112x112.png"
            }
          />
        </MetaTags>
        {this.state.Loading ? (
          <div style={{ textAlign: "center" }}>
            {isMobile ? <MobileChannelLoader /> : <CategoryLoader />}
          </div>
        ) : (
          <div>
            <Container className="cat_page-content">
              <h2 className="page-title">All Categories</h2>
              <Row>
                {this.state.categories.map((category) => (
                  <Col
                    lg={2}
                    xs={6}
                    key={category.uniqueSlug}
                    className="category-grid2"
                  >
                    <div className="categoryBox" style={{}}>
                      <Link to={`/category/` + category.shortUrl}>
                        <img src={category.iconImageUrl} />
                        <p className="cat-sec-name1">{category.name}</p>
                      </Link>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        )}
      </>
    );
  }
}

export default CategoryList;
