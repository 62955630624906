import React, { useEffect } from "react";
import { withRouter, Link, matchPath, useParams } from "react-router-dom";

const Voice = () => {
  useEffect(() => {
    window.location.href = "https://voice.aawaz.com/";
  }, []);
  return <div></div>;
};

export default withRouter(Voice);
