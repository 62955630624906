import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Sanjaybehl from './img/clients/GuestSanjaybehl.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestSanjaybehl extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={Sanjaybehl} alt="Hiralchandrana" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Sanjay Behl, ex-CEO, Raymond

                                    </Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted"> Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        Sanjay Behl is a highly accomplished leader with a proven track record. A young student from Banaras with big aspirations, Sanjay started his professional journey with Hindustan Unilever. He has also operated in a leadership capacity at Nokia and Reliance in India and the Asia Pacific. As a CEO of Raymond in his last assignment, he catapulted the legacy textile and apparel company to become a leading fashion retail organisation with disruptive and sustainable growth in revenue, profitability and enterprise valuation. Currently, the CEO and Co-founder of a technology-based venture Nextqore, Sanjay has built a very rich and diverse expertise in business leadership and management. In this unfiltered chat, Sanjay shares some fun anecdotes from his journey and sacred mantras that he imbibed along the way.


                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2020/07/Ep-1-_-Sanjay-and-Deepali-_-16-June-_-22m-.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestSanjaybehl);