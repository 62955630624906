import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import ScrollToTop from "./ScrollToTop";
import "./index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";
import * as serviceWorker from "./serviceWorker";
import { store } from "./app/store";
import { Provider } from "react-redux";

import clevertap from "clevertap-web-sdk";

import ReactGA from "react-ga";
clevertap.init("WRK-867-Z66Z");
// clevertap.setLogLevel(4);

ReactGA.initialize("UA-122455299-1", { debug: false });
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ScrollToTop />
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
