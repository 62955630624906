/* global google */
import React, { Component, Suspense, lazy } from "react";
import {
  Route,
  Switch,
  withRouter,
  matchPath,
  Redirect,
} from "react-router-dom";
import { BarLoader } from "react-spinners";
import ContentLoader from "react-content-loader";
import { css } from "@emotion/core";
import {
  Container,
  Row,
  Col,
  Image,
  Accordion,
  Card,
  Form,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Modal,
} from "react-bootstrap";
// import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import FlagBacground from "./img/FlgBackgroud.jpeg";
import "./App.css";
import firebase from "firebase/compat/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
// import { initializeApp } from "firebase/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import Marquee from "react-double-marquee";
import moment from "moment";
import SwipeableBottomSheet from "react-swipeable-bottom-sheet";
import HeaderBrand from "./components/Mobile/Header/HeaderBrand";
import DesktopHeader from "./components/Desktop/Header/DesktopHeader";
import DesktopFooter from "./components/Desktop/Footer/footer/footerSec";

//  LOADER IMPORT
import HomepageLoader from "./components/HomeSection/HomeComponent/Loader/HomepageLoader";
import MobileHomepageLoader from "./components/HomeSection/HomeComponent/Loader/MobileHomepageLoader";
import MobileCategiryLoader from "./components/HomeSection/HomeComponent/Loader/MobileCategiryLoader";
import MobileChannelLoader from "./components/HomeSection/HomeComponent/Loader/MobileChannelLoader";
import ChannelLoader from "./components/HomeSection/HomeComponent/Loader/ChannelLoader";
import MobileLiveLoader from "./components/HomeSection/HomeComponent/Loader/MobileLiveLoader";
import LiveLoader from "./components/HomeSection/HomeComponent/Loader/LiveLoader";
import ShowpageLoader from "./components/HomeSection/HomeComponent/Loader/ShowpageLoader";
import MobileShowPageLoader from "./components/HomeSection/HomeComponent/Loader/MobileShowPageLoader";
import MobileEpisodeLoader from "./components/HomeSection/HomeComponent/Loader/MobileEpisodeLoader";
import AllreadLoader from "./components/HomeSection/HomeComponent/Loader/AllreadLoader";
import SinglereadLoader from "./components/HomeSection/HomeComponent/Loader/SinglereadLoader";
// import ScrollToTop from './components/Desktop/Footer/footer/ScrollToTop';
import SideBarNav from "./components/Desktop/Body/SideBar/SideBarNav";
import searchBtn from "./img/search.png";
import iosBtn from "./img/ios.png";
import androidBtn from "./img/android.png";
// Footer Navigation
import FooterNav from "./components/Mobile/Footer/FooterNav";

import About from "./components/Desktop/Footer/FooterPages/About.jsx";
import Disclaimer from "./components/Desktop/Footer/FooterPages/Disclaimer.jsx";
import TermsOfUse from "./components/Desktop/Footer/FooterPages/TermsOfUse.jsx";
import AlexaTermsOfUse from "./components/Desktop/Footer/FooterPages/AlexaTermsOfUser";
import PrivacyPolicy from "./components/Desktop/Footer/FooterPages/PrivacyPolicy.jsx";
import AlexaPrivacyPolicy from "./components/Desktop/Footer/FooterPages/AlexaPrivacyPolicy";
import Faq from "./components/Desktop/Footer/FooterPages/Faq";
import Contact from "./components/Desktop/Footer/FooterPages/Contact";
import Media from "./components/Desktop/Footer/FooterPages/Media.jsx";
import JioDisclaimer from "./components/Pages/JioTv/JioDisclaimer";
import JioTermsOfUse from "./components/Pages/JioTv/JioTermsOfUse";
import JioprivacyPolicy from "./components/Pages/JioTv/JioprivacyPolicy";
import DemoHome from "./components/Desktop/Body/SideBar/DemoHome";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import CategoryList from "./components/Pages/CategoryPage/CategoryList";
import "react-h5-audio-player/lib/styles.css";
import "./css/pages.css";
import styles from "./css/responsive.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import branch from "branch-sdk";
// import voiceBtn from "./img/icons/ic_search_voice.svg"

import ShowList from "./components/Pages/Livepages/ShowList";

// Guest pages and other landing pages
import MarketingUntoldStory from "./components/Pages/LandingPages/MarketingUntoldStory";
import BeingCEO from "./components/Pages/LandingPages/BeingCEO";
import GuestsAlok from "./components/Pages/GuestPages/GuestsAlok";
import GuestsAlokRajan from "./components/Pages/GuestPages/GuestAlokRajan";
import GuestSuparna from "./components/Pages/GuestPages/GuestSuparna";
import GuestsAmitmalik from "./components/Pages/GuestPages/GuestAmitmalik";
import GuestAniljain from "./components/Pages/GuestPages/GuestAniljain";
import GuestApurvapurohit from "./components/Pages/GuestPages/GuestApurvapurohit";
import GuestAshutoshgupta from "./components/Pages/GuestPages/GuestAshutoshgupta";
import GuestDamodarmall from "./components/Pages/GuestPages/GuestDamodarmall";
import GuestDebjanighosh from "./components/Pages/GuestPages/GuestDebjanighosh";
import GuestEtbiz from "./components/Pages/GuestPages/GuestEtbiz";
import GuestHarshmariwala from "./components/Pages/GuestPages/GuestHarshmariwala";
import GuestHiralchandrana from "./components/Pages/GuestPages/GuestHiralchandrana";
import GuestMainakdhar from "./components/Pages/GuestPages/GuestMainakdhar";
import GuestManishm from "./components/Pages/GuestPages/GuestManishm";
import GuestMeghatata from "./components/Pages/GuestPages/GuestMeghatata";
import GuestMukundvasudevan from "./components/Pages/GuestPages/GuestMukundvasudevan";
import GuestNandinidias from "./components/Pages/GuestPages/GuestNandinidias";
import GuestPauldupuis from "./components/Pages/GuestPages/GuestPauldupuis";
import Guestprabhjeetsingh from "./components/Pages/GuestPages/Guestprabhjeetsingh";
import GuestRadhikagupta from "./components/Pages/GuestPages/GuestRadhikagupta";
import GuestRaghavgupta from "./components/Pages/GuestPages/GuestRaghavgupta";
import GuestRajeshr from "./components/Pages/GuestPages/GuestRajeshr";
import GuestRajit from "./components/Pages/GuestPages/GuestRajit";
import GuestSalilmurthy from "./components/Pages/GuestPages/GuestSalilmurthy";
import GuestSameergarde from "./components/Pages/GuestPages/GuestSameergarde";
import GuestSanjaybehl from "./components/Pages/GuestPages/GuestSanjaybehl";
import GuestSarbvir from "./components/Pages/GuestPages/GuestSarbvir";
import GuestSridhar from "./components/Pages/GuestPages/GuestSridhar";
import GuestSuchetamahapatra from "./components/Pages/GuestPages/GuestSuchetamahapatra";
import GuestSudipghose from "./components/Pages/GuestPages/GuestSudipghose";
import GuestUmesh from "./components/Pages/GuestPages/GuestUmesh";
import GuestVinayagrawal from "./components/Pages/GuestPages/GuestVinayagrawal";
import GuestChandrakant from "./components/Pages/GuestPages/GuestChandrakant";
import GuestAshishMunjal from "./components/Pages/GuestPages/GuestAshishMunjal";
import GuestShilpaKhanna from "./components/Pages/GuestPages/GuestShilpaKhanna";
import GuestGirishRowjee from "./components/Pages/GuestPages/GuestGirishRowjee";
import GuestSaminaBano from "./components/Pages/GuestPages/GuestSaminaBano";
import GuestHarshvardhanLunia from "./components/Pages/GuestPages/GuestHarshvardhanLunia";
import GuestAmitabhBehar from "./components/Pages/GuestPages/GuestAmitabhBehar";
import GuestPrativaMohapatra from "./components/Pages/GuestPages/GuestPrativaMohapatra";
import ET from "./components/Pages/ET";
import IBM from "./components/Pages/IBM";
import Voice from "./components/Pages/Voice";

import EnglishShows from "./components/Pages/RedirectRoutes/EnglishShows";

// READ PAGE IMPORT //
import ReadPage from "./components/Pages/Read/ReadPage";
import singleRead from "./components/Pages/Read/singleRead";
import Sitemap from "./components/Pages/Sitemappage/Sitemap";
import LoginScreen from "./img/login-screen.jpg";
import DownloadBanner from "./img/download-mobi-banner.gif";
import backwardBtn from "./img/icons/ic_player_backward.svg";
import playBtn from "./img/icons/ic_player_play.svg";
import closeBtn from "./img/cancel.png";
import pauseBtn from "./img/icons/ic_player_pause.png";
import forwardBtn from "./img/icons/ic_player_forward.svg";
import plyrlkeIcn from "./img/icons/ic_player_like.svg";
import plyrlkeIcnGif from "./img/gif/like.gif";
import plyrEpiIcn from "./img/icons/ic_player_episodes.svg";
import Rewind10 from "./img/rewindWhite.png";
import RewindReverse10 from "./img/rewindReversewhite.png";
import playlistWhite from "./img/playlist-white.png";
import miniPlyBtn from "./img/play-white.png";
import commentSubmit from "./img/icons/comment-send-btn.png";
import Rollbar from "rollbar";
import miniPauseBtn from "./img/pause-white.png";
import {
  getAllBanners,
  getAllSections,
  getShowByURL,
  getCategoryByURL,
  getShowDetails,
  getChannelByURL,
  getEpisodeByURL,
  getSearch,
  createAnonUser,
  getCurrLoggedInUser,
  updateUserDetails,
  likeEpisode,
  dislikeEpisode,
  subscribe,
  unsubscribe,
  postEpisodeComment,
  getComments,
  postShowComment,
  getUserSubsciptions,
  updateLanguage,
  getLanguages,
  getAllChannel,
  getAllCategories,
  getEpisodeBySlug,
  getContinueListening,
  postContinueListening,
  postRecentSearch,
  getRecentSearch,
  FirebaseNotification,
} from "./services/GetServices";
import { postAnalytics } from "./services/Getanalytic";
import NoConnection from "./components/Pages/NoConnectionPage/NoConnection";
import NavTab from "./components/Pages/NavTabSelection/NavTabSelection";
import LoginPrompt from "./components/Pages/LoginPrompt/LoginPrompt";
import { isMobile, isIOS, isAndroid, isTablet } from "react-device-detect";
import Thumbnail from "./img/thumbnail.jpg";
import CategoryLoader from "./components/HomeSection/HomeComponent/Loader/CategoryLoader";
import Settings from "./components/Pages/ProfilePage/Settings";
import UserSettings from "./components/Pages/ProfilePage/UserSettings";
import YoloLogin from "./components/Pages/LoginPage/YoloLogin";
import thumbnail from "./img/thumbnail.jpg";
import { connect } from "react-redux";
import {
  setPause,
  setPlay,
  setAdplay,
  setAdPause,
} from "./features/playerSlice";
import Login from "./components/Pages/LoginPage/Login";
import ReactGA from "react-ga";
import ShareUi from "./components/Share";

// Cleaver Top import
import clevertap from "clevertap-web-sdk";

// import janMastamiBg from "../src/img/Janmastmi/janmastmiBg.jpg";
import GaneshChaturthiBg from "../src/img/GaneshFestival/ganpatiBg.jpg";
import MobileSearch from "./components/Pages/SearchPage/MobileSearch";

ReactGA.initialize("UA-122455299-1", { debug: false });
ReactGA.pageview(window.location.pathname + window.location.search);

const Home = lazy(() =>
  import("./components/HomeSection/HomeComponent/HomeComponent")
);
const Show = lazy(() => import("./components/Pages/ShowPage/Show"));
const ViewAll = lazy(() => import("./components/Pages/ViewAll/ViewAll"));
const Category = lazy(() => import("./components/Pages/CategoryPage/Category"));
const Channel = lazy(() => import("./components/Pages/ChannelPage/Channel"));
const ChannelList = lazy(() =>
  import("./components/Pages/ChannelPage/ChannelList")
);
const History = lazy(() => import("./components/Pages/HistoryPage/History"));
const MyShows = lazy(() => import("./components/Pages/MyShowsPage/MyShows"));
const MyShowLogin = lazy(() =>
  import("./components/Pages/MyShowsPage/MyShowLogin")
);
const Profile = lazy(() => import("./components/Pages/ProfilePage/Profile"));
const DesktopProfile = lazy(() =>
  import("./components/Pages/ProfilePage/DesktopProfile")
);
const EditProfile = lazy(() =>
  import("./components/Pages/ProfilePage/EditProfile")
);

// const MyProfile = lazy(() => import('./components/Pages/ProfilePage/MyProfile'));
const Search = lazy(() => import("./components/Pages/SearchPage/Search"));
const DesktopSearch = lazy(() =>
  import("./components/Pages/SearchPage/DesktopSearch")
);
const HeaderSearch = lazy(() =>
  import("./components/Desktop//Header/HeaderSearch")
);
const FullPlayer = lazy(() =>
  import("./components/Pages/AudioPlayer/FullPlayer")
);
const notFound = lazy(() => import("./components/Pages/NotFoundPage/NotFound"));
const ErrorPage = lazy(() => import("./components/Pages/ErrorPage/ErrorPage"));
const LandscapePage = lazy(() =>
  import("./components/Pages/ScreenPage/LandscapePage")
);
const SamplePage = lazy(() =>
  import("./components/Pages/ScreenPage/SamplePage")
);

const LiveShows = lazy(() => import("./components/Pages/Livepages/Shows"));
const Live = lazy(() => import("./components/Pages/Livepages/live"));

const config = {
  apiKey: "AIzaSyD8SQAsozaVMqu5UnYx2B-GQ-uxs5KE4B4",
  authDomain: "aawaz-227618.firebaseapp.com",
  databaseURL: "https://aawaz-227618.firebaseio.com",
  projectId: "aawaz-227618",
  storageBucket: "aawaz-227618.appspot.com",
  messagingSenderId: "146628349653",
  appId: "1:146628349653:web:f8579ba3167c393316dc5c",
  measurementId: "G-3HWWN504P6",
};

class App extends Component {
  constructor(props) {
    super();
    this.state = {
      LoginUser: JSON.parse(localStorage.getItem("login")),
      resume: false,
      rollbar: new Rollbar({
        accessToken: "1b8ad3543d8545ffa554ba7a12d7a2ec",
        captureUncaught: true,
        captureUnhandledRejections: true,
      }),
      sections: [],
      banners: [],
      categories: [],
      channels: [],
      category: {
        name: "",
        titleColor: "",
        backgroundColor: "",
        featuredImageUrl: "",
        shows: [
          {
            id: 9,
            title: "",
            featuredImageUrl: "",
            uniqueSlug: "",
            language: "",
          },
        ],
        uniqueSlug: "",
      },
      channel: {
        title: "Lifestyle",
        subtitle: "",
        shortUrl: "lifestyle",
        color: "#f9f9fb",
        featuredImageUrl:
          "https://storage.googleapis.com/test_balti/channel/chl_3cf5c4.jpg",
        bannerImageUrl:
          "https://storage.googleapis.com/test_balti/channel/chl_3cf5c4.jpg",
        categories: [],
        shows: [
          {
            title: "Not A Book Review",
            featuredImageUrl:
              "https://storage.googleapis.com/test_balti/show/shw_featured_image_463d61.jpg",
            uniqueSlug: "shw-463d61",
          },
        ],
        showCount: 8,
        uniqueSlug: "chl-3cf5c4",
        deepUrl: "https://www.aawaz.com/lifestyle",
        hasSubscribed: true,
      },
      show: {
        id: 4,
        category: {
          id: 1,
          name: "",
          uniqueSlug: "",
        },
        episodes: [
          {
            title: null,
            episodeDuration: null,
            audioFileUrl: null,
            shortDescription: null,
            episodeNo: null,
            uniqueSlug: null,
            shortUrl: null,
          },
        ],
        advertisements: [],
        artist: {
          id: 1,
          name: "",
          uniqueSlug: "",
        },
        title: "",
        featuredImageUrl: thumbnail,
        bannerImageUrl: thumbnail,
        description: "",
        uniqueSlug: "",
        season: "",
        deepUrl: "",
        hasSubscribed: true,
      },
      showComments: [],
      playlist: [
        {
          title: "",
          episodeDuration: null,
          audioFileUrl: null,
          shortDescription: "",
          episodeNo: "1",
          uniqueSlug: null,
        },
      ],
      playerVisible: false,
      searchAPIData: [],
      PlayerCurrShow: {
        title: "None",
        id: 0,
        language: "None",
        categories: [{ id: 2, name: "Business", uniqueSlug: "cat-8acf69" }],
      },
      currentMusicIndex: 0,
      isLoading: true,
      isFullPlayer: false,
      viewPlaylist: false,
      repeat: false,
      viewSpeed: false,
      currSpeed: 1,
      online: true,
      active: false,
      isSignedIn: false,
      logInPrompt: false,
      SharePrompt: false,
      DownloadModel: true,
      userLangPrefPrompt: true,
      userLangPrefBottomPrompt: false,
      showCommentPrompt: false,
      userComment: "",
      userLangPref: [],
      profileData: {},
      userSubscriptions: [],
      languages: [],
      ContinueListening: [],
      RecentSearchData: [],
      shareLink: "",
      adPlay: true,
    };
    this.player = React.createRef();

    this.logInfo = this.logInfo.bind(this);
    this.throwError = this.throwError.bind(this);
  }

  miniPlyBtn = (
    <Image fluid style={{ maxHeight: "20px" }} src={miniPlyBtn}></Image>
  );
  miniPauseBtn = (
    <Image fluid style={{ maxHeight: "20px" }} src={miniPauseBtn}></Image>
  );
  playBtnIcon = (<Image fluid src={playBtn}></Image>);
  pauseBtnIcon = (
    <Image fluid src={pauseBtn} style={{ width: "50px" }}></Image>
  );
  forwardBtnIcon = (<Image fluid src={forwardBtn}></Image>);
  backwardBtnIcon = (<Image fluid src={backwardBtn}></Image>);
  RewindReverse = (
    <Image fluid src={Rewind10} style={{ width: "20px" }}></Image>
  );
  RewindReverseForword = (
    <Image fluid src={RewindReverse10} style={{ width: "20px" }}></Image>
  );
  // DeviceCheck = navigator.userAgentData.platform

  uiConfig = {
    signInOptions: [
      {
        // Google provider must be enabled in Firebase Console to support one-tap
        // sign-up.
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // Required to enable ID token credentials for this provider.
        // This can be obtained from the Credentials page of the Google APIs
        // console. Use the same OAuth client ID used for the Google provider
        // configured with GCIP or Firebase Auth.
        clientId:
          "146628349653-bgebh7vc3bmbibh3pnp5mkfo77b754gt.apps.googleusercontent.com",
      },
    ],
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
  };

  logInfo() {
    // Example log event using the rollbar object.
    this.state.rollbar.info("react test log");
  }

  throwError() {
    // Example error, which will be reported to rollbar.
    throw new Error("react test error");
  }

  getCurrDate = () => {
    let d = moment().format();
    return d;
  };
  toggleUserLangPrefCard = () => {
    this.setState({ userLangPrefPrompt: !this.state.userLangPrefPrompt });
  };
  click = () => {
    this.setState({ active: true });
  };
  toggleUserLangPrefBottomPrompt = () => {
    this.setState({
      userLangPrefBottomPrompt: !this.state.userLangPrefBottomPrompt,
    });
  };

  onPrefLanguageChange = (value) => {
    let newArray = value;
    this.setState({ userLangPref: newArray });
  };

  toggleLoginPrompt = () => {
    this.setState({ logInPrompt: !this.state.logInPrompt });
  };
  togglesharePrompt = (link) => {
    this.setState({
      SharePrompt: !this.state.SharePrompt,
      shareLink: `${link}`,
    });
  };
  toggleDownloadModel = () => {
    this.setState({ DownloadModel: !this.state.DownloadModel });
  };
  isUserAnonymous = () => {
    return firebase.auth().currentUser.isAnonymous;
  };

  onUserSubscribe = () => {
    if (this.isUserAnonymous()) {
      this.toggleLoginPrompt();
    }
  };

  onUserLike = () => {
    if (this.isUserAnonymous()) {
      this.toggleLoginPrompt();
    }
  };

  onUserComment = () => {
    if (this.isUserAnonymous()) {
      return this.toggleLoginPrompt();
    }
    this.toggleCommentPrompt();
  };

  getSearchData = () => {
    // this.setState({ isLoading: true })
    getSearch()
      .then((res) => {
        // this.setState({ isLoading: false, searchAPIData: res.data })
        this.setState({ ...this.state, searchAPIData: res.data });
      })
      .catch((err) => {
        toast.error("Search Content API error!");
        // console.log(err);
      });
  };

  getBannerData = async () => {
    this.showLoader();
    let token = await firebase.auth().currentUser.getIdToken();
    let Lang = this.state.profileData.languagePreferences;
    getAllBanners()
      .then((response) => {
        if (this.state.profileData.languagePreferences.length === 0) {
          this.setState({ ...this.state, banners: response.data }, () => {
            this.ContinueListening();
            this.getSectionsData(token);
          });
        } else {
          let lang1 = response.data.filter((item) => {
            return this.state.profileData.languagePreferences.some((data) => {
              return data === item.language;
            });
          });
          // let lang1 = response.data.results.filter(language => language.language == this.state.profileData.languagePreferences[0])
          // let lang2 = response.data.results.filter(language => language.language == this.state.profileData.languagePreferences[1])
          // let lang3 = response.data.results.filter(language => language.language == this.state.profileData.languagePreferences[2])
          // let lang4 = response.data.results.filter(language => language.language == this.state.profileData.languagePreferences[3])
          // let con1 = lang1.concat(lang2)
          // let con2 = con1.concat(lang3)
          // let con3 = con2.concat(lang4)
          this.setState({ ...this.state, banners: lang1 }, () => {
            this.ContinueListening();
            this.getSectionsData(token);
          });
        }
      })
      .catch((err) => {
        this.props.history.push("/error");
        this.hideLoader();
      });
  };

  getSectionsData = (token) => {
    this.showLoader();
    getAllSections(token)
      .then((response) => {
        this.setState({ ...this.state, sections: response.data.results }, () =>
          this.hideLoader()
        );
      })
      .catch((err) => {
        this.hideLoader();
        this.props.history.push("/error");
      });
  };

  getAllCategoriesData = () => {
    this.showLoader();

    getAllCategories()
      .then((response) => {
        this.setState(
          { ...this.state, categories: response.data.results },
          () => {}
        );
      })
      .catch((err) => {
        this.hideLoader();
        this.props.history.push("/error");
      });
  };
  ContinueListening = async () => {
    let token = await firebase.auth().currentUser.getIdToken();
    if (firebase.auth().currentUser.isAnonymous) {
      return;
    } else {
      await getContinueListening(this.state.profileData.id, token)
        .then((response) => {
          // console.log("continue", response.data)
          this.setState({ ContinueListening: response.data });
        })

        .catch((err) => {
          // this.hideLoader()
          // console.log("continue err", err);
        });
    }
    // console.log("true", firebase.auth().currentUser.isAnonymous)
  };
  PostContinueListening = async (showSlug, epiSlug, playDuration) => {
    let token = await firebase.auth().currentUser.getIdToken();
    if (firebase.auth().currentUser.isAnonymous) {
      return;
    } else {
      postContinueListening(
        this.state.profileData.id,
        token,
        showSlug,
        epiSlug,
        playDuration
      )
        .then((response) => {
          // console.log("continue post", response.data)
        })

        .catch((err) => {
          // this.hideLoader()
          // console.log("continue post err", err);
        });
    }
  };

  getCategoryData = (shortUrl) => {
    if (this.state.category.shortUrl === shortUrl) {
      // window.Moengage.track_event("category_details", {
      //   event_time: this.getCurrDate(),
      //   category_id: this.state.category.id || "none",
      //   category_name: this.state.category.name || "none",
      //   platform: "web",
      // });
      clevertap.event.push("category_details", {
        event_time: this.getCurrDate(),
        category_id: this.state.category.id || "none",
        category_name: this.state.category.name || "none",
        platform: "web",
      });
      ReactGA.event({
        category: "Category Page",
        action: this.state.category.name,
        label: "Click on Category Details",
        value: 29,
      });

      // return;
    }
    this.showLoader();
    getCategoryByURL(shortUrl)
      .then((response) => {
        if (response.data.length === 0) {
          toast.error(`Couldn't find category ${shortUrl}!`);
          this.props.history.push("/");
        } else {
          this.setState({ ...this.state, category: response.data }, () => {
            this.props.history.push(`/category/${shortUrl}`);
            // window.Moengage.track_event("category_details", {
            //   event_time: this.getCurrDate(),
            //   category_id: this.state.category.id || "none",
            //   category_name: this.state.category.name || "none",
            //   platform: "web",
            // });
            clevertap.event.push(`/category/${shortUrl}`, {
              event_time: this.getCurrDate(),
              category_id: this.state.category.id || "none",
              category_name: this.state.category.name || "none",
              platform: "web",
            });
            ReactGA.event({
              category: "Category Page",
              action: this.state.category.name,
              label: "Click on Category Details",
              value: 29,
            });
          });
        }
        this.hideLoader();
      })
      .catch((err) => {
        this.hideLoader();
        toast.error(`Couldn't find category ${shortUrl}!`);
        this.props.history.push("/");
      });
  };

  getChannelData = async (shortUrl) => {
    if (this.state.channel.shortUrl === shortUrl) {
      // window.Moengage.track_event("channel_details", {
      //   event_time: this.getCurrDate(),
      //   channel_id: this.state.channel.id || "none",
      //   channel_name: this.state.channel.title || "none",
      //   platform: "web",
      // });
      clevertap.event.push("channel_details", {
        event_time: this.getCurrDate(),
        channel_id: this.state.channel.id || "none",
        channel_name: this.state.channel.title || "none",
        platform: "web",
      });
      ReactGA.event({
        category: "Channel Page",
        action: this.state.channel.title,
        label: "Click on Channel Details",
        value: 28,
      });
      // return;
    }
    this.showLoader();
    let token = await firebase.auth().currentUser.getIdToken();
    getChannelByURL(shortUrl, token)
      .then((response) => {
        if (response.data.results.length === 0) {
          toast.error(`Couldn't find channel ${shortUrl}!`);
          this.props.history.push("/");
        } else {
          this.setState(
            { ...this.state, channel: response.data.results[0] },
            () => {
              this.props.history.push(`/channel/${shortUrl}`);
              // window.Moengage.track_event("channel_details", {
              //   event_time: this.getCurrDate(),
              //   channel_id: this.state.channel.id || "none",
              //   channel_name: this.state.channel.title || "none",
              //   platform: "web",
              // });
              clevertap.event.push("channel_details", {
                event_time: this.getCurrDate(),
                channel_id: this.state.channel.id || "none",
                channel_name: this.state.channel.title || "none",
                platform: "web",
              });
              ReactGA.event({
                category: "Channel Page",
                action: this.state.channel.title,
                label: "Click on Channel Details",
                value: 28,
              });
            }
          );
        }
        this.hideLoader();
      })
      .catch((err) => {
        this.hideLoader();
        toast.error(`Couldn't find channel ${shortUrl}!`);
        this.props.history.push("/");
      });
  };

  getEpisodeShowData = async (showShortUrl, play = null) => {
    if (this.state.show.showShortUrl === showShortUrl) {
      // window.Moengage.track_event("show_details", {
      //   event_time: this.getCurrDate(),
      //   show_id: this.state.show.uniqueSlug,
      //   show_name: this.state.show.title,
      //   // "category_id": this.state.show.categories[0].id || 'none',
      //   // "category_name": this.state.show.categories[0].name || 'none',
      //   platform: "web",
      // });
      clevertap.event.push("show_details", {
        event_time: this.getCurrDate(),
        show_id: this.state.show.uniqueSlug,
        show_name: this.state.show.title,
        // "category_id": this.state.show.categories[0].id || 'none',
        // "category_name": this.state.show.categories[0].name || 'none',
        platform: "web",
      });
      ReactGA.event({
        category: "Show Page",
        action: this.state.show.title,
        label: "Click on Show Details",
        value: 27,
      });

      return;
    }

    this.showLoader();
    let token = await firebase.auth().currentUser.getIdToken();
    getShowByURL(showShortUrl, token)
      .then((response) => {
        if (response.data.results.length === 0) {
          toast.error(`Couldn't find show ${showShortUrl}!`);
          this.props.history.push("/");
        } else {
          this.setState(
            { ...this.state, show: response.data.results[0] },
            () => {
              getComments(this.state.show.uniqueSlug)
                .then((res) => {
                  this.setState({ showComments: res.data.comments });
                })
                .catch((err) => {
                  toast.error("Couldn't fetching show comments!");
                  // console.log(err);
                });

              // window.Moengage.track_event("show_details", {
              //   event_time: this.getCurrDate(),
              //   show_id: this.state.show.uniqueSlug,
              //   show_name: this.state.show.title,
              //   category_id: this.state.show.categories[0].id || "none",
              //   category_name: this.state.show.categories[0].name || "none",
              //   platform: "web",
              // });
              clevertap.event.push("show_details", {
                event_time: this.getCurrDate(),
                show_id: this.state.show.uniqueSlug,
                show_name: this.state.show.title,
                category_id: this.state.show.categories[0].id || "none",
                category_name: this.state.show.categories[0].name || "none",
                platform: "web",
              });
              ReactGA.event({
                category: "Show Page",
                action: this.state.show.title,
                label: "Click on Show Details",
                value: 27,
              });

              if (play) {
                this.addPlayerData(this.state.show, 0);
              }
            }
          );
        }
        this.hideLoader();
      })
      .catch((err) => {
        this.hideLoader();
        toast.error(`Couldn't find show ${showShortUrl}!`);
        this.props.history.push("/");
      });
  };

  getShowData = async (showShortUrl, play = null) => {
    this.setState({ ...this.state, show: [] });
    // console.log(({ showShortUrl: showShortUrl, play: play, shrtUrlInState: this.state.show.showShortUrl }))
    if (this.state.show.ShortUrl === showShortUrl) {
      // window.Moengage.track_event("show_details", {
      //   event_time: this.getCurrDate(),
      //   show_id: this.state.show.uniqueSlug,
      //   show_name: this.state.show.title,
      //   // "category_id": this.state.show.categories[0].id || 'none',
      //   // "category_name": this.state.show.categories[0].name || 'none',
      //   platform: "web",
      // });
      clevertap.event.push("show_details", {
        event_time: this.getCurrDate(),
        show_id: this.state.show.uniqueSlug,
        show_name: this.state.show.title,
        // "category_id": this.state.show.categories[0].id || 'none',
        // "category_name": this.state.show.categories[0].name || 'none',
        platform: "web",
      });
      ReactGA.event({
        category: "Show Page",
        action: this.state.show.title,
        label: "Click on Show Details",
        value: 27,
      });

      return;
    }

    this.showLoader();
    let token = await firebase.auth().currentUser.getIdToken();
    getShowByURL(showShortUrl, token)
      .then((response) => {
        if (response.data.results.length === 0) {
          toast.error(`Couldn't find show ${showShortUrl}!`);
          this.props.history.push("/");
        } else {
          this.setState(
            { ...this.state, show: response.data.results[0] },
            () => {
              getComments(this.state.show.uniqueSlug)
                .then((res) => {
                  this.setState({ showComments: res.data.comments });
                })
                .catch((err) => {
                  toast.error("Couldn't fetching show comments!");
                  // console.log(err);
                });

              // window.Moengage.track_event("show_details", {
              //   event_time: this.getCurrDate(),
              //   show_id: this.state.show.uniqueSlug,
              //   show_name: this.state.show.title,
              //   category_id: this.state.show.categories[0].id || "none",
              //   category_name: this.state.show.categories[0].name || "none",
              //   platform: "web",
              // });
              clevertap.event.push("show_details", {
                event_time: this.getCurrDate(),
                show_id: this.state.show.uniqueSlug,
                show_name: this.state.show.title,
                category_id: this.state.show.categories[0].id || "none",
                category_name: this.state.show.categories[0].name || "none",
                platform: "web",
              });
              ReactGA.event({
                category: "Show Page",
                action: this.state.show.title,
                label: "Click on Show Details",
                value: 27,
              });

              if (play) {
                this.addPlayerData(this.state.show, 0);
              }
            }
          );
        }
        this.hideLoader();
      })
      .catch((err) => {
        this.hideLoader();
        toast.error(`Couldn't find show ${showShortUrl}!`);
        this.props.history.push("/");
      });
  };
  getShowDetailsData = async (showShortUrl, play = null) => {
    this.setState({ ...this.state, show: [] });
    // console.log(({ showShortUrl: showShortUrl, play: play, shrtUrlInState: this.state.show.showShortUrl }))
    if (this.state.show.ShortUrl === showShortUrl) {
      // window.Moengage.track_event("show_details", {
      //   event_time: this.getCurrDate(),
      //   show_id: this.state.show.uniqueSlug,
      //   show_name: this.state.show.title,
      //   // "category_id": this.state.show.categories[0].id || 'none',
      //   // "category_name": this.state.show.categories[0].name || 'none',
      //   platform: "web",
      // });
      clevertap.event.push("show_details", {
        event_time: this.getCurrDate(),
        show_id: this.state.show.uniqueSlug,
        show_name: this.state.show.title,
        // "category_id": this.state.show.categories[0].id || 'none',
        // "category_name": this.state.show.categories[0].name || 'none',
        platform: "web",
      });
      ReactGA.event({
        category: "Show Page",
        action: this.state.show.title,
        label: "Click on Show Details",
        value: 27,
      });

      return;
    }

    this.showLoader();
    let token = await firebase.auth().currentUser.getIdToken();
    getShowDetails(showShortUrl, token)
      .then((response) => {
        if (response.data.results.length === 0) {
          toast.error(`Couldn't find show ${showShortUrl}!`);
          this.props.history.push("/");
        } else {
          this.setState(
            { ...this.state, show: response.data.results[0] },
            () => {
              getComments(this.state.show.uniqueSlug)
                .then((res) => {
                  this.setState({ showComments: res.data.comments });
                })
                .catch((err) => {
                  toast.error("Couldn't fetching show comments!");
                  // console.log(err);
                });

              // window.Moengage.track_event("show_details", {
              //   event_time: this.getCurrDate(),
              //   show_id: this.state.show.uniqueSlug,
              //   show_name: this.state.show.title,
              //   category_id: this.state.show.categories[0].id || "none",
              //   category_name: this.state.show.categories[0].name || "none",
              //   platform: "web",
              // });
              clevertap.event.push("show_details", {
                event_time: this.getCurrDate(),
                show_id: this.state.show.uniqueSlug,
                show_name: this.state.show.title,
                category_id: this.state.show.categories[0].id || "none",
                category_name: this.state.show.categories[0].name || "none",
                platform: "web",
              });
              ReactGA.event({
                category: "Show Page",
                action: this.state.show.title,
                label: "Click on Show Details",
                value: 27,
              });
            }
          );
        }
        this.hideLoader();
      })
      .catch((err) => {
        this.hideLoader();
        toast.error(`Couldn't find show ${showShortUrl}!`);
        this.props.history.push("/");
      });
  };
  getEpisodeByUniqueSlug = async (
    showShortUrl,
    uniqueSlug,
    Itemdata,
    episodeShortUrl
  ) => {
    let checkIfAlreadyExists =
      this.state.PlayerCurrShow.shortUrl === showShortUrl ? true : false;
    if (!checkIfAlreadyExists) {
      // console.log("episodeShortUrl", showShortUrl, episodeShortUrl);
      let token = await firebase.auth().currentUser.getIdToken();
      if (showShortUrl.length > 1 && episodeShortUrl.length < 1) {
        // console.log("showShortUrl", showShortUrl)
        getShowByURL(showShortUrl, token)
          .then((response) => {
            if (response.status === 200) {
              let data = response.data.results[0];
              let changeIndexNumber = data.episodes.findIndex(
                (item) => item.uniqueSlug === uniqueSlug
              );
              this.setState({
                ...this.state,
                playlist: data.episodes,
                PlayerCurrShow: data,
                currentMusicIndex:
                  changeIndexNumber !== -1 ? changeIndexNumber : 0,
                isLoading: false,
                playerVisible: true,
              });
            }
            // }
          })
          .catch((err) => {
            this.hideLoader();
            toast.error(`Couldn't find show ${showShortUrl}!`);
          });
      } else if (episodeShortUrl.length > 1) {
        getEpisodeByURL(episodeShortUrl, token)
          .then((response) => {
            if (response.status === 200) {
              let data = response.data.results[0];
              // console.log("data,data", Itemdata)
              let changeIndexNumber = response.data.results.findIndex(
                (item) => item.uniqueSlug === uniqueSlug
              );
              this.setState({
                ...this.state,
                playlist: response.data.results,
                PlayerCurrShow: {
                  title: "None",
                  id: 0,
                  language: "None",
                  categories: [
                    { id: 2, name: "Business", uniqueSlug: "cat-8acf69" },
                  ],
                },
                currentMusicIndex:
                  changeIndexNumber !== -1 ? changeIndexNumber : 0,
                isLoading: false,
                playerVisible: true,
              });
            }
            this.trackPlay();
          })
          .catch((err) => {
            this.hideLoader();
            toast.error(`Couldn't find episode ${episodeShortUrl}!`);
          });
      }
    } else {
      if (uniqueSlug !== "") {
        this.state.playlist.forEach((s, i) => {
          if (s.uniqueSlug === uniqueSlug) {
            this.setState({
              ...this.state,
              currentMusicIndex: i,
              isLoading: false,
              playerVisible: true,
            });
          }
        });
      } else {
        console.log("episode fetch skipped");
      }
      console.log("episode fetch skipped");
      return;
    }
  };
  getEpisodeDataForEpiBanner = async (episodeshortUrl) => {
    let checkIfAlreadyExists = this.state.playlist.every(
      (item) => item.shortUrl !== episodeshortUrl
    );
    // console.log("ceg", episodeshortUrl);
    if (checkIfAlreadyExists) {
      let token = await firebase.auth().currentUser.getIdToken();
      getEpisodeByURL(episodeshortUrl, token)
        .then((response) => {
          if (response.data.results.length === 0) {
            toast.error(`Couldn't find episode ${episodeshortUrl}!`);
            this.props.history.push("/");
          } else {
            // console.log("getEpisodeByURL : ", response.data.results);
            this.setState({
              ...this.state,
              playlist: response.data.results,
              currentMusicIndex: 0,
              isLoading: false,
              playerVisible: true,
              PlayerCurrShow: {
                title: "None",
                id: 0,
                language: "None",
                categories: [
                  { id: 2, name: "Business", uniqueSlug: "cat-8acf69" },
                ],
              },
            });
          }
          // this.hideLoader()
        })
        .catch((err) => {
          this.hideLoader();
          toast.error(`Couldn't find episode ${episodeshortUrl}!`);
          this.props.history.push("/");
        });
    } else {
      if (episodeshortUrl !== "") {
        this.state.playlist.forEach((s, i) => {
          if (s.shortUrl === episodeshortUrl) {
            this.setState({
              ...this.state,
              currentMusicIndex: i,
              isLoading: false,
              playerVisible: true,
            });
          }
        });
      } else {
        console.log("episode fetch skipped");
      }
      console.log("episode fetch skipped");
      return;
    }
  };
  getShowEpisodes = async (showShortUrl, shortUrl) => {
    // console.log("url sss", showShortUrl, shortUrl)
    let checkIfAlreadyExists =
      this.state.PlayerCurrShow.shortUrl === showShortUrl ? true : false;
    if (!checkIfAlreadyExists) {
      let token = await firebase.auth().currentUser.getIdToken();
      getShowByURL(showShortUrl, token)
        .then((response) => {
          if (response.status === 200) {
            // console.log("ressssss", response)
            // if (response.data.results.length === 0) {
            // 	toast.error(`Couldn't find show ${showShortUrl}!`)
            // 	this.props.history.push('/')
            // 	console.log("if")
            // }
            // else {
            let data = response.data.results[0];
            let changeIndexNumber = data.episodes.findIndex(
              (item) => item.shortUrl === shortUrl
            );
            this.setState({
              ...this.state,
              show: response.data.results[0],
              playlist: data.episodes,
              PlayerCurrShow: data,
              currentMusicIndex:
                changeIndexNumber !== -1 ? changeIndexNumber : 0,
              isLoading: false,
              playerVisible: true,
            });
            // console.log("else dtate", data.episodes, changeIndexNumber, data)
          }
          // }
        })
        // .then((res) => {
        // 	console.log("res", res)
        // })
        .catch((err) => {
          this.hideLoader();
          toast.error(`Couldn't find show ${showShortUrl}!`);
          // console.log("err2:", err)
          // this.props.history.push('/')
        });
    } else {
      let changeIndexNumber = this.state.playlist.findIndex(
        (item) => item.shortUrl === shortUrl
      );
      this.setState({
        ...this.state,
        currentMusicIndex: changeIndexNumber !== -1 ? changeIndexNumber : 0,
      });
      // return;
    }
  };

  trackPlay = () => {
    if (this.player.current.audio.current.currentTime > 0) {
      return;
    }
    // window.Moengage.track_event("play", {
    //   event_time: this.getCurrDate(),
    //   user_id: firebase.auth().currentUser.uid,
    //   episode_number:
    //     this.state.playlist[this.state.currentMusicIndex].episodeNo,
    //   episode_id: this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
    //   episode_name: this.state.playlist[this.state.currentMusicIndex].title,
    //   show_id: this.state.PlayerCurrShow.id,
    //   show_name: this.state.PlayerCurrShow.title,
    //   content_language: this.state.PlayerCurrShow.language,
    //   os: window.navigator.platform,
    //   artist_name: this.state.PlayerCurrShow.artist
    //     ? this.state.PlayerCurrShow.artist.name
    //     : "NA",
    //   deep_link: this.state.PlayerCurrShow.deepUrl,
    //   repeat_status: !this.state.repeat ? 0 : 1,
    //   music_player_time: `${Math.floor(
    //     this.player.current.audio.current.currentTime / 60
    //   )}:${Math.floor(this.player.current.audio.current.currentTime)}`,
    //   // "25%": Math.ceil((this.player.current.audio.current.currentTime / this.player.current.audio.current.duration) * 100) >= 25 ? true : false,
    //   // "50%": Math.ceil((this.player.current.audio.current.currentTime / this.player.current.audio.current.duration) * 100) >= 50 ? true : false,
    //   // "75%": Math.ceil((this.player.current.audio.current.currentTime / this.player.current.audio.current.duration) * 100) >= 75 ? true : false
    // });

    clevertap.event.push("play", {
      event_time: this.getCurrDate(),
      user_id: firebase.auth().currentUser.uid,
      episode_number:
        this.state.playlist[this.state.currentMusicIndex].episodeNo,
      episode_id: this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
      episode_name: this.state.playlist[this.state.currentMusicIndex].title,
      show_id: this.state.PlayerCurrShow.id,
      show_name: this.state.PlayerCurrShow.title,
      content_language: this.state.PlayerCurrShow.language,
      os: window.navigator.platform,
      artist_name: this.state.PlayerCurrShow.artist
        ? this.state.PlayerCurrShow.artist.name
        : "NA",
      deep_link: this.state.PlayerCurrShow.deepUrl,
      repeat_status: !this.state.repeat ? 0 : 1,
      music_player_time: `${Math.floor(
        this.player.current.audio.current.currentTime / 60
      )}:${Math.floor(this.player.current.audio.current.currentTime)}`,
    });
    let data = {
      event_name: "play",
      event_time: this.getCurrDate(),
      user_id: firebase.auth().currentUser.uid,
      episode_number:
        this.state.playlist[this.state.currentMusicIndex].episodeNo,
      episode_id: this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
      episode_name: this.state.playlist[this.state.currentMusicIndex].title,
      show_id:
        this.state.PlayerCurrShow.uniqueSlug === undefined
          ? this.state.playlist[this.state.currentMusicIndex].showUniqueSlug
          : this.state.PlayerCurrShow.uniqueSlug,
      show_name: this.state.PlayerCurrShow.title,
      content_language: this.state.PlayerCurrShow.language,
      category_id:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].id
          : "none",
      category_name:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].name
          : "none",
      platform_name: "web",
      repeat_status: !this.state.repeat ? 0 : 1,
      music_player_time: this.player.current.audio.current.currentTime,
      episode_publish_date:
        this.state.playlist[this.state.currentMusicIndex].publishedOn,
      stream_percentage: `${Math.ceil(
        (this.player.current.audio.current.currentTime /
          this.player.current.audio.current.duration) *
          100
      )}`,
    };
    postAnalytics(data)
      .then((res) => {
        // console.log("anakytics res", res)
      })
      .catch((err) => {
        // console.log("anakytics err", err);
      });
    ReactGA.event({
      category: this.state.PlayerCurrShow.title,
      action:
        "(" +
        this.state.playlist[this.state.currentMusicIndex].episodeNo +
        ") " +
        this.state.playlist[this.state.currentMusicIndex].title,
      label: "Click on Play of episode",
      value: 18,
    });
  };
  // console.log("Clicked Pause button")
  trackPause = () => {
    // this.setState({ resume: false })
    this.props.setPause();
    // console.log("check props", setPlayPause)
    this.PostContinueListening(
      this.state.PlayerCurrShow.uniqueSlug || "",
      this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
      Math.floor(this.player.current.audio.current.currentTime)
    );
    if (this.player.current.audio.current.currentTime === 0) {
      return;
    }
    // window.Moengage.track_event("pause", {
    //   event_time: this.getCurrDate(),
    //   user_id: firebase.auth().currentUser.uid,
    //   episode_id: this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
    //   episode_name: this.state.playlist[this.state.currentMusicIndex].title,
    //   repeat_status: !this.state.repeat ? 0 : 1,
    //   show_id: this.state.PlayerCurrShow.id,
    //   show_name: this.state.PlayerCurrShow.title,
    //   content_language: this.state.PlayerCurrShow.language,
    //   category_id:
    //     this.state.PlayerCurrShow.categories.length !== 0
    //       ? this.state.PlayerCurrShow.categories[0].id
    //       : "none",
    //   category_name:
    //     this.state.PlayerCurrShow.categories.length !== 0
    //       ? this.state.PlayerCurrShow.categories[0].name
    //       : "none",
    //   music_player_time: `${Math.floor(
    //     this.player.current.audio.current.currentTime / 60
    //   )}:${Math.floor(this.player.current.audio.current.currentTime)}`,
    // });

    clevertap.event.push("pause", {
      event_time: this.getCurrDate(),
      user_id: firebase.auth().currentUser.uid,
      episode_id: this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
      episode_name: this.state.playlist[this.state.currentMusicIndex].title,
      repeat_status: !this.state.repeat ? 0 : 1,
      show_id: this.state.PlayerCurrShow.id,
      show_name: this.state.PlayerCurrShow.title,
      content_language: this.state.PlayerCurrShow.language,
      category_id:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].id
          : "none",
      category_name:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].name
          : "none",
      music_player_time: `${Math.floor(
        this.player.current.audio.current.currentTime / 60
      )}:${Math.floor(this.player.current.audio.current.currentTime)}`,
    });
    let data = {
      event_name: "pause",
      event_time: this.getCurrDate(),
      user_id: firebase.auth().currentUser.uid,
      episode_number:
        this.state.playlist[this.state.currentMusicIndex].episodeNo,
      episode_id: this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
      episode_name: this.state.playlist[this.state.currentMusicIndex].title,
      repeat_status: !this.state.repeat ? 0 : 1,
      show_id:
        this.state.PlayerCurrShow.uniqueSlug === undefined
          ? this.state.playlist[this.state.currentMusicIndex].showUniqueSlug
          : this.state.PlayerCurrShow.uniqueSlug,
      show_name: this.state.PlayerCurrShow.title,
      content_language: this.state.PlayerCurrShow.language,
      platform_name: "web",
      category_id:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].id
          : "none",
      category_name:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].name
          : "none",
      music_player_time: this.player.current.audio.current.currentTime,
      episode_publish_date:
        this.state.playlist[this.state.currentMusicIndex].publishedOn,
      stream_percentage: `${Math.ceil(
        (this.player.current.audio.current.currentTime /
          this.player.current.audio.current.duration) *
          100
      )}%`,
    };
    postAnalytics(data)
      .then((res) => {
        // console.log("anakytics res pause", res)
      })
      .catch((err) => {
        // console.log("anakytics err", err);
      });
    ReactGA.event({
      category: this.state.PlayerCurrShow.title,
      action:
        "(" +
        this.state.playlist[this.state.currentMusicIndex].episodeNo +
        ") " +
        this.state.playlist[this.state.currentMusicIndex].title,
      label: "Click on Pause of episode",
      value: 20,
    });
  };
  // hidePlayer(){
  // 	this.setState({playerVisible: false})
  // }

  trackResume = () => {
    this.props.setPlay();
    if (
      this.state.show.advertisements &&
      this.state.show.advertisements.length > 0 &&
      this.state.adPlay
    ) {
      this.handelAdsPlay();
    }
    if (this.player.current.audio.current.currentTime === 0) {
      return;
    }
    // if (this.player.current.audio.current.currentTime !== 0) {
    // 	this.player.current.audio.current.play();
    // }
    // if (this.state.Ads.audioUrl && this.player.current.audio.current.src !== this.state.Ads.audioUrl) {
    // 	this.setState({ adsPlay: true })
    // }
    if (
      this.state.playlist[this.state.currentMusicIndex].playheadPosition > 0
    ) {
      this.player.current.audio.current.currentTime =
        this.state.playlist[this.state.currentMusicIndex].playheadPosition;
      this.player.current.audio.current.play();
    }

    if (this.player.current.audio.current.currentTime === 0) {
      return;
    }
    // window.Moengage.track_event("resume", {
    //   event_time: this.getCurrDate(),
    //   user_id: firebase.auth().currentUser.uid,
    //   episode_id: this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
    //   episode_name: this.state.playlist[this.state.currentMusicIndex].title,
    //   repeat_status: !this.state.repeat ? 0 : 1,
    //   show_id: this.state.PlayerCurrShow.id,
    //   show_name: this.state.PlayerCurrShow.title,
    //   content_language: this.state.PlayerCurrShow.language,
    //   category_id:
    //     this.state.PlayerCurrShow.categories.length !== 0
    //       ? this.state.PlayerCurrShow.categories[0].id
    //       : "none",
    //   category_name:
    //     this.state.PlayerCurrShow.categories.length !== 0
    //       ? this.state.PlayerCurrShow.categories[0].name
    //       : "none",
    //   music_player_time: `${Math.floor(
    //     this.player.current.audio.current.currentTime / 60
    //   )}:${Math.floor(this.player.current.audio.current.currentTime)}`,
    //   episode_number:
    //     this.state.playlist[this.state.currentMusicIndex].episodeNo,
    // });
    clevertap.event.push("resume", {
      event_time: this.getCurrDate(),
      user_id: firebase.auth().currentUser.uid,
      episode_id: this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
      episode_name: this.state.playlist[this.state.currentMusicIndex].title,
      repeat_status: !this.state.repeat ? 0 : 1,
      show_id: this.state.PlayerCurrShow.id,
      show_name: this.state.PlayerCurrShow.title,
      content_language: this.state.PlayerCurrShow.language,
      category_id:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].id
          : "none",
      category_name:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].name
          : "none",
      music_player_time: `${Math.floor(
        this.player.current.audio.current.currentTime / 60
      )}:${Math.floor(this.player.current.audio.current.currentTime)}`,
      episode_number:
        this.state.playlist[this.state.currentMusicIndex].episodeNo,
    });
    let data = {
      event_name: "resume",
      event_time: this.getCurrDate(),
      user_id: firebase.auth().currentUser.uid,
      episode_id: this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
      episode_name: this.state.playlist[this.state.currentMusicIndex].title,
      repeat_status: !this.state.repeat ? 0 : 1,
      show_id:
        this.state.PlayerCurrShow.uniqueSlug === undefined
          ? this.state.playlist[this.state.currentMusicIndex].showUniqueSlug
          : this.state.PlayerCurrShow.uniqueSlug,
      show_name: this.state.PlayerCurrShow.title,
      platform_name: "web",
      content_language: this.state.PlayerCurrShow.language,
      category_id:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].id
          : "none",
      category_name:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].name
          : "none",
      music_player_time: this.player.current.audio.current.currentTime,
      stream_percentage: `${Math.ceil(
        (this.player.current.audio.current.currentTime /
          this.player.current.audio.current.duration) *
          100
      )}%`,
      episode_number:
        this.state.playlist[this.state.currentMusicIndex].episodeNo,
      episode_publish_date:
        this.state.playlist[this.state.currentMusicIndex].publishedOn,
    };
    postAnalytics(data)
      .then((res) => {
        // console.log("anakytics res resume", res)
      })
      .catch((err) => {
        // console.log("anakytics err resume", err);
      });
    ReactGA.event({
      category: this.state.PlayerCurrShow.title,
      action:
        "(" +
        this.state.playlist[this.state.currentMusicIndex].episodeNo +
        ") " +
        this.state.playlist[this.state.currentMusicIndex].title,
      label: "Click on Resume of episode",
      value: 19,
    });
  };

  trackStop = () => {
    if (this.player.current.audio.current.currentTime <= 0) {
      return;
    }
    // window.Moengage.track_event("stop", {
    //   event_time: this.getCurrDate(),
    //   user_id: firebase.auth().currentUser.uid,
    //   episode_id: this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
    //   episode_name: this.state.playlist[this.state.currentMusicIndex].title,
    //   repeat_status: !this.state.repeat ? 0 : 1,
    //   show_id: this.state.PlayerCurrShow.id,
    //   show_name: this.state.PlayerCurrShow.title,
    //   content_language: this.state.PlayerCurrShow.language,
    //   category_id:
    //     this.state.PlayerCurrShow.categories.length !== 0
    //       ? this.state.PlayerCurrShow.categories[0].id
    //       : "none",
    //   category_name:
    //     this.state.PlayerCurrShow.categories.length !== 0
    //       ? this.state.PlayerCurrShow.categories[0].name
    //       : "none",
    //   music_player_time: `${Math.floor(
    //     this.player.current.audio.current.currentTime / 60
    //   )}:${Math.floor(this.player.current.audio.current.currentTime)}`,
    //   episode_number:
    //     this.state.playlist[this.state.currentMusicIndex].episodeNo,
    // });

    clevertap.event.push("stop", {
      event_time: this.getCurrDate(),
      user_id: firebase.auth().currentUser.uid,
      episode_id: this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
      episode_name: this.state.playlist[this.state.currentMusicIndex].title,
      repeat_status: !this.state.repeat ? 0 : 1,
      show_id: this.state.PlayerCurrShow.id,
      show_name: this.state.PlayerCurrShow.title,
      content_language: this.state.PlayerCurrShow.language,
      category_id:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].id
          : "none",
      category_name:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].name
          : "none",
      music_player_time: `${Math.floor(
        this.player.current.audio.current.currentTime / 60
      )}:${Math.floor(this.player.current.audio.current.currentTime)}`,
      episode_number:
        this.state.playlist[this.state.currentMusicIndex].episodeNo,
    });
    let data = {
      event_name: "stop",
      event_time: this.getCurrDate(),
      user_id: firebase.auth().currentUser.uid,
      episode_id: this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
      episode_name: this.state.playlist[this.state.currentMusicIndex].title,
      repeat_status: !this.state.repeat ? 0 : 1,
      show_id:
        this.state.PlayerCurrShow.uniqueSlug === undefined
          ? this.state.playlist[this.state.currentMusicIndex].showUniqueSlug
          : this.state.PlayerCurrShow.uniqueSlug,
      show_name: this.state.PlayerCurrShow.title,
      content_language: this.state.PlayerCurrShow.language,
      platform_name: "web",
      category_id:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].id
          : "none",
      category_name:
        this.state.PlayerCurrShow.categories.length !== 0
          ? this.state.PlayerCurrShow.categories[0].name
          : "none",
      music_player_time: this.player.current.audio.current.currentTime,
      stream_percentage: `${Math.ceil(
        (this.player.current.audio.current.currentTime /
          this.player.current.audio.current.duration) *
          100
      )}%`,
      episode_number:
        this.state.playlist[this.state.currentMusicIndex].episodeNo,
      episode_publish_date:
        this.state.playlist[this.state.currentMusicIndex].publishedOn,
    };
    postAnalytics(data)
      .then((res) => {
        // console.log("anakytics res resume", res)
      })
      .catch((err) => {
        // console.log("anakytics err resume", err);
      });
    ReactGA.event({
      category: this.state.PlayerCurrShow.title,
      action:
        "(" +
        this.state.playlist[this.state.currentMusicIndex].episodeNo +
        ") " +
        this.state.playlist[this.state.currentMusicIndex].title,
      label: "Click on Stop of episode",
      value: 25,
    });
  };

  handleClickPrevious = () => {
    this.handelAdsPlay();
    this.setState((prevState) => ({
      currentMusicIndex:
        prevState.currentMusicIndex === 0
          ? this.state.playlist.length - 1
          : prevState.currentMusicIndex - 1,
    }));
  };

  handleClickNext = () => {
    this.handelAdsPlay();
    this.setState((prevState) => ({
      currentMusicIndex:
        prevState.currentMusicIndex < this.state.playlist.length - 1
          ? prevState.currentMusicIndex + 1
          : 0,
    }));
  };

  handleNextTrack = () => {
    this.player.current.audio.current.playbackRate = 1;

    if (this.props.AdplayData) {
      this.props.setAdPause();
      this.setState({ ...this.state, adPlay: false });
    }
    if (this.state.currSpeed !== 1) {
      this.setState({ currSpeed: 1 });
    }
    if (this.state.repeat) {
      this.player.current.audio.current.currentTime = 0;
      this.player.current.audio.current.play();
      this.toggleLoop();
      return;
    }
    if (this.state.currentMusicIndex === this.state.playlist.length - 1) {
      this.trackStop();
      return;
    } else {
      this.trackStop();
      this.setState(
        {
          ...this.state,
          adPlay: true,
          currentMusicIndex: this.state.currentMusicIndex + 1,
          repeat: false,
        },
        () => {
          this.trackPlay();
        }
      );
    }
  };
  handelAdsPlay = () => {
    this.props.setAdplay();
    if (this.state.adPlay && this.state.show.advertisements.length > 0) {
      clevertap.event.push("ad_play", {
        event_time: this.getCurrDate(),
        event_title:
          this.state.show.advertisements.length > 0
            ? this.state.show.advertisements[0].adTitle
            : "",
        user_id: firebase.auth().currentUser.uid,
        episode_id:
          this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
        episode_name: this.state.playlist[this.state.currentMusicIndex].title,
        show_id: this.state.PlayerCurrShow.id,
        show_name: this.state.PlayerCurrShow.title,
        content_language: this.state.PlayerCurrShow.language,
        category_id:
          this.state.PlayerCurrShow.categories.length !== 0
            ? this.state.PlayerCurrShow.categories[0].id
            : "none",
        category_name:
          this.state.PlayerCurrShow.categories.length !== 0
            ? this.state.PlayerCurrShow.categories[0].name
            : "none",
        music_player_time: `${Math.floor(
          this.player.current.audio.current.currentTime / 60
        )}:${Math.floor(this.player.current.audio.current.currentTime)}`,
        episode_number:
          this.state.playlist[this.state.currentMusicIndex].episodeNo,
      });
    }
  };
  handleAdsNextTrack = () => {
    // if (this.props.AdplayData) {
    this.props.setAdPause();
    this.setState({ ...this.state, adPlay: false });
    // }
  };
  handlePlaylist = (index, slug) => {
    if (
      index === this.state.currentMusicIndex &&
      slug === this.state.playlist[this.state.currentMusicIndex].uniqueSlug
    ) {
      this.player.current.audio.current.play();
      if (isMobile) this.togglePlaylist();
      this.trackPlay();
      return;
    }
    this.trackStop();
    this.setState(
      {
        ...this.state,
        currentMusicIndex: index,
        viewPlaylist: false,
        repeat: false,
      },
      () => {
        this.trackPlay();
        // this.props.history.replace(`/show/${this.state.PlayerCurrShow.shortUrl}/episode/${this.state.playlist[this.state.currentMusicIndex].shortUrl}`)
      }
    );
  };

  addPlayerData = (data, index) => {
    // console.log("Data: ", data)
    // console.log("index: ", index)
    this.trackStop();
    this.setState(
      {
        ...this.state,
        playlist: data.episodes,
        playerImg: data.featuredImageUrl,
        currentMusicIndex: index,
        playerVisible: true,
        PlayerCurrShow: data,
      },
      () => {
        this.trackPlay();
        this.props.history.push(
          `/show/${this.state.PlayerCurrShow.shortUrl}/episode/${
            this.state.playlist[this.state.currentMusicIndex].shortUrl
          }`
        );
      }
    );
  };
  addEpisodePlayerData = (data, index, showData) => {
    // console.log("Data: ", data, index, showData);
    // console.log("index: ", index)
    this.trackStop();
    this.setState(
      {
        ...this.state,
        playlist: data,
        playerImg: data.featuredImageUrl,
        currentMusicIndex: index,
        playerVisible: true,
        PlayerCurrShow: showData,
      },
      () => {
        this.props.history.push(
          `/show/${this.state.PlayerCurrShow.shortUrl}/episode/${
            this.state.playlist[this.state.currentMusicIndex].shortUrl
          }`
        );
        this.trackPlay();
      }
    );
  };
  addShowPlayerData = (data, index, showData) => {
    this.trackStop();
    this.setState(
      {
        ...this.state,
        playlist: data,
        playerImg: data.featuredImageUrl,
        currentMusicIndex: index,
        playerVisible: true,
        PlayerCurrShow: showData,
      },
      () => {
        this.trackPlay();
      }
    );
  };
  addPlayerShowData = (data, index) => {
    this.trackStop();

    this.setState(
      {
        ...this.state,
        playlist: data.episodes,
        playerImg: data.featuredImageUrl,
        currentMusicIndex: index,
        playerVisible: true,
        PlayerCurrShow: data,
      },
      () => {
        this.trackStop();
      }
    );
  };
  stateUserLanguageHandler = (data) => {
    let arr = data.map((item) => item.name);
    // console.log('lang arr: ', arr)
    return arr;
  };

  getLoggedInUserData = async () => {
    let loggedUserId = firebase.auth().currentUser.uid;
    let token = await firebase.auth().currentUser.getIdToken();
    // console.log("Token: ", token)
    getCurrLoggedInUser(this.state.anonId, loggedUserId, token)
      .then((res) => {
        this.getAvailLanguages();
        this.getBannerData();
        this.setState({
          profileData: {
            ...res.data,
            languagePreferences: this.stateUserLanguageHandler(
              res.data.languagePreferences
            ),
          },
        });
        clevertap.onUserLogin.push({
          Site: {
            Name: firebase.auth().currentUser.displayName, // String
            Identity: firebase.auth().currentUser.uid, // String or number
            Email: firebase.auth().currentUser.email, // Email address of the user
            Phone: firebase.auth().currentUser.phoneNumber, // Phone (with the country code)
            Gender: res.data.gender == "male" ? "M" : "F", // Can be either M or F
            // DOB: res.data.dateOfBirth, // Date of Birth. Date object
            // optional fields. controls whether the user will be sent email, push etc.
            "MSG-email": false, // Disable email notifications
            "MSG-push": true, // Enable push notifications
            "MSG-sms": true, // Enable sms notifications
            "MSG-whatsapp": true, // Enable WhatsApp notifications
          },
        });
      })
      .catch((err) => console.log("Err from getLoggedInUserData: ", err));
  };

  onUpdateUserDetails = async (data) => {
    // console.log("User data: ",data)
    this.showLoader();
    let token = await firebase.auth().currentUser.getIdToken();
    updateUserDetails(data, this.state.profileData.id, token)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ profileData: res.data });
          clevertap.onUserLogin.push({
            Site: {
              Name: firebase.auth().currentUser.displayName, // String
              Identity: firebase.auth().currentUser.uid, // String or number
              Email: firebase.auth().currentUser.email, // Email address of the user
              Phone: firebase.auth().currentUser.phoneNumber, // Phone (with the country code)
              Gender: res.data.gender == "male" ? "M" : "F", // Can be either M or F
              // DOB: new Date(), // Date of Birth. Date object
              // optional fields. controls whether the user will be sent email, push etc.
              "MSG-email": false, // Disable email notifications
              "MSG-push": true, // Enable push notifications
              "MSG-sms": true, // Enable sms notifications
              "MSG-whatsapp": true, // Enable WhatsApp notifications
            },
          });
          this.hideLoader();
          toast.success("Profile details updated!");
        }
      })
      .catch((err) => {
        this.hideLoader();
        toast.error("Profile update failed!");
        // console.log(err);
      });
  };

  onSubscribe = async (type) => {
    if (firebase.auth().currentUser.isAnonymous)
      return this.toggleLoginPrompt();
    let token = await firebase.auth().currentUser.getIdToken();
    let slug =
      type === "channel"
        ? this.state.channel.uniqueSlug
        : this.state.show.uniqueSlug;
    subscribe(token, slug, type)
      .then((res) => {
        toast.success(`${type} subscibe sucessfully!`);
        type === "channel"
          ? this.setState({
              channel: { ...this.state.channel, hasSubscribed: true },
            })
          : this.setState({
              show: { ...this.state.show, hasSubscribed: true },
            });
      })
      .catch((err) => {
        // console.log(err);
        toast.error(`${type} subscibe failed!`);
      });
  };
  onUnSubscribe = async (type) => {
    if (firebase.auth().currentUser.isAnonymous)
      return this.toggleLoginPrompt();
    let token = await firebase.auth().currentUser.getIdToken();
    let slug =
      type === "channel"
        ? this.state.channel.uniqueSlug
        : this.state.show.uniqueSlug;
    unsubscribe(token, slug, type)
      .then((res) => {
        type === "channel"
          ? this.setState({
              channel: { ...this.state.channel, hasSubscribed: false },
            })
          : this.setState({
              show: { ...this.state.show, hasSubscribed: false },
            });
      })
      .catch((err) => {
        // console.log(err);
        toast.error(`${type} unsubscibe failed!`);
      });
  };

  onEpisodeLike = async () => {
    if (firebase.auth().currentUser.isAnonymous)
      return this.toggleLoginPrompt();
    let token = await firebase.auth().currentUser.getIdToken();
    likeEpisode(
      token,
      this.state.playlist[this.state.currentMusicIndex].uniqueSlug
    )
      .then(() => {
        let playlist = [...this.state.playlist];
        playlist[this.state.currentMusicIndex].hasLiked = true;
        playlist[this.state.currentMusicIndex].likesCount =
          this.state.playlist[this.state.currentMusicIndex].likesCount + 1;
        // toast.success("Episode liked!")
        this.setState({ playlist: playlist });
      })
      .catch((err) => toast.error("Episode like failed!"));
  };

  onEpisodeDisike = async () => {
    // if (firebase.auth().currentUser.isAnonymous) return this.toggleLoginPrompt()
    let token = await firebase.auth().currentUser.getIdToken();
    dislikeEpisode(
      token,
      this.state.playlist[this.state.currentMusicIndex].uniqueSlug
    )
      .then(() => {
        let playlist = [...this.state.playlist];
        playlist[this.state.currentMusicIndex].hasLiked = false;
        playlist[this.state.currentMusicIndex].likesCount =
          this.state.playlist[this.state.currentMusicIndex].likesCount - 1;
        // toast.success("Episode liked!")
        this.setState({ playlist: playlist });
      })
      .catch((err) => toast.error("Episode dislike failed!"));
  };

  signInAnonymously = () => {
    // console.log('Signing in Anonymously!')
    // if(user.is)
    firebase
      .auth()
      .signInAnonymously()
      .catch((err) => {
        toast.error("Anon login error!");
        // console.log("Anon login err: ", err);
        this.props.history.push("/error");
      });
  };

  componentDidMount = () => {
    var options = { no_journeys: false };
    //  live --> key_live_elUlLXcAcbGzpp247cjfhilosFoOzdzx
    // test --- > key_test_mcLhU5oBhiLqei51ZfmpmmfcCEpHxlCN
    branch.init(
      "key_live_elUlLXcAcbGzpp247cjfhilosFoOzdzx",
      options,
      (err, data) => {
        // console.log("jjjdwdwd", data, this)
        if (data) {
          if (data.data_parsed.$og_type === "episode") {
            this.props.history.push(
              `/show/${data.data_parsed.show_shortUrl}/episode/${data.data_parsed.episode_shortUrl}`
            );
            // console.log("episode branch io", data.data_parsed.extras.episode, data.data_parsed.extras.show)
          } else if (data.data_parsed.$og_type === "show") {
            this.props.history.push(`/show/${data.data_parsed.show_shortUrl}`);
            // console.log("show branch io", data.data_parsed.extras.show)
          } else if (data.data_parsed.$og_type === "channel") {
            if (data.data_parsed.source === "web") {
              this.props.history.push(
                `/channel/${data.data_parsed.cahnnel_shortUrl}`
              );
            } else {
              this.props.history.push(
                `/${data.data_parsed.$canonical_identifier}`
              );
            }
          } else if (data.data_parsed.$og_type === "live_show") {
            this.props.history.push(`/live/${data.data_parsed.content_slug}`);
            // console.log("Live show branch io")
          }
        }
      }
    );

    localStorage.setItem("login", false);
    // Initialize Firebase
    const app = firebase.initializeApp(config);
    // console.log(getMessaging(app), this.state)
    // Initialize Firebase Cloud Messaging and get a reference to the service
    getToken(
      getMessaging(app),
      "BIsKOcK6FnLDcluH9pIHvZrmdT7VT2fByyRGCOTDSyw-EqXfIzq0SzQefw0eL2r4F4s0ZGkvjEx7j8FTYWT0W6c"
    )
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          FirebaseNotification(this.state.profileData.firstName, currentToken)
            .then((res) => {
              // console.log(res)
            })
            .catch((err) => {
              // console.log(err)
            });
          // console.log("sssws", currentToken)
        } else {
          // Show permission request UI
          // console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      })
      .catch((err) => {
        // console.log('An error occurred while retrieving token. ', err);
        // ...
      });
    // var ui = new firebaseui.auth.AuthUI(firebase.auth());
    // ui.start("#firebaseui-auth-container", this.uiConfig);
    // ui.disableAutoSignIn();

    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      // console.log('Firebase app started, user is null!')

      this.setState({ isSignedIn: user }, () => {
        // console.log('isSigned: ', user)
        if (user !== null && user.isAnonymous) {
          // console.log('User is not null & is anonymous! hitting createAnon API!')
          createAnonUser(user.uid)
            .then((res) => {
              if (res.status === 201) {
                // console.log('User is not null & is anonymous! hitting createAnon API!')
                clevertap.onUserLogin.push({
                  Site: {
                    Name: "", // String
                    Identity: firebase.auth().currentUser.uid, // String or number
                    Email: "", // Email address of the user
                    Phone: "", // Phone (with the country code)
                    // Gender: "", // Can be either M or F
                    // DOB: new Date(), // Date of Birth. Date object
                    // optional fields. controls whether the user will be sent email, push etc.
                    "MSG-email": false, // Disable email notifications
                    "MSG-push": true, // Enable push notifications
                    "MSG-sms": true, // Enable sms notifications
                    "MSG-whatsapp": true, // Enable WhatsApp notifications
                  },
                });
                this.setState({ anonId: res.data.firebaseUserUuid }, () => {
                  // this.getBannerData()
                  this.getLoggedInUserData();
                });
                // this.setState({ anonId: res.data.firebaseUserUuid }, () => console.log("State: ", this.state.anonId))
              }
            })
            .catch((e) => {
              toast.error("Error creating anonymous user!");
              // console.log(e);
            });
        }
      });
      if (firebase.auth().currentUser == null) {
        this.signInAnonymously();
      }
      if (
        firebase.auth().currentUser !== null &&
        !firebase.auth().currentUser.isAnonymous
      ) {
        this.getLoggedInUserData();
        localStorage.setItem("login", true);
      }
    });

    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
    // this.getSearchData()
    this.checkEpisodePath();
    // this.player.current.container.current.childNodes[1].childNodes[1].childNodes[2].childNodes[0].onclick(() => console.log('prev 10 secs'))
    // const el = document.createElement('script')
    // el.setAttribute('src', 'https://accounts.google.com/gsi/client')
    // el.onload = () => this.initializeGSI();
    // document.querySelector('body').appendChild(el)
  };

  componentDidUpdate = () => {
    // console.log("path: ", this.props.location.pathname)
    // console.log(this.player)
    // console.log("Full player: ", this.state.isFullPlayer)
    // this.player.current.container.current.childNodes[1].childNodes[1].childNodes[2].childNodes[0].style.display = this.checkEpisodePath() ? 'initial' : 'none';
    window.onpopstate = (e) => {
      this.setState({
        logInPrompt: false,
        userLangPrefPrompt: true,
        userLangPrefBottomPrompt: false,
        showCommentPrompt: false,
      });
    };
    this.player.current.container.current.lastChild.childNodes[0].children[0].style.display =
      this.checkEpisodePath() ? "initial" : isMobile ? "none" : "initial";
    this.player.current.container.current.lastChild.childNodes[0].children[2].style.display =
      this.checkEpisodePath() ? "initial" : isMobile ? "none" : "initial";
  };

  componentWillUnmount() {
    this.unregisterAuthObserver();
    window.removeEventListener("online", this.handleConnectionChange);
    window.removeEventListener("offline", this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    if (navigator.onLine) {
      this.setState({ online: true }, () => {
        if (this.props.location.pathname !== "/") {
          this.props.history.push("/");
          toast.success("You are back online!");
        }
      });
    } else {
      this.setState({ online: false }, () => {
        this.props.history.push("/no-connection");
        // toast.error('You are offline!')
      });
    }
    // this.props.history.push('/') : this.props.history.push('/error');
  };

  // changePlayerCss = () => {
  // 	this.setState({ isFullPlayer: !this.state.isFullPlayer })
  // }

  showLoader = () => {
    if (this.state.isLoading) {
      this.setState({ isLoading: true });
    } else {
      return;
    }
  };

  hideLoader = () => {
    if (this.state.isLoading) {
      this.setState({ isLoading: false });
    } else {
      return;
    }
  };
  MyLoader = () => (
    <ContentLoader viewBox="0 0 380 70">
      {/* Only SVG shapes */}
      <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
      <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
    </ContentLoader>
  );
  // checkNavTabPath() {
  // 	let result = !!matchPath(this.props.location.pathname, '/nav-tab/:index')
  // 	return result;
  // }

  checkEpisodePath() {
    let result = !!matchPath(
      this.props.location.pathname,
      "/show/:name1/episode/:name2"
    );
    return result;
  }

  switchToFullPlayer = () => {
    // this.setState({isFullPlayer: true}, () => {
    // 	this.props.history.push('/episode')
    // })
    this.props.history.push(
      `/show/${
        this.state.PlayerCurrShow.shortUrl !== undefined
          ? this.state.PlayerCurrShow.shortUrl
          : this.state.playlist[this.state.currentMusicIndex].showShortUrl
      }/episode/${this.state.playlist[this.state.currentMusicIndex].shortUrl}`
    );
  };

  pauseFromPlaylist = () => {
    this.setState({ viewPlaylist: false }, () =>
      this.player.current.audio.current.pause()
    );
    this.trackPause();
  };

  playFromPlaylist = () => {
    this.setState({ viewPlaylist: true }, () =>
      this.player.current.audio.current.play()
    );
    // this.handlePlaylist()
    this.trackResume();
  };

  toggleSpeedModal = () => {
    this.setState({ viewSpeed: !this.state.viewSpeed });
  };

  togglePlaylist = () => {
    this.setState({ viewPlaylist: !this.state.viewPlaylist });
  };

  changePlaybackRate = (speed) => {
    this.setState(
      { ...this.state, currSpeed: speed, viewSpeed: !this.state.viewSpeed },
      () => {
        this.player.current.audio.current.playbackRate = speed;
      }
    );
  };

  toggleLoop = () => {
    this.setState(
      { repeat: !this.state.repeat }
      // , () => {
      // this.player.current.audio.current.loop = this.state.repeat
      // console.log('loop: ', this.state.repeat)
      // }
    );
  };
  toggleCommentPrompt = () => {
    this.setState({ showCommentPrompt: !this.state.showCommentPrompt });
  };
  handleCommentChange = (e) => {
    this.setState({ userComment: e.target.value });
  };
  handleCommentSubmit = async () => {
    if (this.state.userComment.length === 0)
      return toast.info("Comment cannot be empty!");
    let token = await firebase.auth().currentUser.getIdToken();
    postEpisodeComment(
      token,
      this.state.PlayerCurrShow.uniqueSlug || this.state.show.uniqueSlug,
      this.state.playlist[this.state.currentMusicIndex].uniqueSlug,
      this.state.userComment
    )
      .then((res) => {
        this.setState({
          showCommentPrompt: false,
          userComment: "",
          showComments: res.data.comments.reverse(),
          PlayerCurrShow: {
            ...this.state.PlayerCurrShow,
            commentsCount: this.state.PlayerCurrShow.commentsCount + 1,
          },
        });
        toast.success("Comment posted successfully!");
      })
      .catch((err) => {
        // console.log("Show episode comment post error: ", err);
        toast.error("Couldn't post your comment! Please try again later!");
      });
  };
  handlePlayerClose = () => {
    this.player.current.audio.current.currentTime = 0;
    this.player.current.audio.current.pause();
    this.setState({ playerVisible: false });
    this.trackPause();
  };

  onPostShowComment = async () => {
    if (this.isUserAnonymous()) {
      return this.toggleLoginPrompt();
    }
    if (this.state.userComment.length === 0)
      return toast.info("Comment cannot be empty!");
    let token = await firebase.auth().currentUser.getIdToken();
    postShowComment(token, this.state.show.uniqueSlug, this.state.userComment)
      .then((res) => {
        this.setState({
          userComment: "",
          showComments: res.data.comments.reverse(),
          PlayerCurrShow: {
            ...this.state.PlayerCurrShow,
            commentsCount: this.state.PlayerCurrShow.commentsCount + 1,
          },
        });
        toast.success("Comment posted successfully!");
      })
      .catch((err) => {
        // console.log("Show comment post error: ", err);
        toast.error("Couldn't post your comment! Please try again later!");
      });
  };

  getUserSubscribedShows = async () => {
    if (this.isUserAnonymous()) return;
    let token = await firebase.auth().currentUser.getIdToken();
    getUserSubsciptions(token)
      .then((res) => {
        this.setState({ userSubscriptions: res.data.subscribeShowsChannel });
      })
      .catch((err) => {
        // console.log("Get user subscriptions error: ", err);
        toast.error("Couldn't retrieve subscriptions!");
      });
  };
  getAvailLanguages = async () => {
    if (this.state.languages.length > 0) return;
    let token = await firebase.auth().currentUser.getIdToken();
    getLanguages(token)
      .then((res) => {
        if (res.status === 200) {
          // console.log("languegs res.data.results", res.data.results)
          this.setState({ languages: res.data.results });
        }
      })
      .catch((err) => {
        // console.log("Get languages error: ", err);
        toast.error("Couldn't retrieve languages!");
      });
  };
  updateUserLanguagePref = async () => {
    this.showLoader();
    let token = await firebase.auth().currentUser.getIdToken();
    let data =
      this.state.profileData.languagePreferences.length === 0
        ? ""
        : this.state.profileData.languagePreferences.join();
    updateLanguage(token, data, this.state.profileData.id)
      .then((res) => {
        if (res.status === 200) {
          this.hideLoader();
          this.getBannerData();
          if (this.state.userLangPrefBottomPrompt)
            this.toggleUserLangPrefBottomPrompt();
          if (
            this.state.userLangPrefPrompt &&
            this.state.isSignedIn.isAnonymous
          )
            this.toggleUserLangPrefCard();
          this.setState({
            profileData: {
              ...res.data,
              languagePreferences: this.stateUserLanguageHandler(
                res.data.languagePreferences
              ),
            },
          });
        }
      })
      .catch((err) => {
        this.hideLoader();
        // console.log("Update user language error: ", err);
        toast.error("Couldn't update language! Please try again later!");
      });
  };
  handleUserLangChange = (value) => {
    this.setState({
      profileData: { ...this.state.profileData, languagePreferences: value },
    });
  };
  handleBrolive = (data) => {
    this.setState({
      ...this.state,
      playlist: data,
      currentMusicIndex: 0,
      isLoading: false,
      playerVisible: true,
    });
  };

  render() {
    const { playlist } = this.state;
    const mapDispatchToProps = {};
    const styles = {
      contentRow: {
        display: this.state.isLoading ? "none" : "inline",
      },
      audioPlayer: {
        width: "100%",
        // display: !this.state.playerVisible || this.props.location.pathname == '/episode' ? 'none' : 'inline'
        display: !this.state.playerVisible ? "none" : "flex",
      },
      miniAudioPlayer: {
        // rhap_controlsSection: { display: "fixed" },
        display: !this.state.playerVisible ? "none" : "flex",
        height: "65px",
        backgroundColor: this.state.show.advertisements
          ? this.state.show.advertisements.length > 0 && this.props.AdplayData
            ? "black"
            : "rgba(216, 223, 226, 0.8)"
          : "rgba(216, 223, 226, 0.8)",
        color: "#000000",
        width: "100%",
        borderRadius: "0 15px 15px 0",
        paddingRight: "15px",
        outline: "none",
        paddingTop: "5px",
        lineHeight: "1.3em",
      },
      fullAudioPlayer: {
        // rhap_controlsSection: { display: "fixed" },
        backgroundColor: this.checkEpisodePath() ? "transparent" : "",
        boxShadow: "none",
        outline: "none",
        position: "fixed",
        display: "block",
        bottom: "0px",
        left: "0px",
        lineHeight: "0.3em",
      },
      AdsStyleDesktop: {
        backgroundColor: "white",
        boxShadow: "none",
        outline: "none",
        position: this.props.AdplayData ? "fixed" : "none",
        display: this.props.AdplayData ? "flex" : "none",
        bottom: "0px",
        left: "0px",
        lineHeight: "0.3em",
        width: "100%",
        height: "70px",
        zIndex: "99",
      },
      AdsStyleMobile: {
        // display: !this.state.playerVisible ? "none" : "flex",
        // height: "65px",
        // backgroundColor: "white",
        // color: "#000000",
        // width: "100%",
        // borderRadius: "0 15px 15px 0",
        // paddingRight: "15px",
        // outline: "none",
        // paddingTop: "5px",
        // lineHeight: "1.3em",
        // zIndex: "99",
      },
      AdsHeading: {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "space-evenly",
        width: "21%",
        marginBottom: "19px",
      },
      AdsHeadingMobile: {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "space-evenly",
      },
      container: {
        marginBottom: this.state.playerVisible ? "0" : "10%",
      },
      navBar: {
        display:
          this.checkEpisodePath() || !this.state.online ? "none" : "block",
      },
      miniAlbumArt: {
        display: this.checkEpisodePath()
          ? "none"
          : isMobile && !isTablet
          ? "flex"
          : "none",
        padding: "0px",
        backgroundColor: this.state.show.advertisements
          ? this.state.show.advertisements.length > 0 && this.props.AdplayData
            ? "black"
            : "rgba(216, 223, 226, 0.8)"
          : "rgba(216, 223, 226, 0.8)",
        // borderRadius: "25px 0 0 25px",
        width: "100%",
        marginRight: "-1px",
        borderRadius: "15px 0px 0px 15px",
      },
      miniAlbumArtContainer: {
        display: this.checkEpisodePath()
          ? "none"
          : isMobile && !isTablet
          ? "block"
          : "none",
      },
      miniAlbumArtShowTitle: {
        display: this.checkEpisodePath() ? "none" : isMobile ? "block" : "none",
        padding: "0px",

        textOverflow: "ellipsis",
        width: "200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontSize: "14px",
      },
      miniAlbumArtEpisodeTitle: {
        display: this.checkEpisodePath()
          ? "none"
          : isMobile && !isTablet
          ? "block"
          : "none",
        padding: "0px",
        textOverflow: "ellipsis",
        width: "200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textAlign: "left",
      },
      plyrAdditionalBtns: {
        display: "inherit",
        backgroundColor: "transparent",
      },
      plyLikeBtn: {
        display: this.checkEpisodePath()
          ? "block"
          : isMobile && !isTablet
          ? "none"
          : "block",
      },
      closeButton: {
        display: this.props.AdplayData
          ? "none"
          : this.checkEpisodePath()
          ? "none"
          : "initial",
        display: this.checkEpisodePath() ? "none" : "initial",
        width: "20px",
        position: "absolute",
        top: "-25px",
        right: "5px",
        backgroundColor: "white",
        borderRadius: "50%",
      },
      skipButton: {
        display: !this.props.AdplayData ? "none" : "initial",
        display: "none",
        width: "65px",
        position: "absolute",
        top: "27px",
        right: "116px",
        padding: "7px",
      },
    };

    return (
      <div
        className="App"
        // style={{
        //   backgroundImage: `url(${GaneshChaturthiBg})`,
        //   backgroundRepeat: "repeat",
        //   backgroundSize: "contain",
        // }}
      >
        <Container>
          {/* {isMobile ?
						<div className='mobile-only'>
							<SwipeableBottomSheet open={this.state.DownloadModel} style={{ zIndex: 99 }} onChange={this.toggleDownloadModel} >
								<div>
									{isAndroid ? <a href="https://play.google.com/store/apps/details?id=com.aawaz&hl=en_IN&gl=US" target="_blank" > <img style={{ width: "100%", height: "auto" }} src={DownloadBanner} /> </a> : null}
									{isIOS ? <a href="https://apps.apple.com/tt/app/aawaz-com/id1504894470" target="_blank" > <img style={{ width: "100%", height: "auto" }} src={DownloadBanner} /> </a> : null}
								</div>
							</SwipeableBottomSheet>
						</div>
						: null} */}

          <Modal
            className="login-popup"
            size="x"
            show={this.state.userLangPrefBottomPrompt}
            onHide={this.toggleUserLangPrefBottomPrompt}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3 className="modal-title">Language Preference</h3>
                <p
                  style={{
                    fontSize: "15px",
                    textAlign: "left",
                    fontFamily: "BwModelica-Light",
                    color: "white",
                  }}
                >
                  Please select the language(s) of the Podcast you like to
                  listen.
                </p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="lang-pink-btn">
                <ToggleButtonGroup
                  type="checkbox"
                  onChange={this.handleUserLangChange}
                  defaultValue={this.state.profileData.languagePreferences}
                  className="mb-2"
                  style={{ display: "block", paddingBottom: "20px" }}
                >
                  <label className="lang-modal-name">English </label>
                  &nbsp;&nbsp;
                  <span className="lang-modal-subname">(English)</span>{" "}
                  <input type="checkbox" value={"en"} className="check-btn" />{" "}
                  <br />
                  <hr />
                  {/* <span className="selection-box"> */}
                  <label className="lang-modal-name">Hindi</label>&nbsp;&nbsp;
                  <span className="lang-modal-subname">(हिन्दी)</span>
                  <input type="checkbox" value="hi" className="check-btn" />
                  <hr />
                  {/* <span className="selection-box"> */}
                  <label className="lang-modal-name">Marathi</label>&nbsp;&nbsp;
                  <span className="lang-modal-subname">(मराठी)</span>
                  <input type="checkbox" value="mr" className="check-btn" />
                  <hr />
                  {/* <span className="selection-box"> */}
                  <label className="lang-modal-name">Urdu</label>&nbsp;&nbsp;
                  <span className="lang-modal-subname">(اردو)</span>{" "}
                  <input type="checkbox" value="ur" className="check-btn" />
                  <hr />
                  <label className="lang-modal-name">Gujarati</label>
                  &nbsp;&nbsp;
                  <span className="lang-modal-subname">(ગુજરાતી)</span>{" "}
                  <input type="checkbox" value="gu" className="check-btn" />
                  <hr />
                </ToggleButtonGroup>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="popup-lang-btn"
                variant="link"
                onClick={this.updateUserLanguagePref}
              >
                Listen Now
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
        {/* <SwipeableBottomSheet open={this.state.userLangPrefBottomPrompt} style={{ zIndex: 2 }} onChange={this.toggleUserLangPrefBottomPrompt}>
					
				</SwipeableBottomSheet> */}
        <SwipeableBottomSheet
          open={this.state.showCommentPrompt}
          style={{ zIndex: 99 }}
          onChange={this.toggleCommentPrompt}
        >
          <Container fluid>
            <div>
              <Row>
                <Col>
                  <Form.Control
                    style={{ margin: "15px 0" }}
                    autoComplete="on"
                    type="search"
                    value={this.state.userComment}
                    placeholder="Say Something nice..."
                    className="player-form-comment-input"
                    onChange={(event) => this.handleCommentChange(event)}
                  />
                  <img
                    onClick={this.handleCommentSubmit}
                    src={commentSubmit}
                    alt="commentBtn"
                    className="singelplayer-comment-btn"
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                {this.state.showComments.map((comm, index) => (
                  <Container
                    key={index}
                    style={{ paddingTop: "15px" }}
                    className="commentBody"
                  >
                    <Row>
                      <Col xs={2} sm={2} md={2}>
                        <img
                          alt=""
                          src={comm.user.profileImage}
                          className="commentUserPic"
                        />
                      </Col>
                      <Col xs={10} sm={10} md={10}>
                        <span className="commentName">
                          {comm.user ? comm.user.username : "..."}
                        </span>
                        <span className="commentDuration">
                          {comm.createdAt}
                        </span>
                        <span className="commentDesc">
                          {comm.user ? comm.message : "..."}
                        </span>
                      </Col>
                    </Row>
                    <Row></Row>
                  </Container>
                ))}
              </Col>
            </Row>
          </Container>
        </SwipeableBottomSheet>
        <Container>
          <div id="login-modal">
            <div className="shareSection">
              <Modal
                size="x"
                show={this.state.logInPrompt}
                onHide={this.toggleLoginPrompt}
              >
                {/* <Modal.Content className="shareSection"> */}
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Login
                    user={this.state.isSignedIn}
                    isUserAnonymous={this.isUserAnonymous}
                    toggleLoginPrompt={this.toggleLoginPrompt}
                  />
                </Modal.Body>
                {/* </Modal.Content> */}
              </Modal>
            </div>
          </div>
        </Container>
        <Container>
          <div>
            <Modal
              size="x"
              show={this.state.SharePrompt}
              onHide={this.togglesharePrompt}
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ShareUi link={this.state.shareLink} />
              </Modal.Body>
            </Modal>
          </div>
        </Container>
        {isMobile && !isTablet ? (
          <SwipeableBottomSheet
            open={this.state.viewPlaylist}
            style={{ zIndex: 2 }}
            onChange={this.togglePlaylist}
          >
            <Accordion>
              {playlist.map((item, index) => (
                <Accordion.Toggle as={Card.Header} key={index} eventKey={index}>
                  <Row>
                    <Col sm={2} xs={2}>
                      <img
                        src={`${item.featuredImageUrl}`}
                        style={{ width: "50px", borderRadius: "6px" }}
                      />
                    </Col>
                    <Col sm={2} xs={8}>
                      <div style={{ float: "left" }}>
                        <label
                          style={{
                            fontWeight:
                              index === this.state.currentMusicIndex
                                ? "bold"
                                : "normal",
                          }}
                        >
                          {index + 1}. {item.title}
                        </label>
                      </div>
                    </Col>
                    <Col sm={2} xs={2}>
                      <div style={{ float: "right" }}>
                        {index === this.state.currentMusicIndex &&
                        this.player.current &&
                        !this.player.current.audio.current.paused ? (
                          <i
                            className="fa fa-pause-circle"
                            aria-hidden="true"
                            onClick={this.pauseFromPlaylist}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-play-circle"
                            onClick={() =>
                              this.handlePlaylist(index, item.uniqueSlug)
                            }
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {/* <this.CustomToggle style={{ float: 'right' }} eventKey={index} /> */}
                        {/* <i className="fas fa-chevron-right"
														aria-hidden="true"
														style={{ float: 'right' }}>
													</i> */}
                      </div>
                    </Col>
                  </Row>
                  <Accordion.Collapse eventKey={index}>
                    <div style={{ paddingTop: "60px" }}>
                      <Card.Body>{item.description}</Card.Body>
                    </div>
                  </Accordion.Collapse>
                </Accordion.Toggle>
              ))}
            </Accordion>
          </SwipeableBottomSheet>
        ) : null}

        <header>
          {/* {this.props.location.pathname !== '/lp/being-ceo' ? */}
          {!matchPath(this.props.location.pathname, "/en/guests/:name") &&
          !matchPath(this.props.location.pathname, "/policy/:name") &&
          !matchPath(this.props.location.pathname, "/en/page/:name") ? (
            <>
              <ToastContainer position="top-center" draggable />
              <HeaderBrand
                user={this.state.isSignedIn}
                handleUserLangChange={this.handleUserLangChange}
                defaultUserLangPref={this.state.profileData.languagePreferences}
                updateUserLanguagePref={this.updateUserLanguagePref}
                userLangPrefBottomPrompt={this.toggleUserLangPrefBottomPrompt}
                toggleLoginPrompt={this.toggleLoginPrompt}
                LangPrefBottomPrompt={this.state.userLangPrefBottomPrompt}
              />
              <DesktopHeader
                user={this.state.isSignedIn}
                handleUserLangChange={this.handleUserLangChange}
                defaultUserLangPref={this.state.profileData.languagePreferences}
                updateUserLanguagePref={this.updateUserLanguagePref}
                userLangPrefBottomPrompt={this.toggleUserLangPrefBottomPrompt}
                toggleLoginPrompt={this.toggleLoginPrompt}
                LangPrefBottomPrompt={this.state.userLangPrefBottomPrompt}
              />

              {!this.state.online ? (
                <div style={{ backgroundColor: "red", top: "0" }}>
                  <p style={{ color: "white" }}>You are offline!</p>
                </div>
              ) : null}

              <div style={{ height: "auto", display: "flex" }}>
                <div
                  style={{
                    position: "relative",
                    zIndex: 0,
                  }}
                >
                  <DemoHome />
                </div>

                <div className="MainContainer">
                  {!this.state.isLoading ? (
                    <div>
                      <Suspense
                        fallback={
                          <div style={{ textAlign: "center" }}>
                            {isMobile ? (
                              window.location.pathname === "/" ? (
                                <MobileHomepageLoader />
                              ) : window.location.pathname === "/channels" ? (
                                <MobileChannelLoader />
                              ) : window.location.pathname === "/categories" ? (
                                <MobileChannelLoader />
                              ) : window.location.pathname === "/live" ? (
                                <MobileLiveLoader />
                              ) : !!matchPath(
                                  this.props.location.pathname,
                                  "/category/:name"
                                ) ? (
                                <MobileChannelLoader />
                              ) : !!matchPath(
                                  this.props.location.pathname,
                                  "/channel/:name"
                                ) ? (
                                <MobileChannelLoader />
                              ) : window.location.pathname ==
                                !!matchPath(
                                  this.props.location.pathname,
                                  "/show/:name1"
                                ) ? (
                                <MobileShowPageLoader />
                              ) : !!matchPath(
                                  this.props.location.pathname,
                                  "/show/:name1/episode/:name2"
                                ) ? (
                                <MobileEpisodeLoader />
                              ) : (
                                <MobileHomepageLoader />
                              )
                            ) : window.location.pathname === "/channels" ? (
                              <ChannelLoader />
                            ) : window.location.pathname === "/categories" ? (
                              <CategoryLoader />
                            ) : window.location.pathname === "/live" ? (
                              <LiveLoader />
                            ) : !!matchPath(
                                this.props.location.pathname,
                                "/show/:name1"
                              ) ? (
                              <ShowpageLoader />
                            ) : !!matchPath(
                                this.props.location.pathname,
                                "/category/:name"
                              ) ||
                              !!matchPath(
                                this.props.location.pathname,
                                "/channel/:name"
                              ) ? (
                              <CategoryLoader />
                            ) : !!matchPath(
                                this.props.location.pathname,
                                "/read/:lang/:id"
                              ) ? (
                              <SinglereadLoader />
                            ) : window.location.pathname === "/read" ? (
                              <AllreadLoader />
                            ) : (
                              <HomepageLoader />
                            )}
                          </div>
                        }
                      >
                        <div
                          className="sub-head"
                          style={{ position: "relative" }}
                        >
                          <DesktopSearch
                            getShowData={this.getShowData}
                            // PostRecentSearch={this.PostRecentSearch}
                            profileId={this.state.profileData.id}
                            // RecentSearch={this.RecentSearch}
                          />
                        </div>

                        <Switch>
                          {this.props.isLandscape ? (
                            <Container>
                              <Row>
                                <Col>
                                  <h2>
                                    Please rotate your device to portrait mode!{" "}
                                  </h2>
                                </Col>
                              </Row>
                            </Container>
                          ) : null}
                          <Route path="/" exact>
                            <Home
                              ContinueListening={this.ContinueListening}
                              ContinueListeningData={
                                this.state.ContinueListening
                              }
                              userLangPref={this.state.userLangPref}
                              isLoading={this.state.isLoading}
                              banners={this.state.banners}
                              sections={this.state.sections}
                              getShowData={this.getShowData}
                              getShowEpisodes={this.getShowEpisodes}
                              getEpisodeShowData={this.getEpisodeShowData}
                              playBanner={this.addPlayerData}
                              defaultUserLangPref={
                                this.state.profileData.languagePreferences
                              }
                              getEpisodeByUniqueSlug={
                                this.getEpisodeByUniqueSlug
                              }
                              getEpisodeDataForEpiBanner={
                                this.getEpisodeDataForEpiBanner
                              }
                            />
                          </Route>
                          <Route
                            path="/landscape"
                            exact
                            component={LandscapePage}
                          />
                          <Route
                            path="/samplePage"
                            exact
                            component={SamplePage}
                          />
                          <Route path="/settings" exact>
                            <UserSettings
                              user={this.state.isSignedIn}
                              profile={this.state.profileData}
                              defaultLanguage={
                                this.state.profileData.languagePreferences
                              }
                              toggleLanguage={
                                this.toggleUserLangPrefBottomPrompt
                              }
                            />
                          </Route>
                          <Route path="/myshows" exact>
                            {" "}
                            <MyShows
                              getUserSubscribedShows={
                                this.getUserSubscribedShows
                              }
                              subscribedShows={this.state.userSubscriptions}
                              toggleLoginPrompt={this.toggleLoginPrompt}
                            />
                          </Route>
                          <Route
                            path="/myshowlogin"
                            exact
                            component={MyShowLogin}
                          />
                          <Route path="/profile" exact>
                            <Profile
                              user={this.state.isSignedIn}
                              profile={this.state.profileData}
                              defaultLanguage={
                                this.state.profileData.languagePreferences
                              }
                              toggleLanguage={
                                this.toggleUserLangPrefBottomPrompt
                              }
                              toggleLoginPrompt={this.toggleLoginPrompt}
                            />
                          </Route>
                          <Route path="/edit-profile" exact>
                            <EditProfile
                              showLoader={this.showLoader}
                              hideLoader={this.hideLoader}
                              profileData={this.state.profileData}
                              onUpdateUserDetails={this.onUpdateUserDetails}
                            />
                          </Route>
                          {/* <Route path="/myprofile" exact><MyProfile /></Route> */}
                          <Route path="/Search" exact>
                            <MobileSearch
                              searchData={this.state.searchAPIData}
                              getSearchData={this.getSearchData}
                              // PostRecentSearch={this.PostRecentSearch}
                            />
                          </Route>

                          {/* <Route path="/dsearch" exact>
														<DesktopSearch
															searchData={this.state.searchAPIData}
															getSearchData={this.getSearchData}
															getCategoryData={this.getCategoryData}
															getChannelData={this.getChannelData}
															getShowData={this.getShowData}
														/>
													</Route> */}

                          {/* <Route path="/desktop-search" exact>
														<HeaderSearch
															searchData={this.state.searchAPIData}
															getSearchData={this.getSearchData}
															getCategoryData={this.getCategoryData}
															getChannelData={this.getChannelData}
															getShowData={this.getShowData}
														/>
													</Route> */}
                          <Route path="/view-all/:index">
                            <ViewAll
                              isLoading={this.state.isLoading}
                              data={this.state.sections}
                              component={AudioPlayer}
                              playlist={this.state.playlist}
                            />
                          </Route>
                          <Route path="/show/:name" exact>
                            <Show
                              pauseFromPlaylist={this.pauseFromPlaylist}
                              onEpisodeLike={this.onEpisodeLike}
                              onUserComment={this.onUserComment}
                              handlePlaylist={this.handlePlaylist}
                              toggleLoop={this.toggleLoop}
                              repeat={this.state.repeat}
                              viewSpeed={this.state.viewSpeed}
                              toggleSpeedModal={this.toggleSpeedModal}
                              currSpeed={this.state.currSpeed}
                              changePlaybackRate={this.changePlaybackRate}
                              playerRef={this.player}
                              index={this.state.currentMusicIndex}
                              playlist={this.state.playlist}
                              image={this.state.playerImg}
                              getShowEpisodes={this.getShowEpisodes}
                              // checkEpisodePath={this.checkEpisodePath}
                              show={this.state.PlayerCurrShow}
                              CurrentAudiofile={
                                this.state.playlist[
                                  this.state.currentMusicIndex
                                ].audioFileUrl
                              }
                              onPostShowComment={this.onPostShowComment}
                              handleCommentChange={this.handleCommentChange}
                              showComments={this.state.showComments}
                              toggleLoginPrompt={this.toggleLoginPrompt}
                              onSubscribe={this.onSubscribe}
                              onUnSubscribe={this.onUnSubscribe}
                              data={this.state.show}
                              audioFunc={this.addPlayerData}
                              EpisodeAudioFunc={this.addEpisodePlayerData}
                              addShowPlayerData={this.addShowPlayerData}
                              getShowDetailsData={this.getShowDetailsData}
                              userComment={this.state.userComment}
                              resume={this.state.resume}
                              addPlayerShowData={this.addPlayerShowData}
                              Loading={this.state.isLoading}
                              // getEpisodeData={this.getEpisodeData}
                              togglesharePrompt={this.togglesharePrompt}
                              getEpisodeByUniqueSlug={
                                this.getEpisodeByUniqueSlug
                              }
                              ContinueListening={this.ContinueListening}
                              PostContinueListening={this.PostContinueListening}
                            />
                          </Route>

                          <Route path="/category/:name1/show/:name" exact>
                            <Show
                              onPostShowComment={this.onPostShowComment}
                              handleCommentChange={this.handleCommentChange}
                              userComment={this.state.userComment}
                              showComments={this.state.showComments}
                              data={this.state.show}
                              audioFunc={this.addPlayerData}
                              onSubscribe={this.onSubscribe}
                              onUnSubscribe={this.onUnSubscribe}
                              getShowData={this.getShowData}
                            />
                          </Route>
                          <Route path="/categories" exact>
                            <CategoryList isLoading={this.state.isLoading} />
                          </Route>
                          <Route path="/channels" exact>
                            <ChannelList isLoading={this.state.isLoading} />
                          </Route>
                          <Route path="/channel/:name1/show/:name" exact>
                            <Show
                              onPostShowComment={this.onPostShowComment}
                              handleCommentChange={this.handleCommentChange}
                              showComments={this.state.showComments}
                              data={this.state.show}
                              audioFunc={this.addPlayerData}
                              userComment={this.state.userComment}
                              onSubscribe={this.onSubscribe}
                              onUnSubscribe={this.onUnSubscribe}
                              getShowData={this.getShowData}
                            />
                          </Route>
                          <Route
                            path="/show/:name1/episode/:name2"
                            exact
                            component={FullPlayer}
                          >
                            <FullPlayer
                              playFromPlaylist={this.playFromPlaylist}
                              pauseFromPlaylist={this.pauseFromPlaylist}
                              onEpisodeLike={this.onEpisodeLike}
                              onUserComment={this.onUserComment}
                              handlePlaylist={this.handlePlaylist}
                              toggleLoop={this.toggleLoop}
                              repeat={this.state.repeat}
                              viewSpeed={this.state.viewSpeed}
                              toggleSpeedModal={this.toggleSpeedModal}
                              currSpeed={this.state.currSpeed}
                              changePlaybackRate={this.changePlaybackRate}
                              playerRef={this.player}
                              index={this.state.currentMusicIndex}
                              playlist={this.state.playlist}
                              image={this.state.playerImg}
                              getShowEpisodes={this.getShowEpisodes}
                              data={this.state.show}
                              // checkEpisodePath={this.checkEpisodePath}
                              show={this.state.PlayerCurrShow}
                              resume={this.state.resume}
                              CommentPrompt={this.state.showCommentPrompt}
                              togglesharePrompt={this.togglesharePrompt}
                            />
                          </Route>

                          <Route
                            path="/episode/:name2"
                            exact
                            component={FullPlayer}
                          >
                            <FullPlayer
                              pauseFromPlaylist={this.pauseFromPlaylist}
                              onEpisodeLike={this.onEpisodeLike}
                              onUserComment={this.onUserComment}
                              handlePlaylist={this.handlePlaylist}
                              toggleLoop={this.toggleLoop}
                              repeat={this.state.repeat}
                              viewSpeed={this.state.viewSpeed}
                              toggleSpeedModal={this.toggleSpeedModal}
                              currSpeed={this.state.currSpeed}
                              changePlaybackRate={this.changePlaybackRate}
                              playerRef={this.player}
                              index={this.state.currentMusicIndex}
                              playlist={this.state.playlist}
                              image={this.state.playerImg}
                              getShowEpisodes={this.getShowEpisodes}
                              // checkEpisodePath={this.checkEpisodePath}
                              show={this.state.PlayerCurrShow}
                            />
                          </Route>

                          <Route path="/category/:name" exact>
                            <Category
                              getCategoryData={this.getCategoryData}
                              data={this.state.category}
                              togglesharePrompt={this.togglesharePrompt}
                            />
                          </Route>
                          <Route path="/channel/:name" exact>
                            <Channel
                              getChannelData={this.getChannelData}
                              onSubscribe={this.onSubscribe}
                              onUnSubscribe={this.onUnSubscribe}
                              data={this.state.channel}
                              togglesharePrompt={this.togglesharePrompt}
                            />
                          </Route>

                          <Route path="/live" exact>
                            <ShowList
                              CurrentAudiofile={
                                this.state.playlist[
                                  this.state.currentMusicIndex
                                ].audioFileUrl
                              }
                              playlist={this.state.playlist}
                              handleBrolive={this.handleBrolive}
                              getShowData={this.getShowData}
                              data={this.state.show}
                              isLoading={this.state.isLoading}
                              getEpisodeByUniqueSlug={
                                this.getEpisodeByUniqueSlug
                              }
                            />
                          </Route>
                          <Route path="/live/:uniqueSlug" exact>
                            <Live />
                          </Route>
                          <Route path="/History" exact component={History} />
                          {/* <Route path="/Login" exact><Login user={this.state.isSignedIn} isUserAnonymous={this.isUserAnonymous} /></Route> */}
                          <Route path="/yolo-login" exact>
                            <YoloLogin />
                          </Route>
                          <Route path="/user-profile" exact>
                            <DesktopProfile
                              ContinueListeningData={
                                this.state.ContinueListening
                              }
                              getEpisodeByUniqueSlug={
                                this.getEpisodeByUniqueSlug
                              }
                              getUserSubscribedShows={
                                this.getUserSubscribedShows
                              }
                              subscribedShows={this.state.userSubscriptions}
                              toggleLoginPrompt={this.toggleLoginPrompt}
                              profileData={this.state.profileData}
                              onUpdateUserDetails={this.onUpdateUserDetails}
                              user={this.state.isSignedIn}
                            />
                          </Route>
                          <Route path="/no-connection" exact>
                            <NoConnection />
                          </Route>
                          <Route path="/error" exact>
                            <ErrorPage />
                          </Route>

                          <Route path="/read" exact component={ReadPage} />
                          <Route
                            path="/:lang/read/:Category_slug/:id"
                            exact
                            component={singleRead}
                          />
                          <Route
                            path="/read/:Category_slug/:id"
                            exact
                            component={singleRead}
                          />

                          {/* Footer Navigation */}
                          <Route path="/about-us" exact component={About} />
                          <Route
                            path="/terms-of-use"
                            exact
                            component={TermsOfUse}
                          />
                          <Route
                            path="/alexa-skill-terms-of-use"
                            exact
                            component={AlexaTermsOfUse}
                          />
                          <Route
                            path="/alexa-skill-privacy-policy"
                            exact
                            component={AlexaPrivacyPolicy}
                          />

                          <Route
                            path="/privacy-policy"
                            exact
                            component={PrivacyPolicy}
                          />
                          <Route
                            path="/disclaimer"
                            exact
                            component={Disclaimer}
                          />
                          <Route path="/faq" exact component={Faq} />
                          <Route path="/media" exact component={Media} />
                          <Route path="/contact" exact component={Contact} />
                          <Route path="/sitemap" exact component={Sitemap} />
                          <Route path="/en" exact>
                            {" "}
                            <Redirect to={{ pathname: "/category/english" }} />
                          </Route>
                          <Route path="/en/:id" exact>
                            {" "}
                            <Redirect to={{ pathname: "/category/english" }} />
                          </Route>
                          <Route path="/listen/:id" exact>
                            {" "}
                            <EnglishShows />
                          </Route>
                          <Route path="/:name/listen/:id" exact>
                            {" "}
                            <EnglishShows />
                          </Route>
                          <Route path="/mr" exact>
                            {" "}
                            <Redirect to={{ pathname: "/category/marathi" }} />
                          </Route>
                          <Route path="/mr/:id" exact>
                            {" "}
                            <Redirect to={{ pathname: "/category/marathi" }} />
                          </Route>
                          <Route path="/ur" exact>
                            {" "}
                            <Redirect to={{ pathname: "/category/urdu" }} />
                          </Route>
                          <Route path="/ur/:id" exact>
                            {" "}
                            <Redirect to={{ pathname: "/category/urdu" }} />
                          </Route>
                          <Route path="/gu" exact>
                            {" "}
                            <Redirect to={{ pathname: "category/gu" }} />
                          </Route>
                          <Route path="/gu/:id" exact>
                            {" "}
                            <Redirect to={{ pathname: "category/gu" }} />
                          </Route>
                          <Route path="/tag/:id" exact>
                            {" "}
                            <Redirect to={{ pathname: "/categories" }} />
                          </Route>
                          <Route path="/et" exact component={ET} />
                          <Route path="/voice" exact component={Voice} />
                          <Route path="/ibm" exact component={IBM} />

                          <Route
                            path="*"
                            component={notFound}
                            getAllCategoriesData={this.getAllCategoriesData}
                          ></Route>
                        </Switch>
                      </Suspense>
                    </div>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      {isMobile ? (
                        window.location.pathname === "/" ? (
                          <MobileHomepageLoader />
                        ) : window.location.pathname === "/channels" ? (
                          <MobileChannelLoader />
                        ) : window.location.pathname === "/categories" ? (
                          <MobileChannelLoader />
                        ) : window.location.pathname === "/live" ? (
                          <MobileLiveLoader />
                        ) : !!matchPath(
                            this.props.location.pathname,
                            "/category/:name"
                          ) ? (
                          <MobileChannelLoader />
                        ) : !!matchPath(
                            this.props.location.pathname,
                            "/channel/:name"
                          ) ? (
                          <MobileChannelLoader />
                        ) : window.location.pathname ==
                          !!matchPath(
                            this.props.location.pathname,
                            "/show/:name1"
                          ) ? (
                          <MobileShowPageLoader />
                        ) : !!matchPath(
                            this.props.location.pathname,
                            "/show/:name1/episode/:name2"
                          ) ? (
                          <MobileEpisodeLoader />
                        ) : (
                          <MobileHomepageLoader />
                        )
                      ) : window.location.pathname === "/channels" ? (
                        <ChannelLoader />
                      ) : window.location.pathname === "/categories" ? (
                        <CategoryLoader />
                      ) : window.location.pathname === "/live" ? (
                        <LiveLoader />
                      ) : !!matchPath(
                          this.props.location.pathname,
                          "/show/:name1"
                        ) ? (
                        <ShowpageLoader />
                      ) : !!matchPath(
                          this.props.location.pathname,
                          "/category/:name"
                        ) ||
                        !!matchPath(
                          this.props.location.pathname,
                          "/channel/:name"
                        ) ? (
                        <CategoryLoader />
                      ) : !!matchPath(
                          this.props.location.pathname,
                          "/read/:lang/:id"
                        ) ? (
                        <SinglereadLoader />
                      ) : window.location.pathname === "/read" ? (
                        <AllreadLoader />
                      ) : (
                        <HomepageLoader />
                      )}
                    </div>
                  )}
                  {/* Footer Section */}
                  {/* <ScrollToTop /> */}
                  <DesktopFooter />
                  <FooterNav playerVisible={this.state.playerVisible} />
                </div>
              </div>
            </>
          ) : (
            <div>
              {!this.state.isLoading ? (
                <Suspense
                  fallback={
                    <div style={{ textAlign: "center" }}>
                      <Loader
                        visible={this.state.isLoading}
                        type="Oval"
                        color="#EB1163"
                        height="80vh"
                        width={60}
                      />
                    </div>
                  }
                >
                  <Switch>
                    <Route path="/en/guests/being-ceo" exact>
                      <BeingCEO
                        getShowData={this.getShowData}
                        data={this.state.show}
                        pauseFromPlaylist={this.pauseFromPlaylist}
                        handlePlaylist={this.handlePlaylist}
                        playerRef={this.player}
                        index={this.state.currentMusicIndex}
                        CurrentAudiofile={
                          this.state.playlist[this.state.currentMusicIndex]
                            .audioFileUrl
                        }
                        addPlayerShowData={this.addPlayerShowData}
                      />
                    </Route>
                    <MarketingUntoldStory
                      path="/en/page/marketing-untold-story"
                      exact
                      getShowData={this.getShowData}
                      data={this.state.show}
                      pauseFromPlaylist={this.pauseFromPlaylist}
                      handlePlaylist={this.handlePlaylist}
                      playerRef={this.player}
                      index={this.state.currentMusicIndex}
                      CurrentAudiofile={
                        this.state.playlist[this.state.currentMusicIndex]
                          .audioFileUrl
                      }
                      addPlayerShowData={this.addPlayerShowData}
                    />
                    <Route path="/en/guests/alokbansal" exact>
                      <GuestsAlok />{" "}
                    </Route>
                    <Route path="/en/guests/alokranjan" exact>
                      <GuestsAlokRajan />{" "}
                    </Route>
                    <Route path="/en/guests/suparna" exact>
                      <GuestSuparna />{" "}
                    </Route>
                    <Route path="/en/guests/amitmalik" exact>
                      <GuestsAmitmalik />{" "}
                    </Route>
                    <Route path="/en/guests/aniljain" exact>
                      <GuestAniljain />{" "}
                    </Route>
                    <Route path="/en/guests/apurvapurohit" exact>
                      <GuestApurvapurohit />{" "}
                    </Route>
                    <Route path="/en/guests/ashutoshgupta" exact>
                      <GuestAshutoshgupta />{" "}
                    </Route>
                    <Route path="/en/guests/damodarmall" exact>
                      <GuestDamodarmall />{" "}
                    </Route>
                    <Route path="/en/guests/debjanighosh" exact>
                      <GuestDebjanighosh />{" "}
                    </Route>
                    <Route path="/en/guests/etbiz" exact>
                      <GuestEtbiz />{" "}
                    </Route>
                    <Route path="/en/guests/harshmariwala" exact>
                      <GuestHarshmariwala />{" "}
                    </Route>
                    <Route path="/en/guests/hiralchandrana" exact>
                      <GuestHiralchandrana />{" "}
                    </Route>
                    <Route path="/en/guests/mainakdhar" exact>
                      <GuestMainakdhar />{" "}
                    </Route>
                    <Route path="/en/guests/manishm" exact>
                      <GuestManishm />{" "}
                    </Route>
                    <Route path="/en/guests/meghatata" exact>
                      <GuestMeghatata />{" "}
                    </Route>
                    <Route path="/en/guests/mukundvasudevan" exact>
                      <GuestMukundvasudevan />{" "}
                    </Route>
                    <Route path="/en/guests/nandinidias" exact>
                      <GuestNandinidias />{" "}
                    </Route>
                    <Route path="/en/guests/pauldupuis" exact>
                      <GuestPauldupuis />{" "}
                    </Route>
                    <Route path="/en/guests/prabhjeetsingh" exact>
                      <Guestprabhjeetsingh />{" "}
                    </Route>
                    <Route path="/en/guests/radhikagupta" exact>
                      <GuestRadhikagupta />{" "}
                    </Route>
                    <Route path="/en/guests/raghavgupta" exact>
                      <GuestRaghavgupta />{" "}
                    </Route>
                    <Route path="/en/guests/rajeshr" exact>
                      <GuestRajeshr />{" "}
                    </Route>
                    <Route path="/en/guests/rajit" exact>
                      <GuestRajit />{" "}
                    </Route>
                    <Route path="/en/guests/salilmurthy" exact>
                      <GuestSalilmurthy />{" "}
                    </Route>
                    <Route path="/en/guests/sameergarde" exact>
                      <GuestSameergarde />{" "}
                    </Route>
                    <Route path="/en/guests/sanjaybehl" exact>
                      <GuestSanjaybehl />{" "}
                    </Route>
                    <Route path="/en/guests/sarbvir" exact>
                      <GuestSarbvir />{" "}
                    </Route>
                    <Route path="/en/guests/sridhar" exact>
                      <GuestSridhar />{" "}
                    </Route>
                    <Route path="/en/guests/suchetamahapatra" exact>
                      <GuestSuchetamahapatra />{" "}
                    </Route>
                    <Route path="/en/guests/sudipghose" exact>
                      <GuestSudipghose />{" "}
                    </Route>
                    <Route path="/en/guests/umesh" exact>
                      <GuestUmesh />{" "}
                    </Route>
                    <Route path="/en/guests/vinayagrawal" exact>
                      <GuestVinayagrawal />{" "}
                    </Route>
                    <Route path="/en/guests/Chandrakant" exact>
                      <GuestChandrakant />
                    </Route>
                    <Route path="/en/guests/AshishMunjal" exact>
                      <GuestAshishMunjal />
                    </Route>
                    <Route path="/en/guests/shilpa-khanna" exact>
                      <GuestShilpaKhanna />
                    </Route>
                    <Route path="/en/guests/girish-rowjee" exact>
                      <GuestGirishRowjee />
                    </Route>
                    <Route path="/en/guests/samina-bano" exact>
                      <GuestSaminaBano />
                    </Route>
                    <Route path="/en/guests/HarshvardhanLunia" exact>
                      <GuestHarshvardhanLunia />
                    </Route>
                    <Route path="/en/guests/AmitabhBehar" exact>
                      <GuestAmitabhBehar />
                    </Route>
                    <Route path="/en/guests/prativa-mohapatra" exact>
                      <GuestPrativaMohapatra />
                    </Route>

                    <Route
                      path="/policy/terms-of-use"
                      exact
                      component={JioTermsOfUse}
                    />
                    <Route
                      path="/policy/privacy-policy"
                      exact
                      component={JioprivacyPolicy}
                    />
                    <Route
                      path="/policy/disclaimer"
                      exact
                      component={JioDisclaimer}
                    />
                  </Switch>
                </Suspense>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <Loader
                    visible={this.state.isLoading}
                    type="Oval"
                    color="#EB1163"
                    height="80vh"
                    width={60}
                  />
                </div>
              )}
            </div>
          )}
        </header>

        <div className="player-background">
          <div className="audioPlayer" style={styles.audioPlayer}>
            <Container fluid className="playerSec">
              <Row>
                <Col xs={2} md={2} lg={1} xl={2} style={styles.miniAlbumArt}>
                  {/*  MOBILE MINI PALYER ADS IMAGE HANDEL */}
                  {this.state.show.advertisements &&
                  this.props.AdplayData &&
                  this.state.show.advertisements.length > 0 ? (
                    <div
                      style={{
                        backgroundColor: "black",
                        borderRadius: "15px 15px 0px",
                      }}
                    ></div>
                  ) : (
                    <Image
                      fluid
                      onClick={this.switchToFullPlayer}
                      className="episodePic"
                      src={
                        playlist[this.state.currentMusicIndex].featuredImageUrl
                      }
                      alt="episode pic"
                    />
                  )}
                </Col>
                <Col
                  xs={10}
                  sm={10}
                  md={10}
                  xl={10}
                  lg={11}
                  style={
                    this.checkEpisodePath()
                      ? {}
                      : { paddingLeft: "inherit", paddingRight: "inherit" }
                  }
                >
                  <AudioPlayer
                    controls
                    ref={this.player}
                    className="audioPlayertrackResume"
                    style={
                      this.checkEpisodePath()
                        ? styles.fullAudioPlayer
                        : isMobile && !isTablet
                        ? styles.miniAudioPlayer
                        : styles.fullAudioPlayer
                    }
                    onClickPrevious={this.handleClickPrevious}
                    onPlay={() => this.trackResume()}
                    onPause={() => this.trackPause()}
                    onClickNext={() => this.handleClickNext()}
                    // onListen={() => this.HandelOnListen()}
                    layout={
                      this.checkEpisodePath() && !isMobile
                        ? "stacked-reverse"
                        : isMobile && !isTablet
                        ? styles.miniAudioPlayer
                        : "stacked-reverse"
                    }
                    onError={() => toast.error("Could'nt fetch data!")}
                    autoPlayAfterSrcChange={true}
                    showSkipControls={
                      this.checkEpisodePath()
                        ? true
                        : isMobile && !isTablet
                        ? false
                        : true
                    }
                    showJumpControls={
                      this.checkEpisodePath()
                        ? true
                        : isMobile && !isTablet
                        ? false
                        : true
                    }
                    loop={this.state.repeat}
                    src={
                      this.state.show.advertisements &&
                      this.props.AdplayData &&
                      this.state.show.advertisements.length > 0
                        ? this.state.show.advertisements[0].adAudioUrl
                        : this.state.playlist[this.state.currentMusicIndex]
                            .audioFileUrl
                    }
                    progressJumpSteps={{ backward: 10000, forward: 10000 }}
                    customControlsSection={[
                      <div style={styles.miniAlbumArtContainer}>
                        {/*  START Handle ADS MINI AUDIO PLAYER */}
                        {this.state.show.advertisements &&
                        this.props.AdplayData &&
                        this.state.show.advertisements.length > 0 ? (
                          <div style={styles.AdsHeadingMobile}>
                            <h3
                              style={{
                                color: "#fffc00",
                                fontSize: "12px",
                                fontFamily: "BwModelica-Light",
                                // cursor: "pointer",
                              }}
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={
                                this.state.show.advertisements.length > 0
                                  ? this.state.show.advertisements[0].adTitle
                                  : ""
                              }
                            >
                              Ads by
                              <span
                                style={{ fontWeight: 800, marginLeft: "4px" }}
                              >
                                {this.state.show.advertisements[0].adTitle}
                              </span>
                            </h3>
                            <a
                              href={
                                this.state.show.advertisements.length > 0
                                  ? this.state.show.advertisements[0]
                                      .adTargetUrl
                                  : ""
                              }
                              target="_blank"
                              style={{
                                color: "black",
                                fontSize: "11px",
                                marginLeft: "5px",
                                backgroundColor: "#fffc00",
                                fontWeight: 800,
                                padding: "3px 9px",
                                borderRadius: "5px",
                              }}
                            >
                              Know More
                            </a>
                          </div>
                        ) : (
                          <>
                            <h4
                              style={styles.miniAlbumArtShowTitle}
                              onClick={this.switchToFullPlayer}
                            >
                              {playlist[this.state.currentMusicIndex]
                                .episodeNo +
                                ". " +
                                this.state.playlist[
                                  this.state.currentMusicIndex
                                ].title}
                            </h4>
                            <p style={styles.miniAlbumArtEpisodeTitle}>
                              {this.state.PlayerCurrShow.title === "None"
                                ? null
                                : this.state.PlayerCurrShow.title}
                            </p>
                          </>
                        )}
                        {/* END Handle ADS MINI AUDIO PLAYER */}
                      </div>,

                      <div style={styles.plyrAdditionalBtns}>
                        <span className="desktop-only episode-details-inner">
                          {this.state.show.advertisements &&
                          this.props.AdplayData &&
                          this.state.show.advertisements.length > 0 ? (
                            <div style={styles.AdsHeading}>
                              <h3
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  fontFamily: "BwModelica-Light",
                                  // cursor: "pointer",
                                }}
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={
                                  this.state.show.advertisements.length > 0
                                    ? this.state.show.advertisements[0].adTitle
                                    : ""
                                }
                              >
                                Ads by
                                <span
                                  style={{ fontWeight: 800, marginLeft: "4px" }}
                                >
                                  {this.state.show.advertisements[0].adTitle}
                                </span>
                              </h3>
                              <a
                                href={
                                  this.state.show.advertisements.length > 0
                                    ? this.state.show.advertisements[0]
                                        .adTargetUrl
                                    : ""
                                }
                                target="_blank"
                                style={{ color: "black", fontSize: "12px" }}
                              >
                                Know More
                              </a>
                            </div>
                          ) : (
                            <Image
                              className="PlayerEpisodePic"
                              style={{ cursor: "pointer" }}
                              src={
                                playlist[this.state.currentMusicIndex]
                                  .featuredImageUrl
                              }
                              onError={(e) => {
                                e.currentTarget.src = Thumbnail;
                              }}
                              onClick={() =>
                                this.state.show.advertisements &&
                                this.props.AdplayData &&
                                this.state.show.advertisements.length > 0
                                  ? null
                                  : this.state.PlayerCurrShow.shortUrl
                                  ? this.props.history.push(
                                      `/show/${this.state.PlayerCurrShow.shortUrl}`
                                    )
                                  : null
                              }
                            />
                          )}

                          <span
                            className="player-episode-detail"
                            onClick={() =>
                              this.state.show.advertisements &&
                              this.props.AdplayData &&
                              this.state.show.advertisements.length > 0
                                ? null
                                : this.state.PlayerCurrShow.shortUrl
                                ? this.props.history.push(
                                    `/show/${this.state.PlayerCurrShow.shortUrl}`
                                  )
                                : null
                            }
                          >
                            {this.state.show.advertisements &&
                            this.props.AdplayData &&
                            this.state.show.advertisements.length > 0 ? null : (
                              <div
                                style={{
                                  width: "400px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <h3
                                  className="player-episode-title"
                                  style={{
                                    textOverflow: "ellipsis",
                                    width: "150px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    cursor: "pointer",
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={
                                    playlist[this.state.currentMusicIndex]
                                      .episodeNo +
                                    ". " +
                                    playlist[this.state.currentMusicIndex].title
                                  }
                                >
                                  {playlist[this.state.currentMusicIndex].title
                                    .length > 30 ? (
                                    <Marquee direction="left">
                                      {playlist[this.state.currentMusicIndex]
                                        .episodeNo +
                                        ". " +
                                        playlist[this.state.currentMusicIndex]
                                          .title}
                                    </Marquee>
                                  ) : (
                                    playlist[this.state.currentMusicIndex]
                                      .episodeNo +
                                    ". " +
                                    playlist[this.state.currentMusicIndex].title
                                  )}
                                </h3>
                                <h5
                                  className="stitle"
                                  style={{
                                    textOverflow: "ellipsis",
                                    width: "150px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    cursor: "pointer",
                                  }}
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={
                                    this.state.PlayerCurrShow.title === "None"
                                      ? null
                                      : this.state.PlayerCurrShow.title
                                  }
                                >
                                  {this.state.PlayerCurrShow.title ===
                                  "None" ? null : this.state.PlayerCurrShow
                                      .title.length > 30 ? (
                                    <Marquee direction="left">
                                      {this.state.PlayerCurrShow.title}
                                    </Marquee>
                                  ) : (
                                    this.state.PlayerCurrShow.title
                                  )}
                                </h5>
                              </div>
                            )}
                          </span>
                        </span>

                        {this.checkEpisodePath() ? (
                          <Image
                            className="mobile-only-imp"
                            fluid
                            src={playlistWhite}
                            width="25"
                            onClick={() =>
                              this.setState({ viewPlaylist: true })
                            }
                          ></Image>
                        ) : null}
                      </div>,
                      this.state.show.advertisements ? (
                        this.state.show.advertisements.length &&
                        this.props.AdplayData ? (
                          <div style={{ height: "50px" }}></div>
                        ) : (
                          RHAP_UI.MAIN_CONTROLS
                        )
                      ) : (
                        RHAP_UI.MAIN_CONTROLS
                      ),

                      <div style={styles.plyrAdditionalBtns}>
                        {this.state.show.advertisements &&
                        this.props.AdplayData &&
                        this.state.show.advertisements.length > 0 ? null : this
                            .state.playlist[this.state.currentMusicIndex]
                            .hasLiked ? (
                          <div style={styles.plyLikeBtn} className="likeCounts">
                            <Image
                              className="likeCountImg"
                              onClick={this.onEpisodeDisike}
                              fluid
                              src={plyrlkeIcn}
                            />
                            <p className="likeCountNo">
                              {
                                this.state.playlist[
                                  this.state.currentMusicIndex
                                ].likesCount
                              }
                              Likes
                            </p>
                          </div>
                        ) : (
                          <div style={styles.plyLikeBtn} className="likeCounts">
                            <i
                              className="far fa-heart"
                              onClick={this.onEpisodeLike}
                            ></i>
                          </div>
                        )}
                      </div>,
                    ]}
                    customIcons={{
                      play:
                        this.checkEpisodePath() && isMobile ? (
                          !isTablet ? (
                            <i className="far fa-play-circle"></i>
                          ) : (
                            this.playBtnIcon
                          )
                        ) : (
                          this.playBtnIcon
                        ),
                      pause:
                        this.checkEpisodePath() && isMobile ? (
                          !isTablet ? (
                            <i className="far fa-pause-circle"></i>
                          ) : (
                            this.pauseBtnIcon
                          )
                        ) : (
                          this.pauseBtnIcon
                        ),
                      rewind:
                        this.checkEpisodePath() && isMobile
                          ? !isTablet
                            ? this.RewindReverse
                            : this.backwardBtnIcon
                          : this.backwardBtnIcon,
                      forward:
                        this.checkEpisodePath() && isMobile
                          ? !isTablet
                            ? this.RewindReverseForword
                            : this.forwardBtnIcon
                          : this.forwardBtnIcon,
                    }}
                    onEnded={
                      this.props.AdplayData
                        ? this.handleAdsNextTrack
                        : this.handleNextTrack
                    }
                    // onEnded={this.handleNextTrack}
                  />

                  {/* ads section */}
                  {/* <div
                    style={
                      this.checkEpisodePath()
                        ? styles.AdsStyleDesktop
                        : isMobile && !isTablet
                        ? styles.AdsStyleMobile
                        : styles.AdsStyleDesktop
                    }
                  >
                    <div
                      style={{
                        width: "40%",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ color: "red", fontSize: "15px" }}>
                        {this.state.show.advertisements.length > 0
                          ? this.state.show.advertisements[0].adTitle
                          : ""}
                      </p>
                      <p>
                        {this.state.show.advertisements.length > 0
                          ? this.state.show.advertisements[0].adDetails
                          : ""}
                      </p>
                    </div>

                    <div>
                      <button onClick={() => this.handelAds()}>skip</button>
                    </div>
                  </div> */}
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        {/* {
			  this.state.isSignedIn ? <div id='firebaseui-auth-container' style={{display: 'none'}}></div> : null
			} */}

        {/* {this.state.LoginUser === false ? (
          <div id="firebaseui-auth-container" style={{ display: "none" }}></div>
        ) : null} */}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setPause: () => dispatch(setPause(false)),
    setPlay: () => dispatch(setPlay(true)),
    setAdplay: () => dispatch(setAdplay(true)),
    setAdPause: () => dispatch(setAdPause(false)),
  };
};
const mapStateToProps = (state) => {
  return {
    AdplayData: state.player.Adplay,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
