import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Suparna from './img/clients/Suparna.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestSuparna extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={Suparna} alt="Suparna-Mitra" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Suparna Mitra, CEO,Watches & Wearables Division, Titan Company Ltd.</Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted">Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        Suparna Mitra, CEO of the Watches and Wearables division, Titan Company Ltd, took on the mantle of CEO at the beginning of the pandemic in April 2020. From braving zero sales in those initial months when the world was suspended in animation, to celebrating the thrill of achieving ambitious targets, Suparna has always believed in leading from the front. One of the few women CEOs in India, she believes our destiny is a result of our everyday actions, and that leaders have to believe in themselves much before the world recognises their potential. She talks about her growing up years, how things have changed for women in the workplace, her mentors at IIM Calcutta, her passion for characters she finds both in books, and in real life during her travels. Hear more from this inspiring boss in conversation with Deepali Naair, on BeingCEO.
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/prod-aawz2-bckt/episode/epi_0a8d00.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                    <Card.Text>Last updated 3 mins ago</Card.Text>

                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestSuparna);