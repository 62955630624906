import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import AnilKumar from './img/clients/Being-CEO-Ep-Anil-Kumar.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestAniljain extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={AnilKumar} alt="Anil-Kumar" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Anil Kumar Jain - CEO, National Internet Exchange of India (NIXI)</Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted">Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        Motivated to serve the country, Anil Kumar Jain charted his career path to the Government of India. An engineer by training and profession, he has spent 36 glorious years making his mark in the telecom industry. Hailing from a humble background he has truly scaled the peak by passionately fulfilling leadership positions at BSNL, MTNL and the Department of Telecommunications, India. Currently, the CEO of the National Internet Exchange of India (NIXI), Anil aims to make the best internet services accessible and affordable for every individual in the country. His life story is nothing but enthralling. Tune into this freewheeling, inspirational conversation as he shares golden nuggets on leadership, culture building, serving the nation and more.
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2021/03/Ep.-24-Anil-Kumar-Jain-Deepali_2.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestAniljain);