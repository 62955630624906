import React, { Component } from "react";
import Logo from "../../../img/logo.png";
import GuestStyle from "./css/guest_style.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from "react-bootstrap";
import { withRouter } from "react-router";
import AmitMalik from "./img/clients/Being-CEO-Ep-Amit-Malik.jpg";
import AdsBanner from "./img/clients/banner.jpg";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

class GuestPrativaMohapatra extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="guest-header">
        <Container>
          <img src={Logo} alt="Aawaz.com logo" className="guest-logo" srcset="Aawaz.com logo" />
        </Container>
        </div>
        <body className="guest-body">
          <Container className="guest-sections">
            <Row>
              <Col>
                <img
                  src={
                    "https://storage.googleapis.com/prod-aawz2-bckt/optimised/episode/epi-3e5e27-featured-image-1666250968.webp"                    
                  }
                  alt="GuestPrativaMohapatra"
                  className="guest-img"
                />
              </Col>
              <Col>
                <Card className="mb-2 card bg-dark text-white">
                  <Card.Title className="m-2 text-white font-weight-bold">
                  Prativa Mohapatra, VP & MD, Adobe India
                  </Card.Title>
                  <h6 className="m-2 font-weight-bold text-muted">
                    Being CEO with Deepali Naair
                  </h6>
                  <Card.Text className="m-2 text-justify text-muted">
                  The entire state of Odisha was cheering Prativa Mohapatra when she became the first woman Vice President and Managing Director for Adobe India. She is no stranger to leadership roles having spent decades at global technology majors like IBM & PwC. In this episode, Prativa Mohapatra talks to Deepali Naair about humble beginnings, her definition of success, the value of mentorship and how she takes tough decisions at the workplace.
                  </Card.Text>
                  <AudioPlayer
                    className="being-ceo-player"
                    src="https://storage.googleapis.com/prod-aawz2-bckt/episode/epi-3e5e27-1666250968-mp3.mp3"
                    layout="horizontal"
                    showJumpControls={false}
                    // hasDefaultKeyBindings={true}
                    showDownloadProgress={true}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    onPlay={(e) => console.log("onPlay")}
                    // other props here
                  />
                  <Card.Text>Last updated 3 mins ago</Card.Text>
                </Card>
                <img
                  src={AdsBanner}
                  alt="Guest page ads banner"
                  onClick={() =>
                    this.props.history.push(
                      "/show/being-ceo-with-deepali-naair"
                    )
                  }
                  style={{ cursor: "pointer" }}
                />
              </Col>
            </Row>
          </Container>
          <section id="body"></section>
        </body>
      </React.Fragment>
    );
  }
}

export default withRouter(GuestPrativaMohapatra);
