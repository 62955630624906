import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom';


const IBM = () => {

    useEffect(() => {
        window.location.href = ' https://www.aaw.az/ibm/';
    }, [])
    return (
        <div></div>
    )
}

export default withRouter(IBM)