import React, { Component } from "react";
import "./Footer.css";

class AlexaPrivacyPolicy extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="page-content container-fluid">
          <h1 style={{ textAlign: "center", fontFamily: "BwModelica-Bold" }}>
            “Aawaz dot com” Skill on Alexa
          </h1>
          <h2
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "bold",
              margin: "20px auto",
              fontFamily: "BwModelica-Bold",
            }}
          >
            Privacy Policy
          </h2>
          <p className="page-para">
            Aawaz dot com respects and recognizes the privacy and security of
            the users of Aawaz Dot Com Skill and appreciate that your
            (hereinafter referred to as &#39;You&#39;, &#39;Your&#39;,
            &#39;User&#39;) personal information is very important. In order to
            provide you the information and resources that are most relevant and
            helpful to you, we may collect information during your usage of
            skill.In order to demonstrate our commitment to your privacy, we
            have prepared this Privacy Policy, which describes the treatment of
            information collected and posted/transmitted through the skill.
          </p>
          <br />
          <p className="page-para">
            {" "}
            By using this skill, you signify your consent to the terms of this
            Privacy Policy. If you do not agree to any of the provisions of this
            Privacy Policy, You should not enable or access and use this skill.
          </p>
          <br />
          <p className="page-para">
            This Privacy Policy explains what information of yours collected,
            sets out key information regarding our collection, use, process,
            disclosure and storage of your personal information, how and when we
            might use your information, how we protect your information, who has
            access to your information. In order to fully understand your
            rights, we encourage you to read this Privacy Policy.
          </p>
          {/* What information does Aawaz Dot Com skill gathers or track about you: */}
          <strong className="sub-header">
            What information does Aawaz Dot Com skill gathers or track about
            you:
          </strong>
          <p className="page-para">
            Aawaz dot com may collect or require you to furnish information when
            you use skill. Aawaz dot com may collect personally identifiable
            information viz., your name, e-mail, address, date of birth, gender,
            profession, marital status, contact numbers, voice commands, etc.
            You hereby expressly consent Aawaz dot com to store, retain or
            transfer the information or data that voluntarily supplied herein
            and process and use it by Aawaz dot com or its affiliates,
            associates, Dealers, Agencies, etc. for providing products &amp;
            services, improving services and to contact you through Telephone/
            Mobile Numbers or send SMS or Email for marketing and/or promotions,
            product related information, newsletter, etc. and other legitimate
            purposes. Aawaz dot com has taken adequate security measures to
            secure access to your data or information.
          </p>
          <br />
          <p className="page-para">
            The primary purpose of collecting information from you, is to allow
            you to use this skill and its various features and services made
            available on or in relation to the skill, app or any websites or for
            any services provided by Aawaz dot com or affiliates or its various
            service providers or business partners and advertisers; to record
            your information and details; to serve various promotion materials
            and advertising materials to you; and such other uses as provided in
            this Privacy Policy. Aawaz dot com may also use your information for
            news or/and content recommendations, transactional emails or to
            provide you with information, direct marketing, online and offline
            advertising and other materials regarding products, services and
            other offers from time to time. Promotions that run on the skill may
            be sponsored by companies other than Aawaz dot com or may be
            co-sponsored by another company. Some or all data collected during a
            promotion may be shared with the sponsor.
          </p>
          {/* What does Aawaz dot com do with the information? */}
          <strong className="sub-header">
            What does Aawaz dot com do with the information?
          </strong>
          <p className="page-para">
            We use the data or information gathered to send you information
            about Product, features, etc. Your information is also used to
            contact you when necessary. Aawaz dot com may also use the
            information for internal research &amp; quality improvement, user
            demographics, interests and behaviour, for providing you with the
            latest information, updates, which you may be interested in services
            including services of Aawaz dot com Affiliates or third parties. We
            may combine personal information collected from you with information
            from other services or third parties to provide a better user
            experience, that display customized content and advertising
            including customizing content for you. The Company may share the
            information with our affiliates, dealers, marketing partners or any
            third party contracted to do business on our behalf or for product
            improvements, feature enhancements, bug fixing, technological
            improvement, etc. If you sign up for certain services and offers
            with us, we will share names, or other contact information necessary
            for any associate third party to provide these services.
          </p>
          <br />
          <p className="page-para">
            We will also use your Internet Protocol address, and e-mail
            addresses, primary phone number etc. to help diagnose problems with
            our server and to administer the Skill. Please note that any
            information that is disclosed in the public areas of this Skill
            becomes in fact public and you should exercise caution when deciding
            to post your personal data. Aawaz dot com tracks some traffic data
            &amp; records the basic information about visits to the Site, for
            systems administration, statistical, diagnose problem with our
            server and to administer our Site and troubleshooting purposes. Each
            time a user/visitor comes to skill , the skill app server logs some
            basic technical information such as Operating system, device
            information, platform, domain name, referral data, demographic
            details, etc. We use your personal data or information to tailor
            your experience showing you content that we think you might be
            interested in and displaying the content according to your
            preferences.
          </p>
          {/* How Aawaz dot com protects your information? */}
          <strong className="sub-header">
            How Aawaz dot com protects your information?
          </strong>
          <p className="page-para">
            The privacy and protection of your information is vitally important
            to us. All personal data or information accumulated will be
            acquired, processed, and used according to the applicable
            regulations governing the protection of personal data for the sole
            purpose of managing and maintaining Aawaz dot com own legitimate
            business interests. Any user statistics that we may provide to
            partners regarding your Site usage are provided in the aggregate
            only and do not include any personally identifiable information
            about any individual user. No data transmissions over the internet
            or any other mode can be guaranteed to be completely secure. Despite
            our efforts to protect your information through security procedures
            commensurate with industry standards to safeguard against loss,
            theft, unauthorized access, destruction, use, modification and
            disclosure, we cannot assure or warrant that security of any Data or
            information that you provide or transmit to Aawaz dot com will never
            be disclosed in a manner that is inconsistent with the Policy or
            ensure or warrant the security of any information you transmit to
            us. You transmit all such information at your own risk.
          </p>
          <br />
          {/* With whom does Aawaz dot com share the information? */}
          <strong className="sub-header">
            With whom does Aawaz dot com share the information?
          </strong>
          <p className="page-para">
            Information, including Personal Information, location, your Device
            identifiers etc. about you supplied to us may be shared with third
            parties viz., affiliates, group companies, auditors, authorized
            dealers, legal advisers and marketing partners etc. contracted to
            provide services on our behalf. Aawaz dot com as it deems
            appropriate may use your contact information for the use of any
            affiliate, dealer or third party. Aawaz dot com may disclose
            information if required to do so by any law enforcing agency or on
            an order from any court or competent authority. We may also transfer
            your information to any of our Affiliate or group company outside
            India.
          </p>
          <br />
          {/* Third party websites */}
          <strong className="sub-header">Third party websites</strong>
          <p className="page-para">
            Skill may contain links to Applications/websites, services etc.
            owned or operated or managed by third parties. We take no
            responsibility for the privacy practices or content of third party
            Applications/websites, services or businesses. We recommend that you
            thoroughly read the privacy policies of those third party
            applications/websites, services, businesses etc.
          </p>
          <br />
          {/* Changes to the Privacy Policy*/}
          <strong className="sub-header">Changes to the Privacy Policy</strong>
          <p className="page-para">
            Aawaz dot com reserves the right at any time and without notice, at
            its sole discretion to revise, alter, add, amend, change or modify
            and/or delete any provisions of this Privacy Policy from time to
            time simply by updating such change on this page. By enabling and/or
            your continued access or using the Skill following the posting of
            changes to this Privacy Policy, it will be deemed you agree to be
            bound by any such alteration, amendment, addition or modification.
            Please check our Privacy Policy regularly for updates. To the extent
            permitted by law, nothing in this policy is intended to hold Aawaz
            dot com liable for any failure to comply with this Privacy Policy.
          </p>
          <br />
          <p className="page-para">
            This Privacy Policy should be read in conjunction with our Aawaz dot
            com Privacy Policy and other legal documents such as cookie policies
            as amended, as provided for Aawaz System/ Aawaz. In the event of a
            conflict between any provision contained in this Privacy Policy and
            any provision of any other document (including Bluelink Terms of
            Use, Privacy Policy) incorporated herein, the provision contained in
            this Privacy Policy shall take precedence only to the extent of such
            variation. By submitting your personal data through this website/
            Skill, you shall be consenting to it being processed in the manner
            described by Aawaz dot com, its affiliates, third parties around the
            world and our hosting organizations around the world.
          </p>
          <br />
          {/*4)Contact:*/}
          <strong className="sub-header">Contact:</strong>
          <p className="page-para">
            If You have any questions about this Privacy Policy, practices of
            this Action or Your dealings with this Action or any of the features
            or if your personal information changes, you shall within 7 days
            correct or update the personal data provided to us, by contacting us
            via email at :
            <a href="mailto:developer@agrahyah.com">developer@agrahyah.com</a>
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default AlexaPrivacyPolicy;
