import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import {
  Nav,
  Navbar,
  Col,
  Dropdown,
  ToggleButtonGroup,
  Button,
} from "react-bootstrap";
import userLogin from "../../../img/user-profile.svg";
import HeaderSearch from "./HeaderSearch";
import SearchIcon from "../../../img/search.png";
import { YoloProvider } from "react-googleyolo";
import SideNav from "./SideNav";
import Logo from "../../../img/logo.png";
// import Logo from "../../../img/festive/diwali/diwali-logo.gif";
import LiveBtn from "../../../img/svg/live-btn-gif.gif";
import MoreBtn from "../../../img/svg/more-nav.svg";
import langBtn from "../../../img/language-icon.svg";
import darkBtn from "../../../img/dark-btn.svg";
import languageIcon from "../../../img/icons/language-icon.svg";
import languageActiveIcon from "../../../img/icons/language-active.svg";
import FlagLogo from "../../../img/flagLogo.jpeg";

import Garland from "../../../img/festive/diwali/lamp.png";
import Matki from "../../../img/Janmastmi/matki.gif";
import GanpatiLogo from "../../../img/GaneshFestival/Ganpatilogo7.png";

import "../../../css/pages.css";
import btn from "./img.png.png";
import firebase from "firebase/app";
class DesktopHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { user } = this.props;
    return (
      <React.Fragment>
        {/* <span className="matki">
          <img
            src={Matki}
            alt="Matki"
            style={{
              width: "180px",
              position: "absolute",
              zIndex: 999,
              right: "22%",
            }}
          />
        </span> */}
        {/* <span className="Garland">
            <img
              src={Garland}
              alt="Garland"
              style={{
                width: "100%",
                position: "absolute",
                zIndex: 0,
                top: "40px",
                backgroundColor: "white",
                boxShadow: "1px 3px 3px #ebebeb"
              }}
            />
          </span> */}
        <div className="background">
          <div className="desktop-only header-nav">
            <Navbar
              bg="white"
              variant="light"
              className="sticky-navbar bg-transparent"
            >
              <Col lg={1} md={1}>
                <Navbar.Brand>
                  <Link to={`/`}>
                    <img
                      alt=""
                      src={Logo}
                      // width={"245px"}
                      className="logo d-flex justify-content-center flex-wrap"
                    />
                  </Link>
                </Navbar.Brand>
              </Col>
              <Col lg={3} md={1}></Col>
              <Col lg={6} md={7}>
                <Nav className="main-menu desktop-nav">
                  <NavLink exact activeClassName="activeheader" to="/">
                    <span className="headerHover">Home</span>
                  </NavLink>
                  <NavLink
                    exact
                    activeClassName="activeheader"
                    to="/categories"
                  >
                    <span className="headerHover">Categories</span>
                  </NavLink>
                  <NavLink exact activeClassName="activeheader" to="/channels">
                    <span className="headerHover">Channels</span>
                  </NavLink>
                  <NavLink exact activeClassName="activeheader" to="/read">
                    <span className="headerHover">Read</span>
                  </NavLink>
                  {/* <a exact activeClassName="activeheader" href="https://read.aawaz.com/"><span className="headerHover">Read</span></a> */}
                  <NavLink exact activeClassName="activeheader" to="/live">
                    {" "}
                    <img
                      width="10"
                      src={LiveBtn}
                      alt="img"
                      className="live-gif"
                    />
                  </NavLink>
                  <a activeClassName="activeheader">
                    <span
                      className="dropdownHeaderMore-container"
                      tabIndex="-1"
                    >
                      <img src={MoreBtn} className="info-icon" />
                      <div className="dropdownHeaderMore">
                        <NavLink
                          exact
                          activeClassName="activeheader"
                          to="/contact"
                        >
                          <div className="headerHover">Contact</div>
                        </NavLink>
                        <NavLink
                          exact
                          activeClassName="activeheader"
                          to="/about-us"
                        >
                          <div className="headerHover">About</div>
                        </NavLink>
                      </div>
                    </span>
                  </a>
                  {/* <a exact activeClassName="activeheader" href="https://live.aawaz.com"><span className="live-btn"> <img width="30" src={LiveBtn} alt="img" /> Live</span></a> */}
                </Nav>
              </Col>
              <Col lg={1} md={1}></Col>
              <Col lg={1} className="right-menu" md={2}>
                <span
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Select your Language"
                  onClick={() => this.props.userLangPrefBottomPrompt()}
                  style={{ cursor: "pointer", paddingRight: "21px" }}
                >
                  {" "}
                  <img
                    src={
                      this.props.defaultUserLangPref
                        ? this.props.defaultUserLangPref.length >= 1 &&
                          this.props.defaultUserLangPref.length < 4
                          ? languageActiveIcon
                          : languageIcon
                        : languageIcon
                    }
                    alt="img"
                  />
                </span>
                {/* <span data-toggle="tooltip" data-placement="bottom" title="Select your Theme" style={{ paddingRight: "16px" }}><img src={darkBtn} /> </span> */}
                <span
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Login Here"
                >
                  <span style={{ cursor: "pointer" }} data-tip="Login here">
                    {user === null || user.isAnonymous ? (
                      <img
                        alt=""
                        className="user-btn"
                        style={{ borderRadius: "50%", marginRight: "18px" }}
                        src={userLogin}
                        onClick={() => this.props.toggleLoginPrompt()}
                      />
                    ) : (
                      <span
                        className="dropdownHeaderProfile-container"
                        tabIndex="-1"
                      >
                        <NavLink to="/user-profile" exact={true}>
                          <img
                            alt="user"
                            style={{
                              height: "30px",
                              width: "30px",
                              borderRadius: "50%",
                            }}
                            className="Profile-icon"
                            src={user.photoURL || userLogin}
                            onError={(e) => {
                              e.currentTarget.src = userLogin;
                            }}
                          />
                        </NavLink>
                      </span>
                    )}
                  </span>
                </span>
              </Col>
            </Navbar>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(DesktopHeader);
