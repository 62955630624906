import axios from 'axios'

let baseUrl = "https://logs.aawaz.com/logs"


const getAnalytics = () => {
    return axios({
        method: "GET",
        url: `${baseUrl}/player`
    })
}

const postAnalytics = (data) => {
    return axios({
        method: "POST",
        headers: { 'Content-Type': "application/json" },
        url: `${baseUrl}/player/`,
        data: data
    })
}


export {
    getAnalytics, postAnalytics
}