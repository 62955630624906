import React from 'react';
import MenuArrow from '../../../img/ic_title_caret.svg'
import firebase from 'firebase/compat/app';
import { Link } from 'react-router-dom';


const Settings = (props) => {

    // const [value, setValue] = React.useState(0);

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    const Login = () => {
        localStorage.setItem("login", false)
        console.log("hello")
    }
    return (
        <React.Fragment>
            <div onClick={props.toggleLanguage} className="NotifyMessage active">
                <h4>Language<span><img alt="show-img" src={MenuArrow} /></span></h4>
            </div>
            <div className="NotifyMessage">
                <a href="https://play.google.com/store/apps/details?id=com.aawaz" target="_blank"><h4>Rate us on Google Play<span><img alt="show-img" src={MenuArrow} /></span></h4></a>
            </div>
            <div className="NotifyMessage">
                <a href="https://telegram.me/AawazDotCom" target="_blank"><h4>Join us on Telegram<span><img alt="show-img" src={MenuArrow} /></span></h4></a>
            </div>
            <div className="NotifyMessage">
                <Link to={`/faq`}><h4 style={{ color: 'black' }}>FAQs<span><img alt="show-img" src={MenuArrow} /></span></h4></Link>
            </div>
            <div className="NotifyMessage">
                <Link to={`/privacy-policy`}><h4 style={{ color: 'black' }}>Privacy Policy<span><img alt="show-img" src={MenuArrow} /></span></h4></Link>
            </div>
            <div className="NotifyMessage">
                <Link to={`/terms-of-use`}><h4 style={{ color: 'black' }}>Terms of Use<span><img alt="show-img" src={MenuArrow} /></span></h4></Link>
            </div>
            <div className="NotifyMessage">
                <Link to={`/disclaimer`}><h4 style={{ color: 'black' }}>Disclaimer<span><img alt="show-img" src={MenuArrow} /></span></h4></Link>
            </div>
            <div className="NotifyMessage">
                <Link to={`/about-us`}><h4 style={{ color: 'black' }}>About<span><img alt="show-img" src={MenuArrow} /></span></h4></Link>
            </div>
            <div className="NotifyMessage">
                <Link to={`/contact`}><h4 style={{ color: 'black' }}>Contact<span><img alt="show-img" src={MenuArrow} /></span></h4></Link>
            </div>
            <div className="NotifyMessage">
                {props.user !== null && !props.user.isAnonymous ?
                    <div>
                        {/* <div><Link to='/myshows'>My Shows</Link></div> */}
                        <div>
                            <a onClick={() => {
                                firebase.auth().signOut()
                                localStorage.removeItem("firebaseui::rememberedAccounts")


                            }}><h4 style={{ color: 'black' }}>LogOut<span><img alt="show-img" src={MenuArrow} /></span></h4></a>
                        </div>
                    </div>
                    : null}

            </div>

        </React.Fragment>
    );
}

export default Settings;