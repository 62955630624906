import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Container, Card, Accordion, AccordionCollapse, Row, Col } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import auth from '../../../services/authentication'
import './sitemap.css'
const Sitemap = (props) => {

    let [cat, setCat] = useState([])
    let [cha, setCha] = useState([])
    let [shw, setShw] = useState([])
    useEffect(() => {
        axios.get(`https://prod.aawaz.com/api/category/?all=True`, { auth: auth })
            .then(res => {
                const categories = res.data
                setCat(res.data)
            })
            .catch((err) => {

            })
        axios.get(`https://prod.aawaz.com/api/channels/?all=True`, { auth: auth })
            .then(res => {
                const categories = res.data
                setCha(res.data)
            })
            .catch((err) => {

            })
        axios.get(`https://prod.aawaz.com/api/shows/?only_select=True&all=True`, { auth: auth })
            .then(res => {
                const categories = res.data
                setShw(res.data)
            })
            .catch((err) => {

            })
    }, [])
    return (
        <Container className='sitemapCon'>
            <h1>Sitemap</h1>
            <Row>

                <Col>
                    <Card>
                        <Card.Header>
                            <h1 style={{ fontSize: "14px" }}>Pages</h1>

                        </Card.Header>
                        <Card.Body>
                            <p className='sitecardlink'>
                                <Link to="/">Home</Link>
                            </p>
                            <p className='sitecardlink'>
                                <Link to="/about-us">About</Link>
                            </p>
                            <p className='sitecardlink'>
                                <Link to="/terms-of-use">Terms of use</Link>
                            </p>
                            <p className='sitecardlink'>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </p>
                            <p className='sitecardlink'>
                                <Link to="/disclaimer">Disclaimer</Link>
                            </p>
                            <p className='sitecardlink'>
                                <Link to="/faq"> FAQ</Link>
                            </p>
                            <p className='sitecardlink'>
                                <a href='https://business.aawaz.com/' target='_blank'>Business</a>
                            </p>
                            <p className='sitecardlink'>
                                <Link to="/contact">Contact</Link>
                            </p>
                        </Card.Body>
                    </Card>

                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            <h1 style={{ fontSize: "14px" }}>Read</h1>

                        </Card.Header>
                        <Card.Body>
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Accordion}
                                            variant="link" eventKey="0">
                                            <h1 style={{ fontSize: "14px" }}>Hindi</h1>

                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <p className='sitecardlink'>
                                                <Link to={`#`}>मनोरंजन</Link>
                                            </p>
                                            <p className='sitecardlink'>
                                                <Link to={`#`}>फूड</Link>
                                            </p>
                                            <p className='sitecardlink'>
                                                <Link to={`#`}>सेहत</Link>
                                            </p>
                                            <p className='sitecardlink'>
                                                <Link to={`#`}>यात्रा</Link>
                                            </p>
                                            <p className='sitecardlink'>
                                                <Link to={`#`}>कामकाज</Link>
                                            </p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Accordion}
                                            variant="link" eventKey="0">
                                            <h1 style={{ fontSize: "14px" }}>English</h1>

                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <p className='sitecardlink'>
                                                <Link to={`#`}> Food</Link>
                                            </p>
                                            <p className='sitecardlink'>
                                                <Link to={`#`}> Travel</Link>
                                            </p>
                                            <p className='sitecardlink'>
                                                <Link to={`#`}>Work</Link>
                                            </p>
                                            <p className='sitecardlink'>
                                                <Link to={`#`}>Culture</Link>
                                            </p>
                                            <p className='sitecardlink'>
                                                <Link to={`#`}>Celebrities</Link>
                                            </p>
                                            <p className='sitecardlink'>
                                                <Link to={`#`}>Health</Link>
                                            </p>
                                            <p className='sitecardlink'>
                                                <Link to={`#`}>Music</Link>
                                            </p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Card.Body>
                    </Card>

                </Col>
                <Col>
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Accordion}
                                    variant="link" eventKey="0">
                                    <h1 style={{ fontSize: "14px" }}>Categories</h1>

                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    {cat.map((item) => (
                                        <p className='sitecardlink'>
                                            <Link to={`/category/${item.shortUrl}`}>{item.name}</Link>
                                        </p>
                                    ))}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
                <Col>
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Accordion}
                                    variant="link" eventKey="0">
                                    <h1 style={{ fontSize: "14px" }}>Channels</h1>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    {cha.map((item) => (
                                        <p className='sitecardlink'>
                                            <Link to={`/channel/${item.shortUrl}`}>{item.title}</Link>
                                        </p>
                                    ))}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
                <Col>
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Accordion}
                                    variant="link" eventKey="0">
                                    <h1 style={{ fontSize: "14px" }}>Shows</h1>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body >
                                    {shw.map((item) => (
                                        <p className='sitecardlink'>
                                            <Link to={`show/${item.shortUrl}`}>{item.title}</Link>
                                        </p>
                                    ))}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>

            </Row>

        </Container>
    )
}

export default Sitemap