import React, { Component } from "react";
import Logo from "../../../img/logo.png";
import GuestStyle from "./css/guest_style.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from "react-bootstrap";
import { withRouter } from "react-router";
import AlokBansal from "./img/clients/Being-CEO-Ep-Alok-Bansal.jpg";
import AdsBanner from "./img/clients/BeingFoundersBanner.jpg";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

class GuestHarshvardhanLunia extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="guest-header">
          <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
        </div>

        <body
          className="guest-body"
          style={{ backgroundColor: "rgb(119 169 255)" }}
        >
          <Container className="guest-sections" style={{ padding: "53px" }}>
            <Row>
              <Col>
                <img
                  src={
                    "https://storage.googleapis.com/prod-aawz2-bckt/optimised/episode/epi_featured_image_ce8271.webp"
                  }
                  alt="Alok-Bansal"
                  className="guest-img"
                  style={{ height: "450px", width: "450px" }}
                />
              </Col>
              <Col>
                <Card className="mb-2 card bg-dark text-white">
                  <Card.Title className="m-2 text-white font-weight-bold">
                    Harshvardhan Lunia, Co-Founder & CEO, Lendingkart
                  </Card.Title>
                  <h6 className="m-2 font-weight-bold text-muted">
                    {" "}
                    Being Founders with Deepali Naair
                  </h6>
                  <Card.Text
                    className="m-2 text-justify text-muted"
                    style={{ fontSize: "14px" }}
                  >
                    Harshvardhan Lunia is the Co-Founder and CEO of Lendingkart,
                    one of the most popular fin-tech startups headquartered at
                    Ahmedabad, India. He followed his MBA degree with a banking
                    job in London- the kind of job and lifestyle that is an
                    aspiration and envy of most MBA pass outs. But besides
                    having one of the most ideal career trajectories,
                    Harshvardhan felt like he had a lot more to offer. In this
                    episode, Deepali delves into his early influences that led
                    to the building of Lendingkart and what it takes to design a
                    life that's untouched by wealth and fame.
                  </Card.Text>
                  <AudioPlayer
                    src="https://storage.googleapis.com/prod-aawz2-bckt/episode/epi_ce8271.mp3"
                    layout="horizontal"
                    showJumpControls={false}
                    customVolumeControls={[]}
                    onPlay={(e) => console.log("onPlay")}
                    // other props here
                  />
                  <Card.Text>Last updated 3 mins ago</Card.Text>
                </Card>
                <img
                  src={AdsBanner}
                  alt="Guest page ads banner"
                  onClick={() =>
                    this.props.history.push(
                      "/show/being-founders-with-deepali-nair"
                    )
                  }
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    // height: "95px",
                    border: "2px solid #EBEBEB",
                    borderRadius: "8px",
                    boxShadow: "3px 5px 7px #ebebeb",
                  }}
                />
              </Col>
            </Row>
          </Container>
          <section id="body"></section>
        </body>
      </React.Fragment>
    );
  }
}

export default withRouter(GuestHarshvardhanLunia);
