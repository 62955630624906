import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import './Search.css'
import { Container, Row, Col, Form, Spinner, Card } from 'react-bootstrap';

import { postSearch } from "../../../services/GetServices"
import voiceBtn from "../../../img/icons/ic_search_voice.svg"
import MobiSection from "../../HomeSection/GirdCarousel/MobiSection"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import Cancel from '../../../img/cancel.png'
import VoiceGif from '../../../img/gif/Voice-Search_gif.gif'
import { postRecentSearch } from '../../../services/GetServices';

const Search = (props) => {

    // getData: [],
    const [search, setSearch] = useState("")
    const [searchFound, setSearchFound] = useState(false)
    const [searchResult, setSearchResult] = useState([])
    const [searchedFor, setSearchedFor] = useState("")
    const [isGetLoading, setIsGetLoading] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const typingTimer = null



    const searchOnChangeHandler = (event) => (
        setSearch(event.target.value),
        setIsGetLoading(event.target.value.length > 0 ? true : false)
    )

    useEffect(() => {
        if (search !== " ") {
            postSearch({ search })
                .then(response => {
                    if (response.data.length !== 0) {
                        return (setSearchResult(response.data), setIsSearch(true), setSearchFound(true), setSearchedFor(search), setIsGetLoading(false), () => {
                            window.Moengage.track_event("Search", {
                                "query": search,
                                "user_id": firebase.auth().currentUser.uid,
                                "results": response.data,
                                "type": "Show"
                            })
                        })
                    }
                    else {
                        return (setIsSearch(true), setSearchFound(false), setSearchedFor(search), setIsGetLoading(false))
                    }

                })
                .catch(err => console.log(err))
        }

    }, [search])
    const {
        transcript,
        interimTranscript,
        finalTranscript,
        resetTranscript,
        listening,
    } = useSpeechRecognition();

    const showClickHandler = (url, showId) => {
        // props.PostRecentSearch(showId)

        props.history.push(`/show/${url}`)
    }

    const fetchShowData = (url) => {
        props.getShowEpisodes(url)
        props.history.push(`/show/${url}/episode/:${url}`)
    }


    const fetchCategoryData = (url) => {
        props.history.push(`/category/${url}`)
    }

    const fetchEpisodeData = (url) => {
        props.history.push(`/show/${url}`)
    }


    const fetchChannelData = (url) => {
        props.history.push(`/channel/${url}`)
    }

    const getContentDetails = (type, item) => {


        switch (type) {
            case "category":
                fetchCategoryData(item.shortUrl)
                break;
            case "channel":
                fetchChannelData(item.shortUrl)
                break;
            case "show":
                fetchEpisodeData(item.shortUrl)
                break;
            case "episode":
                this.props.history.push(`/show/${item.showShortUrl}/episode/${item.shortUrl}`)
                break;

            default:
                fetchShowData(item.shortUrl)
                break;
        }
    }
    useEffect(() => {
        if (props.searchData.length === 0) {
            props.getSearchData()
        }
    })
    useEffect(() => {
        if (finalTranscript !== '') {
            setSearch(finalTranscript)
        }
    }, [interimTranscript, finalTranscript]);
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        return null;
    }

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        console.log('Your browser does not support speech recognition software! Try Chrome desktop, maybe?');
    }
    const listenContinuously = () => {
        SpeechRecognition.startListening({
            continuous: false,
            language: 'en-GB',
        });
    };
    return (
        <Container className="searchDiv mobile-only">
            <Row>
                {/* <Col>{listening ? 'listening......' : ''}</Col> */}
                <Col xs={12} sm={12} md={12} lg={12}>
                    <Form.Control style={{ padding: '15px' }} value={search} placeholder="Search aawaz.com" className="form-control-input search-input-box" onChange={(event) => searchOnChangeHandler(event)} />
                    <img onClick={() => search !== "" ? setSearch("") : listenContinuously()} src={search !== "" ? Cancel : voiceBtn} className="search-btn" />
                    {/* <VoiceSearch /> */}
                </Col>
                {/* <Col xs={3} sm={3} md={3} lg={3}>
            <Button variant="primary" onClick={this.searchPostHandler}><i className="fas fa-search"></i></Button>
        </Col> */}

            </Row>
            {isGetLoading ?
                <Row className="justify-content-md-center">
                    <Col style={{ textAlign: 'center', paddingTop: '10px' }}>
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
                : null}
            {isSearch && !isGetLoading && search.length !== 0 ?
                <Container className="searchResults" >
                    <Row><h4 style={{ marginLeft: "20px" }}>Searching for :<span className="search-result-text">"{searchedFor}"</span></h4></Row>

                    {/* <h4>Search result for "{this.state.searchedFor}"</h4> */}

                    {searchFound ?
                        <div style={{ height: "110vh" }}>
                            <Row  >
                                {searchResult.map((data, index) =>
                                    //  (console.log("",data))
                                    <Col xs={4}>
                                        <div className="showItem" key={index} xs={4} onClick={() => showClickHandler(data.shortUrl, data.id)} >
                                            <Card key={index + 3} className="showCard">
                                                <Card.Img className="showImg" variant="top" src={data.featuredImageUrl} />
                                                <h4 className="showTitle">{data.title}</h4>
                                            </Card>
                                            {/* <img alt="" src={data.featuredImageUrl} className="ShowImage" /> */}

                                            {/* <h4 className="showTitle">{data.type}</h4> */}
                                            {/* <p style={{ fontSize: "13px", marginRight: "100%" }}>{data.type}</p> */}
                                        </div>
                                    </Col>


                                )}
                            </Row>
                        </div>

                        :
                        <p>No Data Found</p>
                    }

                </Container>
                :
                null
            }

            {!isSearch || search.length === 0
                ?
                <Container className="searchGet" style={{ padding: '0', margin: '0' }}>
                    <div>
                        {
                            props.searchData.map((data, index) =>
                                <MobiSection key={index} data={data} type={data.sectionType} getDetails={getContentDetails} />
                            )
                        }
                    </div>
                </Container>
                : null}
            {listening ? <div style={{
                zIndex: 99,
                opacity: 0.8,
                position: "fixed",
                top: 0,
                left: 0,
                zindex: 99,
                width: "100vw",
                height: "100vh",
                backgroundColor: "#000"
            }}><div style={{ padding: "30px", color: "white" }}>
                    {transcript === "" ?
                        <div style={{ marginTop: "50%" }}>
                            <h4 style={{ color: "white", fontSize: "12px" }}>Listening...</h4>
                            <div style={{ textAlign: 'center', marginTop: "10%" }}>
                                <img src={VoiceGif} alt="voice" width="150" />
                            </div>
                        </div>
                        :
                        <div style={{ marginTop: "50%" }}>
                            <h4 style={{ color: "white", fontSize: "12px" }}>Searching for :<span className="search-result-text">"{transcript}"</span></h4>
                            <div style={{ textAlign: 'center', marginTop: "10%" }}>
                                <img src={VoiceGif} alt="voice" width="150" />
                            </div>
                        </div>
                    } </div></div> : null}
        </Container>
    );
}



export default withRouter(Search);