import React, { Component } from "react";
import Logo from "../../../img/logo.png";
import GuestStyle from "./css/guest_style.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from "react-bootstrap";
import { withRouter } from "react-router";
import AmitMalik from "./img/clients/Being-CEO-Ep-Amit-Malik.jpg";
import AdsBanner from "./img/clients/banner.jpg";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

class GuestAmitabhBehar extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="guest-header">
          <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
        </div>
        <body className="guest-body">
          <Container className="guest-sections">
            <Row>
              <Col>
                <img
                  src={
                    "https://storage.googleapis.com/prod-aawz2-bckt/optimised/episode/epi_featured_image_a0bc05.webp"                    
                  }
                  alt="GuestAmitabhBehar"
                  className="guest-img"
                />
              </Col>
              <Col>
                <Card className="mb-2 card bg-dark text-white">
                  <Card.Title className="m-2 text-white font-weight-bold">
                    Amitabh Behar, CEO, Oxfam India
                  </Card.Title>
                  <h6 className="m-2 font-weight-bold text-muted">
                    Being CEO with Deepali Naair
                  </h6>
                  <Card.Text className="m-2 text-justify text-muted">
                    Amitabh Behar, Chief Executive Officer of Oxfam India, is a
                    global civil society leader, and an authority on tackling
                    economic and gender inequality. From being the son of IAS
                    officers to wearing a kurta and jeans to a UN General
                    Assembly, he's always been one that has never lost sight of
                    the things that really matter and goes on to call
                    inequalities in society an “economic violence”. In this
                    episode, Mr.Behar talks to Deepali about why he does what he
                    does, how privilege blinds us to reality and what keeps his
                    resilience untouched in an arena where change is often slow
                    to come by.
                  </Card.Text>
                  <AudioPlayer
                    src="https://storage.googleapis.com/prod-aawz2-bckt/episode/epi_80d729.mp3"
                    layout="horizontal"
                    showJumpControls={false}
                    customVolumeControls={[]}
                    onPlay={(e) => console.log("onPlay")}
                    // other props here
                  />
                  <Card.Text>Last updated 3 mins ago</Card.Text>
                </Card>
                <img
                  src={AdsBanner}
                  alt="Guest page ads banner"
                  onClick={() =>
                    this.props.history.push(
                      "/show/being-ceo-with-deepali-naair"
                    )
                  }
                  style={{ cursor: "pointer" }}
                />
              </Col>
            </Row>
          </Container>
          <section id="body"></section>
        </body>
      </React.Fragment>
    );
  }
}

export default withRouter(GuestAmitabhBehar);
