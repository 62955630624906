import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Nav,
  Navbar,
  Col,
  Dropdown,
  ToggleButtonGroup,
  Button,
} from "react-bootstrap";

class footerSocial extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <Col lg={6} md={6} className="socio-sec">
            <ul className="footer-menu">
              <NavLink exact activeClassName="activeheader" to="/about-us">
                <span className="headerHover">About</span>
              </NavLink>
              <NavLink exact activeClassName="activeheader" to="/terms-of-use">
                <span className="headerHover">Terms of use</span>
              </NavLink>
              {/* <NavLink
                exact
                activeClassName="activeheader"
                to="/alexa-skill-terms-of-use"
              >
                <span className="headerHover">Alex Terms of use</span>
              </NavLink> */}
              <NavLink
                exact
                activeClassName="activeheader"
                to="/privacy-policy"
              >
                <span className="headerHover">Privacy Policy</span>
              </NavLink>

              {/* <NavLink
                exact
                activeClassName="activeheader"
                to="/alexa-skill-privacy-policy"
              >
                <span className="headerHover">Alex Privacy Policy</span>
              </NavLink> */}
              <NavLink exact activeClassName="activeheader" to="/disclaimer">
                <span className="headerHover">Disclaimer</span>
              </NavLink>
              <NavLink exact activeClassName="activeheader" to="/faq">
                <span className="headerHover">FAQ</span>
              </NavLink>
              {/* <a href="https://www.aawaz.com/media/" target="_blank" rel="noopener noreferrer">
                                <li>Media</li></a> */}
              <a
                href="https://business.aawaz.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>Business</li>
              </a>
              <NavLink exact activeClassName="activeheader" to="/contact">
                <span className="headerHover">Contact</span>
              </NavLink>
              <NavLink exact activeClassName="activeheader" to="/sitemap">
                <span className="headerHover">Sitemap</span>
              </NavLink>
            </ul>
          </Col>
          <Col lg={6} md={6} className="copyright-sec">
            <ul className="footer-menu">
              <li>
                Copyright © 2022 <span className="brand-name">aawaz</span>. All
                Rights Reserved.
              </li>
            </ul>
          </Col>
        </div>
      </React.Fragment>
    );
  }
}

export default footerSocial;
