import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  play: false,
  Adplay: false,
};

const userSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setPlay: (state, action) => {
      state.play = action.payload;
    },
    setPause: (state, action) => {
      state.play = action.payload;
    },
    setAdplay: (state, action) => {
      state.Adplay = action.payload;
    },
    setAdPause: (state, action) => {
      state.Adplay = action.payload;
    },
  },
});

export const { setPause, setPlay, setAdplay, setAdPause } = userSlice.actions;

export const selectPlayPause = (state) => state.player.play;
export const selectAdPlayPause = (state) => state.player.Adplay;

export default userSlice.reducer;
