import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Sameergarde from './img/clients/GuestSameergarde.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestSameergarde extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={Sameergarde} alt="Hiralchandrana" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Sameer Garde, President, Cisco India & SAARC
                                    </Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted"> Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        Having worked for some of India's most illustrious companies like Nestle, Pepsi, Whirlpool and Dell, his success is an attestation to his career trajectory. However that's not all, he has also had some stints at startups and ran a consultancy of his own. Currently, the President at Cisco India and SAARC Sameer believes every workplace has had an immense contribution to making him the leader he is right now. Tune in as he chats about culture building, ambition, conflict resolution and his interesting take on competition.


                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2021/05/Ep.-26-Sameer-Garde-Deepali-26.-05-mins.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestSameergarde);