import React from 'react'
import ContentLoader from 'react-content-loader'
import { Row, Col, Container } from 'react-bootstrap'

function SinglereadLoader(props) {

    const rows = 1
    const columns = 1
    const coverHeight = 495
    const coverWidth = 1055
    const padding = 0

    const coverHeightWithPadding = coverHeight + padding
    const coverWidthWithPadding = coverWidth + padding
    const initial = 1
    const covers = Array(columns * rows).fill(1)

    return (
        <div>
            <Container className="cat_page-content">
                <ContentLoader
                    className="page-title"
                    speed={1}
                    backgroundOpacity={0.1}
                    foregroundOpacity={0.3}
                    width={"100%"}
                    height={70}
                    // foregroundColor="#808080"
                    backgroundColor="#808080"
                    // baseUrl={"/"}
                    {...props}
                >
                    <rect
                        x="0"
                        y="0"
                        rx="10"
                        ry="10"
                        width={"75%"}
                        height="20"
                    />


                </ContentLoader>
                <Row>

                    <Col className="category-grid2" >

                        <ContentLoader
                            speed={1}
                            backgroundOpacity={0.1}
                            foregroundOpacity={0.3}
                            width={"100%"}
                            height={rows * coverHeightWithPadding}
                            // foregroundColor="#808080"
                            backgroundColor="#808080"
                            // baseUrl={window.location.pathname === "/" ? "/" : window.location.pathname === "/channels" ? "/channels" : "/category/:id"}
                            {...props}
                        >
                            {covers.map((g, i) => {
                                let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
                                let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
                                return (
                                    <rect
                                        key={i}
                                        x={vx}
                                        y={vy}
                                        rx="10"
                                        ry="10"
                                        width={coverWidth}
                                        height={coverHeight}
                                    />
                                )
                            })}
                        </ContentLoader>
                    </Col>


                    <ContentLoader
                        speed={1}
                        backgroundOpacity={0.1}
                        foregroundOpacity={0.3}
                        width={"100%"}
                        height={300}
                        backgroundColor="#808080"
                        {...props}
                    >
                        {/* <rect x="20" y="20" rx="5" ry="5" width="215" height="240" /> */}
                        <rect x="0" y="10" rx="5" ry="5" width="90%" height="12" />
                        <rect x="0" y="30" rx="5" ry="5" width="90%" height="12" />
                        <rect x="0" y="50" rx="5" ry="5" width="90%" height="12" />
                        <rect x="0" y="70" rx="5" ry="5" width="90%" height="12" />
                    </ContentLoader>
                </Row>
            </Container>
        </div>
    )
}

export default SinglereadLoader
