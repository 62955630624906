import React, { Component } from 'react'
import LandscapeImg from '../../../img/vectors/landscape.png'
import Mobileimg from '../../../img/mobile.png'


class ScreenPage extends Component {
    state = {}
    render() {
        return (
            <div className="screenbg">
                <h1>We're Sorry ...</h1>
                <img src={Mobileimg} alt="Landscape" width={200} />
                <p style={{ fontSize: "12px", padding: "20px" }}>This page don't support mobile resolution. Please open this on desktop to view this page</p>
            </div>
        );
    }
}

export default ScreenPage;