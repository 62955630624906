import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Mainakdhar from './img/clients/GuestMainakdhar.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestMainakdhar extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={Mainakdhar} alt="Hiralchandrana" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Mainak Dhar, MD & CEO, Kimberly-Clark, India & South Asia</Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted"> Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        Mainak Dhar, has spent almost three decades thriving as a successful business leader across diverse markets and geographies. Mainak started his career with Procter & Gamble, spending almost 18 years there before joining General Mills India as Managing Director. Today Mainak is Managing Director & CEO at Kimberly-Clark India & South Asia. A proficient business leader by day and best-selling author by night (17 to his name), he is someone who believes ideas can transcend many boundaries. He also firmly believes de-cluttering is essential to focus on what matters, not all conflict is bad and average leaders provide feedback on the ‘how’ while he believes in the ‘why’. Tune in to more leadership insights!
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2020/10/Ep-14-_-Mainak-and-Deepali-_-18-Oct-_-30m.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestMainakdhar);