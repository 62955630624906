import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom';


const ET = () => {

    useEffect(() => {
        window.location.href = ' https://www.aaw.az/et/';
    }, [])
    return (
        <div></div>
    )
}

export default withRouter(ET)