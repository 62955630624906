import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import AlokBansal from './img/clients/Being-CEO-Ep-Alok-Bansal.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestChandrakant extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>

                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={"https://storage.googleapis.com/prod-aawz2-bckt/optimised/episode/epi_featured_image_8f4a18.webp"} alt="Alok-Bansal" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Chandrakant Nayak, President & CEO, DOW India</Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted"> Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        Our guest this week almost never made it to college and seriously considered opening a food stall after passing his 12th Std Board exams. But life had other, much grander plans for this brilliant student (gold medallist) and now CEO of DOW Chemical International Pvt. Ltd. (DOW India).
                                        Tune into this episode of BeingCEO to hear Mr.Chandrakant Nayak speak to host Deepali Naair as they share anecdotes, lessons in humility and stories of grit and determination. Mr.Nayak talks about his career spanning three decades, his love for music, and shares his advice for overcoming office politics while climbing the rungs of corporate India.
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/prod-aawz2-bckt/episode/epi_3eb502.mp3"
                                        layout="horizontal" showJumpControls={false} customVolumeControls={[]} onPlay={e =>
                                            console.log("onPlay")}
                                    // other props here
                                    />
                                    <Card.Text>Last updated 3 mins ago</Card.Text>

                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestChandrakant);