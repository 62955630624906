import React, { useState, useEffect } from 'react';
import Thumbnail from '../../../img/thumbnail.jpg';
import './read-responsive.css';
import axios from 'axios'
import { useHistory } from 'react-router';
import { withRouter, Link } from 'react-router-dom';
import AllreadLoader from '../../HomeSection/HomeComponent/Loader/AllreadLoader';
import LngIcin from '../../../img/language-icon.svg'
import { DropdownButton, Dropdown, ToggleButtonGroup, Row, Col, Container } from 'react-bootstrap';
import auth from '../../../services/authentication'
import ShowMoreText from 'react-show-more-text';
import InfiniteScroll from "react-infinite-scroll-component";
import { ClipLoader } from 'react-spinners'


const ReadPage = () => {
    const [Data, setData] = useState([])
    const [Cat, setCat] = useState([])
    const [id, setid] = useState(420)
    const history = useHistory()
    const [Loader, setLoader] = useState(true)
    const [lang, setlang] = useState("hi")
    const [hasMore, sethasMore] = useState(false)
    const [TotalCount, setTotalCount] = useState(0)
    const [Next, setNext] = useState("")

    const OnLoad = (id) => {
        // https://prod.aawaz.com/aawaz_blog/read-v2/?language=${lang}&category_slug=${slug}
        axios.get(`https://prod.aawaz.com/aawaz_blog/read-v2/?language=${lang}&category_id=${id}`, { auth: auth })
            .then((res) => {
                // console.log("read data", res.data.results)
                setData(res.data.results)
                setTotalCount(res.data.count)
                res.data.next !== null ? sethasMore(true) : sethasMore(false)
                res.data.next !== null ? setNext(res.data.next) : setNext("")
                setLoader(false)
            })
            .catch((err) => {
                console.log("read featch err", err)
            })
    }
    useEffect(() => {
        OnLoad(id)
    }, [id])
    useEffect(() => {
        setLoader(true)
        axios.get(`https://prod.aawaz.com/aawaz_blog/category/?language=${lang}`, { auth: auth })
            .then((res) => {
                // console.log("read data", res.data)
                setCat(res.data.results)
            })
            .catch((err) => {
                console.log("read featch err", err)
            })
        lang === "hi" ? OnLoad(420) : OnLoad(421)
    }, [lang])
    const handleUserLangChange = (value) => {
        setlang(value)
    }
    const fetchMoreData = () => {
        axios.get(`${Next}`, { auth: auth })
            .then((res) => {
                // console.log("read data", res.data)
                setData(Data.concat(res.data.results))
                setTotalCount(res.data.count)
                res.data.next !== null ? sethasMore(true) : sethasMore(false)
                res.data.next !== null ? setNext(res.data.next) : setNext("")
                setLoader(false)
            })
            .catch((err) => {
                console.log("read featch err", err)
            })
    }
    return (
        <div>
            <div className="readCtegory">
                <div className="ReadFilterContainer" >
                    <span className="ReadFilter" onClick={() => handleUserLangChange("hi")} style={{ border: lang === "hi" ? "1px solid #eb1163" : "1px solid lightgray", cursor: "pointer", color: lang === "hi" ? "#eb1163" : "#666666" }}>Hindi</span> <br />
                    <span className="ReadFilter" onClick={() => handleUserLangChange("en")} style={{ border: lang === "hi" ? "1px solid lightgray" : "1px solid #eb1163", cursor: "pointer", color: lang === "hi" ? "#666666" : "#eb1163" }}>English</span>
                </div>
                <div className='readmenuContainer'>
                    {Cat.map((item) => (
                        <div className='readMenu' key={item.categoryId}>
                            {/* <NavLink exact activeClassName="activeheader" to="/"><span className="headerHover">Home</span></NavLink> */}
                            <h4 key={item.categoryId} onClick={() => setid(item.categoryId)} style={{ borderBottom: id === item.categoryId ? "1px solid white" : "none" }}>{item.categoryName}</h4>
                        </div>
                    ))}
                </div>
            </div>
            {Loader ? <AllreadLoader /> :
                <div className="SingleReadwrapper">
                    <div className="container">
                        <div className='mobile-only'>
                            {Data.map((item) => (
                                <Row key={item.id}>
                                    <Col lg={3} xs={12}>
                                        <div className="ReadCardwrapper" key={item.categoryId} onClick={() => history.push(item.language === "hi" ? `/read/${item.categorySlug}/${item.articleSlug}` : `/${item.language}/read/${item.categorySlug}/${item.articleSlug}`)}>
                                            <span className="read-category-name">
                                                <p>{item.categoryName}</p>
                                            </span>
                                            <img onLoad={(e) => { e.target.src = item.articleImage }} src={Thumbnail} onError={(e) => { e.currentTarget.src = Thumbnail }} className="Readpost-img" alt="" />
                                            <div className="post-mid-band">
                                                <h2 className="Readpost-name">{item.articleTitle}</h2>
                                                <p className="Readauthor-name">{item.articleAuthor}<span className="pubdate">{item.pubdate}</span> </p>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>

                            ))}
                        </div>

                        <InfiniteScroll
                            dataLength={Data.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            loader={<p style={{ textAlign: "center" }}>
                                <ClipLoader />
                            </p>}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                            className='desktop-only'
                        >
                            <Container>
                                <Row>
                                    {Data.map((item) => (
                                        <Col lg={4} xs={6} key={item.id}>
                                            <div className="card ReadCardwrapper" key={item.categoryId} onClick={() => history.push(item.language === "hi" ? `/read/${item.categorySlug}/${item.articleSlug}` : `/${item.language}/read/${item.categorySlug}/${item.articleSlug}`)}>
                                                <span className="read-category-name">
                                                    <p>{item.categoryName}</p>
                                                </span>
                                                <img onLoad={(e) => { e.target.src = item.articleImage }} src={Thumbnail} onError={(e) => { e.currentTarget.src = Thumbnail }} className="Singlepost-img" alt="" />
                                                <div className="post-mid-band">
                                                    <p className="Readauthor-name">{item.articleAuthor}<span className="pubdate">{item.pubdate}</span> </p>
                                                </div>
                                                <div className="singelRead-description">
                                                    <h2 className="Readpost-name">{item.articleTitle}</h2>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                        </InfiniteScroll>
                    </div>
                </div>
            }
        </div >
    )
}

export default withRouter(ReadPage)