import React, { Component } from "react";
import Logo from "../../../img/logo.png";
import GuestStyle from "./css/guest_style.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from "react-bootstrap";
import { withRouter } from "react-router";
import AlokBansal from "./img/clients/Being-CEO-Ep-Alok-Bansal.jpg";
import AdsBanner from "./img/clients/BeingFoundersBanner.jpg";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

class GuestAshishMunjal extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="guest-header">
          <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
        </div>

        <body
          className="guest-body"
          style={{ backgroundColor: "rgb(119 169 255)" }}
        >
          <Container className="guest-sections" style={{ padding: "53px" }}>
            <Row>
              <Col>
                <img
                  src={
                    "https://storage.googleapis.com/prod-aawz2-bckt/optimised/episode/epi_featured_image_6c6079.webp"
                  }
                  alt="Alok-Bansal"
                  className="guest-img"
                  style={{ height: "450px", width: "450px" }}
                />
              </Col>
              <Col>
                <Card className="mb-2 card bg-dark text-white">
                  <Card.Title className="m-2 text-white font-weight-bold">
                    Ashish Munjal, Co-Founder & CEO, Sunstone
                  </Card.Title>
                  <h6 className="m-2 font-weight-bold text-muted">
                    {" "}
                    Being CEO with Deepali Naair
                  </h6>
                  <Card.Text
                    className="m-2 text-justify text-muted"
                    style={{ fontSize: "14px" }}
                  >
                    Ashish Munjal is the Co-founder & CEO of Sunstone, a higher
                    education organization working to provide Accountable and
                    Industry-Integrated Education. With an MBA from IMT
                    Ghaziabad and a CFA from the USA, he's acquired diverse
                    experience in various financial and consulting roles over
                    his 12 year long corporate tenure. In this episode, Deepali
                    talks to him about the importance of carrying on lessons of
                    company culture into new-age businesses and the underlying
                    principles that drove him to build what Sustone is today.
                  </Card.Text>
                  <AudioPlayer
                    src="https://storage.googleapis.com/prod-aawz2-bckt/episode/epi_6c6079.mp3"
                    layout="horizontal"
                    showJumpControls={false}
                    customVolumeControls={[]}
                    onPlay={(e) => console.log("onPlay")}
                    // other props here
                  />
                  <Card.Text>Last updated 3 mins ago</Card.Text>
                </Card>
                <img
                  src={AdsBanner}
                  alt="Guest page ads banner"
                  onClick={() =>
                    this.props.history.push(
                      "/show/being-founders-with-deepali-nair"
                    )
                  }
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    // height: "95px",
                    border: "2px solid #EBEBEB",
                    borderRadius: "8px",
                    boxShadow: "3px 5px 7px #ebebeb",
                  }}
                />
              </Col>
            </Row>
          </Container>
          <section id="body"></section>
        </body>
      </React.Fragment>
    );
  }
}

export default withRouter(GuestAshishMunjal);
