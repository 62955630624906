import React, { Component } from "react";
import Logo from "../../../img/logo.png";
import GuestStyle from "./css/guest_style.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from "react-bootstrap";
import { withRouter } from "react-router";
import AlokBansal from "./img/clients/Being-CEO-Ep-Alok-Bansal.jpg";
import AdsBanner from "./img/clients/BeingFoundersBanner.jpg";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import DesktopHeader from "../../Desktop/Header/DesktopHeader";
class GuestSaminaBano extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
             
        <div className="guest-header">
        <Container>
          <img src={Logo} alt="Aawaz.com logo" className="guest-logo" srcset="Aawaz.com logo" />
        </Container>
        </div>

        <body
          className="guest-body"
          style={{ backgroundColor: "rgb(119 169 255)" }}
        >
          <Container className="guest-sections" style={{ padding: "53px 0" }}>
            <Row>
              <Col>
                <img
                  src={
                    "https://storage.googleapis.com/prod-aawz2-bckt/optimised/episode/epi-a49f0d-featured-image-1667374258.webp"
                  }
                  alt="Alok-Bansal"
                  className="guest-img"
                  style={{ height: "450px", width: "450px" }}
                />
               
              </Col>
              <Col>
                <Card className="mb-2 card bg-dark text-white">
                  <Card.Title className="m-2 text-white font-weight-bold">
                  Samina Bano, Founder & CEO, Rightwalk Foundation
                  </Card.Title>
                  <h6 className="m-2 font-weight-bold text-muted">
                    {" "}
                    Being Founders with Deepali Naair
                  </h6>
                  <Card.Text
                    className="m-2 text-justify text-muted"
                    style={{ fontSize: "14px" }}
                  >
                    Samina Bano has a truly inspirational life story. This engineer and IIM-Bangalore alumna gave up a rewarding career abroad with Deloitte to return to India, to found and lead the RightWalk Foundation which works towards securing free access to education for lakhs of marginalized children, using RTE. In this episode Deepali Naair traces Samina’s personal journey against all odds, talks about her upbringing in a conservative Muslim family, to standing up for her rights early on in childhood to becoming a pioneer in inclusive education with principles from the for-profit world to drive change, one student and school at a time.
                  </Card.Text>
                  <AudioPlayer
                    className="being-founder-player"
                    src="https://storage.googleapis.com/prod-aawz2-bckt/episode/epi-a49f0d-1667374258-mp3.mp3"
                    layout="horizontal"
                    showJumpControls={false}
                    // hasDefaultKeyBindings={true}
                    showDownloadProgress={true}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    onPlay={(e) => console.log("onPlay")}
                    // other props here
                  />
                  <Card.Text>Last updated 3 mins ago</Card.Text>
                </Card>
                <img
                  src={AdsBanner}
                  alt="Guest page ads banner"
                  onClick={() =>
                    this.props.history.push(
                      "/show/being-founders-with-deepali-nair"
                    )
                  }
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    // height: "95px",
                    border: "2px solid #EBEBEB",
                    borderRadius: "8px",
                    boxShadow: "3px 5px 7px #ebebeb",
                  }}
                />
              </Col>
            </Row>
          </Container>
          <section id="body"></section>
        </body>
      </React.Fragment>
    );
  }
}

export default withRouter(GuestSaminaBano);
