import React, { useState, useEffect } from 'react';
import Thumbnail from '../../../img/thumbnail.jpg';
import './read-responsive.css';
import axios from 'axios'
import { withRouter, Link, useHistory } from 'react-router-dom';
import SinglereadLoader from '../../HomeSection/HomeComponent/Loader/SinglereadLoader';
import { Breadcrumb } from 'react-bootstrap'
import auth from '../../../services/authentication'

const SingleRead = (props) => {
    const history = useHistory()
    const [Data, setData] = useState([])
    const [Loader, setLoader] = useState(true)
    let { id } = props.match.params
    let { lang } = props.match.params



    const OnLoad = (id) => {
        axios.get(`https://prod.aawaz.com/aawaz_blog/read-v2/?language=${lang ? "en" : "hi"}&article_slug=${id}`, { auth: auth })
            .then((res) => {
                // console.log(" singel read data", res.data)
                setData(res.data.results)
                setLoader(false)
            })
            .catch((err) => {
                console.log("read featch err", err)
            })
    }
    useEffect(() => {
        OnLoad(id)
    }, [id])
    return (
        <div className="Readwrapper">
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push('/read')}>Read</Breadcrumb.Item>
                <Breadcrumb.Item active>{Data.length !== 0 ? Data[0].categoryName : null}</Breadcrumb.Item>
            </Breadcrumb>
            {Loader ? <SinglereadLoader /> :
                Data.map((item => (
                    <div className="container">
                        <div className="read-menu">
                        </div>
                        {/* <span className="read-category-name">
                            <p>{item.categoryName}</p>
                        </span> */}
                        <h2 class="post-name">{item.articleTitle}</h2>
                        <p className="author-name">{item.articleAuthor} -  <span className="pubdate">{item.pubdate}</span> </p>
                        <img src={item.articleImage} className="post-img" alt="" />
                        <div className='ReadContant' dangerouslySetInnerHTML={{ __html: item.articleContent }} />
                    </div>
                )))

            }
        </div>
    )
}

export default withRouter(SingleRead)
