import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import ApurvaPurohit from './img/clients/Being-CEO-Ep-Apurva.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

class GuestApurvapurohit extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={ApurvaPurohit} alt="Apurva-Purohit" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Apurva Purohit, President, Jagran Group</Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted">Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        One of the most influential businesswomen in India, Apurva Purohit is a paradigm of being a pathbreaker in a male-dominated industry. Before heading The Jagran Group (Dainik Jagran, Mid Day, Inext, Inquilab, and Radio City) as President, where she has been credited with the listing of Radio City on the local stock exchange, Apurva successfully built TV brands like Zoom at Zee Telefilms and launched one of the largest media buying agencies in the country, Lodestar. She has been ranked as one of the Top 30 Most Powerful Women in Business by Business Today and was featured on Fortune India's Most Powerful Women in Business list. In this episode, listen to her unveil secrets on how to thrive in a man's world, what keeps her going, and why she’s never had to write a CV in 30 years.
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2020/07/Ep-2-_-Apoorva-and-Deepali-_-30-June-_-21m.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestApurvapurohit);