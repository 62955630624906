import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Hiralchandrana from './img/clients/GuestHiralchandrana.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestHiralchandrana extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={Hiralchandrana} alt="Hiralchandrana" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Hiral Chandrana,Global CEO Mastek</Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted"> Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        A small town boy from Mangalore, this CEO never considered studying business formally back then but went onto become a Global CEO operating from N.America. Barring a few hiccups like having his visa rejected a couple times, luck has always favoured Hiral Chandrana, Global CEO at Mastek. Hear him talk about his belief in progress over perfection, his advice for aspiring leaders and his take on the events and people that shaped him, in conversation with Deepali Naair on BeingCEO exclusively on Aawaz.
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2021/10/BEINGCEO-MR.-HIRAL-PACKAGED.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestHiralchandrana);