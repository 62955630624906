import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Prabhjeet from './img/clients/GuestPrabhjeet.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestPrabhjeet extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={Prabhjeet} alt="Hiralchandrana" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Prabhjeet Singh - President, Uber India & South Asia

                                    </Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted"> Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        He calls himself an ingenious professional entrepreneur. An alumnus of IIT Kharagpur and IIM-A, Prabhjeet Singh joined Uber in 2015 and has been instrumental in scaling the profitability of the company. Prior to Uber, he spent close to a decade at McKinsey which he believes proved to be a key building block in the elevation of his professional journey. An innovative and strategic leader tune in as Prabhjeet shares the fascinating story of how he landed the job at Uber, his unique approach to conflict resolution, and work-life balance. Prabhjeet also lets you in on invaluable secrets of building an entrepreneur-driven culture within an organisation.
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2021/03/Ep.-25-Prabhjeet-Singh-Deepali-37.25-mins.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestPrabhjeet);