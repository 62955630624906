import React, { useEffect } from 'react'
import { withRouter, Link, matchPath, useParams } from 'react-router-dom';

function EnglishShows(props) {
    let { name, id } = props.match.params

    useEffect(() => {
        props.history.push(`/show/${id}`)
    }, [id])

    return (
        <div>EnglishShows</div>
    )
}

export default withRouter(EnglishShows)