import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Col, Row, Card} from 'react-bootstrap';
import Etbiz from './img/clients/GuestEtbiz.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestEtbiz extends Component {
    state = {}
    render() {
        return (
        <React.Fragment>
           <div className="guest-header">    
                <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
            </div>
            <body className="guest-body">
                <Container className="guest-sections">
                    <Row>
                        <Col>
                            <img src={Etbiz} alt="Alok-Bansal" className='guest-img'/>
                        </Col>
                        <Col>
                            <Card className="mb-2 card bg-dark text-white">
                     
                                    <Card.Title className="m-2 text-white font-weight-bold">Decoding Future Consumers. Now.
</Card.Title>
                                    <Card.Text className="m-2 text-justify text-muted">
                                    The Economic Times Back to Business series hosted a webinar on the theme of 'Post Covid-19 Consumer Behaviour: What Changes; What Doesn't' on 31 July 2020. aawaz.com brings an anecdotal audio-on-demand version of this webinar. The Panellists include:
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2020/09/Ep-1_-ET-_-02-Sept-_-7mins.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                        // other props here
                                    />
                            </Card>
                            <img src={AdsBanner} alt="Guest page ads banner" />
                        </Col>
                    </Row>
                </Container>
           <section id="body">
                
           </section>
            </body>
                
            
        </React.Fragment>
        );
    }
}

export default GuestEtbiz;