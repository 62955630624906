import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Damodarmall from './img/clients/GuestDamodarmall.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestDamodarmall extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={Damodarmall} alt="Alok-Bansal" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Damodar Mall, CEO, Grocery Retail, Reliance
                                    </Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted"> Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        In this episode, we chat with a CEO who rightly calls himself 'Supermarketwala'. His outstanding career journey began with a supermarket chain in 90's, which has since expanded into the multi-store D-Mart chain in western India. He has also been instrumental in shaping Big Bazaar and Food Bazaar models for the Future Group. Retail and consumer behavior are his strengths, he currently heads Reliance Retail as a CEO. A business graduate from IIM, Bangalore where he won a gold medal in marketing, he is someone with an unconventional leadership style. The wit with which he delivers his wisdom is unmissable. Tune into his amazing journey as he shares his golden nuggets.
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2020/12/Being-CEO-Mr.-Damodar-31.56-mins-.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                    <Card.Text>Last updated 3 mins ago</Card.Text>

                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestDamodarmall);