import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import AmitMalik from './img/clients/Being-CEO-Ep-Amit-Malik.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestsAlokRajan extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={AmitMalik} alt="Amit-Malik" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Amit Malik, CEO & MD, Aviva India</Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted">Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        Amit Malik, Chief Executive Officer & Managing director of Aviva India is always filled with the thirst for not just books,but also life. He has led a very unique life, with his father having suffered five bullet injuries and finding himself in the close range of a cross fire while still being a school kid. Having had to carry the baton onwards and forward from a very young age, Amit recounts some of his golden moments and shares how he approaches his goals that lie in contributing to make life insurance and financial literacy more inclusive in India.
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2022/02/BEINGCEO-MR.AMIT-MALIK-PACKAGED-FIN.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                    <Card.Text>Last updated 3 mins ago</Card.Text>

                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestsAlokRajan);