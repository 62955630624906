import React from 'react'
import ContentLoader from 'react-content-loader'
import { Row, Col, Container } from 'react-bootstrap'


function AllreadLoader(props) {
    const rows = 3
    const columns = 4
    const coverHeight = 325
    const coverWidth = 230
    const padding = 40

    const coverHeightWithPadding = coverHeight + padding
    const coverWidthWithPadding = coverWidth + padding
    const initial = 1
    const covers = Array(columns * rows).fill(1)
    return (
        <Container>
            <ContentLoader
                speed={1}
                backgroundOpacity={0.1}
                foregroundOpacity={0.3}
                width={"100%"}
                height={rows * coverHeightWithPadding}
                // foregroundColor="#808080"
                backgroundColor="#808080"
                // baseUrl={window.location.pathname === "/" ? "/" : window.location.pathname === "/channels" ? "/channels" : "/category/:id"}
                {...props}
            >
                {covers.map((g, i) => {
                    let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
                    let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
                    return (
                        <rect
                            key={i}
                            x={vx}
                            y={vy}
                            rx="10"
                            ry="10"
                            width={coverWidth}
                            height={coverHeight}
                        />
                    )
                })}
            </ContentLoader>
        </Container>
    )
}

export default AllreadLoader
