import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Ashutoshgupta from './img/clients/Being-CEO-Ep-Ashutosh-Gupta.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestAshutoshgupta extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={Ashutoshgupta} alt="Alok-Bansal" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Ashutosh Gupta, Country Manager, LinkedIn India</Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted">Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        Ashutosh Gupta is a seasoned leader, an incisive decision-maker with incredibly deep market expertise. He has built a successful career with over 20 years of experience in the IT & Software Services Industry, starting with Infosys, then Cognizant and Google. Currently, Country Manager of LinkedIn India, his startling clarity in life led him to make many unconventional yet successful career pivots. Tune in to Ashutosh sharing his story, why culture is the bedrock of a winning organization, and leading with compassion.
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2020/10/Ep-12-_-Ashutosh-and-Deepali-_-08-Oct-_-38m.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestAshutoshgupta);