import React, { useEffect, useRef } from "react";
import "./LandingPage.css";
import "./MarketingUntoldStory.css";
import "../../../index.css";

import beingCeoLogo from "./img/beingCeoLogo.png";
import BannerImg from "./img/marketingImg/heading.png";
import ListenBtn from "./img/listenbtn.png";
import Max from "./img/marketingImg/clients/max.png";
import Zepto from "./img/marketingImg/clients/zepto.png";
import Palm from "./img/marketingImg/clients/palm.png";
import YourStory from "./img/marketingImg/clients/your-story.png";
import Birla from "./img/marketingImg/clients/birla.png";

// import Angel from "./img/angel.png";
import Author from "./img/Author.png";
import FbIcon from "./img/marketingImg/fb.png";
import TweetIcon from "./img/marketingImg/tweet.png";
import InstaIcon from "./img/marketingImg/insta.png";

import BhuvanaHost from "./img/marketingImg/bhuvana.png";

import PlayBtn from "./img/play-btn.png";
import EpiPlay from "./img/marketingImg/epi-play.png";
import EpiPause from "./img/marketingImg/pause.png";
import InfoBtn from "./img/marketingImg/info-btn.png";
import ReasonBorder from "./img/reason-border.png";
import ReasonBorder1 from "./img/reason-border1.png";
import HostT from "./img/twit.png";
import HostLI from "./img/li.png";
import DarkLogo from "./img/dark-logo.png";
import firebase from "firebase/compat/app";
import { selectPlayPause } from "../../../features/playerSlice";
import { useSelector } from "react-redux";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import LandscapePage from "../ScreenPage/LandscapePage";

const MarketingUntoldStory = (props) => {
  const playerPlay = useSelector(selectPlayPause);
  const pauseClicked = (index) => {
    props.addPlayerShowData(props.data, index);
    props.pauseFromPlaylist();
  };
  const playClicked = async (index, uniqueSlug) => {
    await props.addPlayerShowData(props.data, index);
    await props.handlePlaylist(index, uniqueSlug);
  };
  const OnloadFunc = async () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        props.getShowData("marketing-the-untold-story");
      }
    });
  };
  useEffect(() => {
    OnloadFunc();
  }, []);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4glaon8",
        "template_m6g846p",
        form.current,
        "user_K6WapEtfMMsleufMjprc3"
      )
      .then(
        (result) => {
          toast.success("Email sent successfully !!");
        },
        (error) => {
          toast.error("Email sent failed !! try again later");
          console.log(error.text);
        }
      );
  };
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };
  console.log("props", props);
  return (
    <React.Fragment>
      <Desktop>
        <div className="desktop-only">
          <ToastContainer position="top-center" draggable />
          <section id="musLpheader">
            <div className="container">
              <header className="d-flex flex-wrap justify-content-left py-3 mb-4">
                <a
                  href="/"
                  className="d-flex align-items-left mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
                >
                  <img src={beingCeoLogo} className="lp-logo" />
                </a>
              </header>
              <div className="row full-height align-items-center">
                <div className="col-lg-8">
                  <h1 className="para-bold mbanner-heading">
                  Marketing The Untold Story <br />
                  With Bhuvana Subramanyan
                  </h1>
                  <p className="banner-para para-reg">
                  Secrets from  <br />
                  Marketing Gurus
                  </p>
                  <img
                    src={ListenBtn}
                    alt=""
                    className="banner-btn"
                    onClick={() => props.addPlayerShowData(props.data, 0)}
                  />
                </div>
                <div className="col-lg-6">
                  {/* <img src={BannerImg} className="mus-banner-img" /> */}
                </div>
              </div>
            </div>
          </section>
          <section id="leaders" className="content">
            <h4 className="para-bold title-blue">Brands We’ve Featured</h4>

            <div className="client-img mt-5">
              <img src={Max} />
              <img src={Zepto} />
              <img src={Palm} />
              <img src={YourStory} />
              <img src={Birla} />
            </div>
          </section>
          <section id="mquote" className="content">
            <h4 className="title-blue">Show Promise</h4>
            <p className="sub-title-mar">
            “There’s more to marketing than meets the eye! Host, Bhuvana Subramanyan, interviews top marketers in India to uncover new trends and category truths, demystify go-to-market strategies and discuss the role of data and creativity. Tune in to learn how digital native brands are disrupting traditional approaches to achieve revenue-focused growth, in today’s competitive landscape, only on Marketing, The Untold Story. ”
            </p>
            {/* <img src={Author} className="ppl-img" /> */}
            {/* <p className="blue-author-name">- Alok Ranjan</p>
            <p className="author-bio">
              CEO, Flipcarbon integrated Solutions Pvt. Ltd.
            </p> */}
          </section>
          <section id="player" className="content">
            <div className="container">
              <div className="row">
                <div className="col-5 ">
                  <h3 className="episode-head-title">Featured Episodes</h3>
                  <div className="feature-episode-section">
                    {/* Sanjay */}
                    {props.data.length !== 0
                      ? props.data.episodes.slice(0, 3).map((data, index) => (
                          <div className="row fepisode-list">
                            <div className="col-lg-4">
                              <div className="episode-img vid-clip-sm">
                                <img
                                  src={data.featuredImageUrl}
                                  alt={data.title}
                                />

                                <div className="vid-clip-play">
                                  {/* <img src={PlayBtn} /> */}
                                  {index === props.index &&
                                  props.playerRef.current &&
                                  props.playerRef.current.audio.current.play &&
                                  playerPlay === true &&
                                  props.CurrentAudiofile ===
                                    data.audioFileUrl ? (
                                    <p
                                      style={{
                                        display:
                                          index === props.index
                                            ? "inline-block !important"
                                            : "none",
                                      }}
                                    >
                                      <img
                                        src={EpiPause}
                                        onClick={() => pauseClicked(index)}
                                      />
                                    </p>
                                  ) : (
                                    <p className="desktop-epi-pause">
                                      {" "}
                                      <img
                                        src={PlayBtn}
                                        onClick={() =>
                                          playClicked(index, data.uniqueSlug)
                                        }
                                      />
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-7 episode-content">
                              <h5 className="mepisode-name my-3">{data.title}</h5>
                              {/* <p className="mepisode-description">
                                {index === 0
                                  ? "Former CEO, Raymond"
                                  : index === 1
                                  ? "MD, Twitter India"
                                  : "President, Jagran Group"}
                              </p> */}
                              <span
                                class="dropdownLanding-container-feature"
                                tabindex="-1"
                              >
                                <span className="m-more-link">more</span>
                                <div class="dropdownLandingFeature">
                                  <div style={{ width: "100%" }}>
                                    {" "}
                                    {data.shortDescription}
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="col-7">
                  <h3 className="episode-head-title2">All Episodes</h3>
                  {/* episode list */}
                  <div className="mepisode-section">
                    {props.data.length !== 0
                      ? props.data.episodes.map((data, index) => (
                          <div className="row episode-list mb-3">
                            <div className="col-lg-2">
                              <img
                                src={data.featuredImageUrl}
                                className="epi-img"
                              />
                            </div>

                            <div className="col-lg-7">
                              <h5
                                className="mepisode-name-all"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={data.title}
                              >
                                {data.title}
                              </h5>
                              {/* <p
                                className="episode-description"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={data.shortDescription}
                              >
                                {data.shortDescription}
                              </p> */}
                            </div>
                            <div className="col-lg-1">
                              {index === props.index &&
                              props.playerRef.current &&
                              props.playerRef.current.audio.current.play &&
                              playerPlay === true &&
                              props.CurrentAudiofile === data.audioFileUrl ? (
                                <p
                                  style={{
                                    display:
                                      index === props.index
                                        ? "inline-block !important"
                                        : "none",
                                  }}
                                >
                                  <img
                                    src={EpiPause}
                                    className="epi-play-icon"
                                    onClick={() => pauseClicked(index)}
                                  />
                                </p>
                              ) : (
                                <p className="desktop-epi-pause">
                                  {" "}
                                  <img
                                    src={EpiPlay}
                                    className="epi-play-icon"
                                    onClick={() =>
                                      playClicked(index, data.uniqueSlug)
                                    }
                                  />
                                </p>
                              )}
                            </div>
                            <div className="col-lg-1">
                              <span
                                class="dropdownLanding-container"
                                tabindex="-1"
                              >
                                <img src={InfoBtn} className="info-icon" />
                                <div class="dropdownLanding">
                                  <div style={{ width: "100%" }}>
                                    {data.shortDescription}
                                  </div>
                                </div>
                              </span>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="reason" className="content">
            <h4 className="title-blue center reason-header"> Marketing - The Untold Story</h4>
            <img src={ReasonBorder1} className="reason-border" />
            <div className="container">
              <div className="row">
                <div className="col-3">
                  <h3 className="mreason-no-point">1</h3>
                  <h4 className="reason-head2">
                  Learn From the Best
                  </h4>
                  <p className="reason-description">
                  Hear from brand custodians on innovative approaches to acquiring and engaging with audiences to drive awareness, improve brand recall and increase LTV (lifetime value) for customers
                  </p>
                </div>
                <div className="col-3">
                  <h3 className="mreason-no-point">2</h3>
                  <h4 className="reason-head2">Fuel your Ambition</h4>
                  <p className="reason-description">Curious about how modern day marketers are redefining their function in today’s data-driven era? Learn from experts on how they strike the right balance and devise omnichannel strategies to suit different platforms.</p>
                </div>
                <div className="col-3">
                  <h3 className="mreason-no-point">3</h3>
                  <h4 className="reason-head2">Management Lessons Ver 2.0</h4>
                  <p className="reason-description">
                  Marketing, The Untold Story helps demystify jargon and impart useful marketing lessons using anecdotes, case studies, and more. 
                  </p>
                </div>
                <div className="col-3">
                  <h3 className="mreason-no-point">4</h3>
                  <h4 className="reason-head2">CMOs get Candid </h4>
                  <p className="reason-description">
                  Get real-world lessons. Hear from marketing leaders, across industry verticals, discussing their approaches to solving challenges in a conversational, #nofilter style. 
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="mhost">
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <h4 className="mhost-title">Meet Our Host</h4>
                  <h2 className="mhost-name">Bhuvana Subramanyan</h2>
                  <p className="social-host">
                    <img src={HostLI} className="host-l" />
                    <img src={HostT} className="host-t" />
                  </p>
                  <img src={BhuvanaHost} className="host-img" />
                </div>
                <div className="col-6">
                  <p className="host-description-untold-story">
                  Bhuvana Subramanyan is an award winning Chief Marketing Officer with over 26 years of marketing & communications having led teams at Randstad India, IBM, Aptech, BrandComm and Vijay TV. <br/><br/>
                  Her expertise spans campaign creation, employee communication, portfolio and product marketing, events management, evolving marketing strategy, and digital marketing with a focus on customer centricity and revenue generation. She is curious about unearthing first-hand insights on how D2C brands and established companies, on Marketing, The Untold Story, to benefit new and mid-career professionals looking to make a mark within the industry. <br/><br/>
                  This podcast is a result of her passion for marketing and brings together India’s brand creators and marketers who share their marketing stories and cover the Untold part of marketing as well. These guest speakers often have wildly similar yet unique stories that you will benefit from.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="mabout">
            <div className="container">
              <h4 className="title-blue">About aawaz.com</h4>
              <p className="lp-about-para">
              aawaz.com is the <span className="strong-txt">largest</span> spoken-word audio and podcast network offering content in four Indian languages. Live since January 2019, aawaz contains over <span className="strong-txt">1200 hours</span> of high-quality original content in <span className="strong-txt">English, Hindi, Marathi and Urdu,</span> spanning 24 genres, 350+ shows and 8500+ episodes, with <span className="strong-txt">10,00,000 listeners</span> and <span className="strong-txt">6 million+</span> streams every month.
              </p>
            </div>
          </section>
          <section id="mcontact" className="content">
            <div className="container">
              <h4 className="title-blue pb-3" style={{textTransform: "none"}}>What Can We Do Better?</h4>
              <form ref={form} onSubmit={sendEmail} className="contact-form">
                <div className="row">
                  <div className="col-6">
                    <input
                      type="text"
                      placeholder="Your Name"
                      name="name"
                      required
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      placeholder="Your Email id"
                      name="email"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      placeholder="Comments / Suggestions"
                      name="message"
                      required
                    />
                  </div>
                </div>

                <button type="submit" className="msubmit-btn">
                  TELL US!
                </button>
              </form>
            </div>
          </section>
          <section className="content" id="footer">
            <img src={DarkLogo} className="footer-dark-logo" />
            <ul className="ceo-footer-links">
                <li>
                  <a href="https://aawaz.com/about-us" target="_blank">About Aawaz</a>
                </li>
                <li><a href="https://aawaz.com/contact" target="_blank">Contact Us</a></li>
                <li><a href="https://aawaz.com/terms-of-use" target="_blank">Terms & Conditions</a></li>
                <li><a href="https://aawaz.com/disclaimer" target="_blank">Disclaimer</a></li>
            </ul>
            <ul className="ceo-footer-social-links">
                <li> <a href="https://www.facebook.com/aawazDotCom/" target="_blank"> <img src={FbIcon} className="host-l" /></a></li>
                <li> <a href="https://twitter.com/AawazDotCom" target="_blank"><img src={TweetIcon} className="host-l" /></a></li>
                <li> <a href="https://www.instagram.com/aawazdotcom/" target="_blank"><img src={InstaIcon} className="host-l" /></a></li>
            </ul>
          </section>
        </div>
      </Desktop>
      <Mobile>
        <LandscapePage />
      </Mobile>
      <Tablet>
        <LandscapePage />
      </Tablet>
    </React.Fragment>
  );
};
export default MarketingUntoldStory;
