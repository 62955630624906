import React from "react";
import Search from "./Search";
import { Alert } from "react-bootstrap";

const MobileSearch = (props) => {
  return (
    <div>
      {window.SpeechRecognition !== undefined ||
      window.webkitSpeechRecognition !== undefined ||
      window.SpeechRecognition ? (
        <Search
          searchData={props.searchData}
          getSearchData={props.getSearchData}
        />
      ) : (
        <Alert variant={"danger"}>
          Speech Recognition Is Not Supported In This Browser
        </Alert>
      )}
    </div>
  );
};

export default MobileSearch;
