import React from "react";
import { Card, Image, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Carousel from 'react-multi-carousel';
import "./Dashboard.css";
import "react-multi-carousel/lib/styles.css";
import "./GridStyle.css";
import HomeIcon from "./ic_nav_home.svg";
import ArrowIcon from "./ic_title_caret.svg";
import thumbnail from "../../../img/thumbnail.webp";

const MobiSection = (props) => {
  // let imgHash = Date.now();
  let data = props.data;
  let itemClassName = (type) => {
    switch (type) {
      case "channel":
        return "channelItem";
      case "category":
        return "categoryItem";
      default:
        return "showItem";
    }
  };
  let cardClassName = (type) => {
    switch (type) {
      case "channel":
        return "channelCard";
      case "category":
        return "categoryCard";
      default:
        return "showCard";
    }
  };
  let imgClassName = (type) => {
    switch (type) {
      case "channel":
        return "channelImg";
      case "category":
        return "categoryImg";
      default:
        return "showImg";
    }
  };

  return (
    <div
      className="dashboardDiv"
      style={{
        backgroundColor:
          props.type.name === "channelshows" ? data.color : "transparent",
      }}
    >
      {props.type.name !== "channelshows" ? (
        <div className="container">
          <h2 className="sectionName" style={{ paddingLeft: "0px" }}>
            {data.name}
            {/* <span className="arrow-tip"> <img alt="" src={HomeIcon} className="audioIcon" /> </span><img alt="" src={ArrowIcon} className="arrowIcon" /> */}
          </h2>
          {data.name !== "Categories" && data.name !== "Channels" ? (
            <Link
              style={{ marginTop: "-18px" }}
              className="view-all-link"
              to={`/view-all/${props.index}`}
            >
              View All
            </Link>
          ) : null}
          <h4 className="titlen2">{data.name} </h4>
        </div>
      ) : null}

      <div className="wrapper">
        <>
          {props.type.name === "channelshows" ? (
            <div
              className="channelShows"
              style={{ backgroundColor: data.color }}
            >
              <Image className="channelShowsImage" src={data.thumbnailUrl} />
              <button
                onClick={() => props.getShowData(data.items[0].shortUrl, true)}
                className="listenBtn"
                style={{
                  color: "black",
                  backgroundColor: "white",
                  position: "absolute",
                  left: "5px",
                  bottom: "5px",
                }}
              >
                <i className="fas fa-play" style={{ color: "black" }}></i>{" "}
                Listen Now
              </button>
            </div>
          ) : null}
          {data.items.map((item, index) => (
            <div
              className={itemClassName(props.type.name)}
              key={item.uniqueSlug}
              onClick={() => props.getDetails(props.type.name, item)}
            >
              <Card
                key={item.uniqueSlug}
                className={cardClassName(props.type.name)}
                style={{
                  backgroundColor:
                    props.type.name === "category"
                      ? item.backgroundColor
                      : "white",
                }}
              >
                <Card.Img
                  variant="top"
                  onLoad={(e) => {
                    e.target.src =
                      item.featuredImageUrl ||
                      item.bannerImageUrl ||
                      item.iconImageUrl;
                  }}
                //   onError={(e) => {
                //     e.currentTarget.src = thumbnail;
                //   }}
                  className={imgClassName(props.type.name)}
                  src={thumbnail}
                />
              </Card>
              <h4
                style={{
                  color: props.type.name === "channelshows" ? "white" : "black",
                }}
                className={
                  props.type.name !== "channel"
                    ? "seachCatTitle"
                    : "channelTitle"
                }
              >
                {item.title || item.name}
              </h4>
            </div>
          ))}
        </>
      </div>
    </div>
  );
};

export default MobiSection;
