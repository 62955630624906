import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Col, Row, CardImg, Container } from "react-bootstrap";
// import AgoraRTC from "agora-rtc-sdk";
// import classes from "./shows.module.css"
import axios from "axios";
import auth from "../../../services/authentication";
// import liveimg from './img/live.gif'
// import pauseimg from './img/pause.png'
// import logo from './img/aawaz-280.png'
import { getAllShows, CheckLive, BroLive } from "./services/liveservices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowMoreText from "react-show-more-text";
// import moment from 'moment';
import ReactGA from "react-ga";
import { timers } from "jquery";
import firebase from "firebase/compat/app";
import LiveLoader from "../../HomeSection/HomeComponent/Loader/LiveLoader";
import MobileLiveLoader from "../../HomeSection/HomeComponent/Loader/MobileLiveLoader";
// import Share from '../../Share'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { isMobile } from "react-device-detect";

ReactGA.initialize("UA-122455299-1", { standardImplementation: true });
function ShowList(props) {
  const [data, setdata] = useState([]);
  const [EndCont, setEndCont] = useState(true);
  const [Loading, setLoading] = useState(true);
  const [StramLive, setStramLive] = useState([]);

  // const OnloadFunc = async () => {
  //     setLoading(true)
  //     firebase.auth().onAuthStateChanged(user => {
  //         if (user) {
  //             props.getShowData("bro")
  //             // setLoading(props.isLoading)
  //         }
  //         // setLoading(false)
  //     }
  //     )

  // }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    getAllShows()
      .then((res) => {
        setdata(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    // OnloadFunc()
    StreamFun();
  }, []);
  const StreamFun = async () => {
    setLoading(true);
    await axios
      .get(`https://prod.aawaz.com/web/dashboard-v2/sec-511b87/`, {
        auth: auth,
      })
      .then((res) => {
        setStramLive(res.data.items);
        // console.log("most popular", res.data.items);
        setLoading(false);
      })
      .catch((error) => {
        // console.log("most popular", error);
        setLoading(false);
      });
  };
  function alertAndPush() {
    var r = window.confirm("Press a button!");
    if (r === true) {
    } else {
    }
  }
  const Golive = (title, uniqueSlug) => {
    CheckLive()
      .then((res) => {
        let data = res.data.data.channels;

        let data1 = data.filter((item) => {
          return item.channelName === title;
        });
        if (data1.length !== 0) {
          let cha = data1[0].channelName;
          let usercount = data1[0].userCount;
          if (title == cha) {
            usercount > 0
              ? history.push(`/live/${uniqueSlug}`)
              : toast.dark("Stream Not Started Yet !!");
          }
        } else {
          toast.dark("Stream Not Started Yet !!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Timer = (id, start) => {
    if (id !== null && start !== "") {
      // console.log("enter", id, start)
      //  Set the date we're counting down to
      var countDownDate = new Date(start * 1000).getTime();
      // Update the count down every 1 second
      var x = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime();
        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        // Output the result in an element with id="demo"

        let day1 = document.getElementById(`${id + "day1"}`);
        let day2 = document.getElementById(`${id + "day2"}`);
        let hours1 = document.getElementById(`${id + "hours1"}`);
        let hours2 = document.getElementById(`${id + "hours2"}`);
        let mint1 = document.getElementById(`${id + "mint1"}`);
        let mint2 = document.getElementById(`${id + "mint2"}`);
        let sec1 = document.getElementById(`${id + "sec1"}`);
        let sec2 = document.getElementById(`${id + "sec2"}`);
        // console.log("ids", Ids)
        if (day1 !== null) {
          day1.innerHTML = parseInt(days / 10);
          day2.innerHTML = days % 10;
          hours1.innerHTML = parseInt(hours / 10);
          hours2.innerHTML = parseInt(hours % 10);
          mint1.innerHTML = parseInt(minutes / 10);
          mint2.innerHTML = minutes % 10;
          sec1.innerHTML = parseInt(seconds / 10);
          sec2.innerHTML = seconds % 10;
          // setEndCont(true)
          if (
            parseInt(days / 10) + (days % 10) <= 0 &&
            parseInt(hours / 10) + parseInt(hours % 10) <= 0 &&
            parseInt(minutes / 10) + (minutes % 10) <= 0 &&
            parseInt(seconds / 10) + (seconds % 10) <= 0
          ) {
            let table = document.getElementById(`${id + "table"}`);
            // console.log("table", table)
            if (table !== null) {
              table.style.display = "none";
              let button = document.getElementById(`${id + "button"}`);
              button.style.display = "inline";
            }
          } else {
            // console.log("tes2")
          }
        } else {
          return;
        }

        // If the count down is over, write some text
      }, 1000);
    }
  };

  const history = useHistory();
  return (
    <div className="liveContainer">
      {Loading ? (
        <div style={{ textAlign: "center" }}>
          {isMobile ? <MobileLiveLoader /> : <LiveLoader />}
        </div>
      ) : (
        <Row>
          {data.map((result, i) => (
            <>
              {result.shows.map((item, i) => {
                return (
                  <Col lg={6} xs={12} md={6}>
                    <Card className="live-show-grid">
                      <Row>
                        <Col lg={5} xs={6} md={6}>
                          <img
                            src={item.featuredImageUrl}
                            alt=""
                            className="live-show-img"
                          />
                          <div className="schedule-box">
                            <span className="time-section">
                              {/* {item.startTime} */}
                              {new Date(item.beginTime * 1000)
                                .toDateString()
                                .slice(0, 3)}
                              ,
                              {new Date(item.beginTime * 1000)
                                .toDateString()
                                .slice(7, 10)}
                              {new Date(item.beginTime * 1000)
                                .toDateString()
                                .slice(3, 7)}
                            </span>
                            <span className="time-section-Hour">
                              {((new Date(item.beginTime * 1000).getHours() +
                                11) %
                                12) +
                                1}
                              {item.startTime.slice(
                                item.startTime.length - 2,
                                item.startTime.length
                              )}
                            </span>
                          </div>
                        </Col>
                        <Col lg={7} xs={6} md={6}>
                          <h2 className="live-show-title">{item.title}</h2>
                          <p className="live-show-description">
                            <ShowMoreText
                              /* Default options */
                              lines={2}
                              more="Read more"
                              less="Show less"
                              expanded={false}
                              className="showDetails-style"
                              keepNewLines={false}
                              anchorClass="showDetails"
                              width={205}
                            >
                              {item.description}
                            </ShowMoreText>
                          </p>
                          {/* {result.name === "Upcoming Shows" || EndCont ? */}
                          <>
                            <table
                              class="table-borderless"
                              id={`${item.currentSlug + "table"}`}
                            >
                              <tr
                                className="live-timer"
                                id={`${item.currentSlug}`}
                              >
                                <td id={`${item.currentSlug + "day1"}`}>
                                  {Timer(item.currentSlug, item.beginTime)}
                                </td>
                                <td id={`${item.currentSlug + "day2"}`}>
                                  {Timer(item.currentSlug, item.beginTime)}
                                </td>
                                <td id={`${item.currentSlug + "hours1"}`}>
                                  {Timer(item.currentSlug, item.beginTime)}
                                </td>
                                <td id={`${item.currentSlug + "hours2"}`}>
                                  {Timer(item.currentSlug, item.beginTime)}
                                </td>
                                <td id={`${item.currentSlug + "mint1"}`}>
                                  {Timer(item.currentSlug, item.beginTime)}
                                </td>
                                <td id={`${item.currentSlug + "mint2"}`}>
                                  {Timer(item.currentSlug, item.beginTime)}
                                </td>
                                <td id={`${item.currentSlug + "sec1"}`}>
                                  {Timer(item.currentSlug, item.beginTime)}
                                </td>
                                <td id={`${item.currentSlug + "sec2"}`}>
                                  {Timer(item.currentSlug, item.beginTime)}
                                </td>
                              </tr>
                              <tr className="live-timer-days">
                                <td colspan="2">Day</td>
                                <td colspan="2">Hour</td>
                                <td colspan="2">Min</td>
                                <td colspan="2">Sec</td>
                              </tr>
                            </table>
                            {/* <button className="reminder-btn">Set Reminder</button> */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                              }}
                            >
                              <p className="live-show-description">Share </p>
                              <FacebookShareButton url={item.deepUrl}>
                                <FacebookIcon
                                  size={20}
                                  round={true}
                                  logoFillColor={"black"}
                                  iconBgStyle={{ fill: "white" }}
                                />
                              </FacebookShareButton>
                              <WhatsappShareButton
                                url={item.deepUrl}
                                className="mobile-only"
                              >
                                <WhatsappIcon
                                  size={20}
                                  round={true}
                                  logoFillColor={"black"}
                                  iconBgStyle={{ fill: "white" }}
                                />
                              </WhatsappShareButton>
                              <LinkedinShareButton url={item.deepUrl}>
                                <LinkedinIcon
                                  size={20}
                                  round={true}
                                  logoFillColor={"black"}
                                  iconBgStyle={{ fill: "white" }}
                                />
                              </LinkedinShareButton>
                              <TwitterShareButton url={item.deepUrl}>
                                <TwitterIcon
                                  size={20}
                                  round={true}
                                  logoFillColor={"black"}
                                  iconBgStyle={{ fill: "white" }}
                                />
                              </TwitterShareButton>
                              <CopyToClipboard
                                style={{
                                  cusor: "pointer",
                                  fontSize: "15px",
                                  marginTop: "4px",
                                }}
                                text={`${item.deepUrl}`}
                                onCopy={() =>
                                  toast.info("Link copied to clipboard!")
                                }
                              >
                                <i class="fas fa-share-alt"></i>
                              </CopyToClipboard>
                            </div>
                          </>
                          {/* : */}
                          <>
                            <button
                              className="live-join-btn"
                              id={`${item.currentSlug + "button"}`}
                              style={{ display: "none" }}
                              onClick={() =>
                                Golive(item.title, item.uniqueSlug)
                              }
                            >
                              Join Now
                            </button>
                          </>
                          {/* } */}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </>
          ))}
          <>
            {StramLive.length !== 0
              ? StramLive.map((item, i) => {
                  return (
                    <Col lg={6} xs={12} md={6}>
                      <Card
                        className="live-show-grid"
                        key={item.id}
                        style={{ marginBottom: "100px" }}
                      >
                        <Row>
                          <Col lg={5} xs={6} md={6}>
                            <img
                              src={item.featuredImageUrl}
                              alt=""
                              className="live-show-img"
                            />
                            {/* <div className="schedule-box">
                                                    <span className="time-section">
                                                        Sat, 20 Nov
                                                    </span>
                                                    5 PM
                                                </div> */}
                          </Col>
                          <Col lg={7} xs={6} md={6}>
                            <h2
                              className="live-show-title"
                              style={{ fontWeight: "900" }}
                            >
                              {item.title}
                            </h2>
                            <p className="live-show-description">
                              {item.title === "BIG Radio LIVE"
                                ? "Listen to BIG FM's BIG RADIO Online and get entertained by fresh, trendy and relatable content which will inspire you to lead a better life!"
                                : item.title === "महाशिवरात्रि - सदगुरु के साथ"
                                ? "महाशिवरात्रि भारत के पवित्र त्योहारों में से एक बड़ा और महत्वपूर्ण त्योहार है। यह त्योहार महादेव की कृपा, शक्ति, आस्था और माता पार्वती के साथ उनके विवाह की वर्षगाँठ का प्रतीक है। इस महाशिवरात्रि की रात को, जो इस वर्ष की सबसे गहरी रात भी है..."
                                : ""}
                            </p>
                            {item.isLive ? (
                              props.CurrentAudiofile === item.audioUrl ? (
                                <button
                                  className="live-join-btn"
                                  onClick={() =>
                                    props.getEpisodeByUniqueSlug(
                                      "",
                                      item.uniqueSlug,
                                      item.shortUrl
                                    )
                                  }
                                >
                                  <i class="fa fa-pause" aria-hidden="true"></i>{" "}
                                  PLAYING{" "}
                                </button>
                              ) : (
                                <button
                                  className="live-join-btn"
                                  onClick={() =>
                                    props.getEpisodeByUniqueSlug(
                                      "",
                                      item.uniqueSlug,
                                      item.shortUrl
                                    )
                                  }
                                >
                                  <i class="fa fa-play" aria-hidden="true"></i>{" "}
                                  Listen Now{" "}
                                </button>
                              )
                            ) : (
                              <button className="live-join-btn">
                                <i class="fa fa-play" aria-hidden="true"></i>{" "}
                                Listen Now{" "}
                              </button>
                            )}
                            {/* <button className="live-join-btn" onClick={() => props.handleBrolive(props.data.episodes)}><i class="fa fa-play" aria-hidden="true"></i>  Listen Now </button> */}
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })
              : null}
          </>
        </Row>
      )}
    </div>
  );
}

export default ShowList;
