import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backBtn from "../../../../img/icons/ic_back_dark.svg";

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_4glaon8', 'template_m6g846p', form.current, 'user_K6WapEtfMMsleufMjprc3')
            .then((result) => {
                toast.success("Email sent successfully !!")
            }, (error) => {
                toast.error("Email sent failed !! try again later")
                console.log(error.text);
            });
    };

    return (
        <Container className="page-content contact-section">
            <h2 className="page-header">Let's Talk</h2>
            <Row>
                <Col lg={6} xs={12}>
                    <p className="page-para">The largest spoken-word audio and podcast network in Indian languages.<br />
                        भारतीय भाषाओं के बीच सबसे बड़ा मौखिक कार्यक्रम का मंच। <br />
                        भारतीय भाषांमधील सर्वात मोठा श्राव्य मंच।</p>
                    <br />
                    <h2 className="page-header">Say Hello</h2>
                    <p className="page-para">
                        <br />
                        <strong className="sub-header">Sreeraman Thiagarajan<br />
                            Tel: </strong><a href="tel:+91-9769020148">+91-9769020148</a> <br />
                        <strong className="sub-header">Mail: </strong> <a href="mailto:sreeraman@aawaz.com">sreeraman@agrahyah.com</a><br />
                        For business, licensing, and advertising.<br />
                        कार्य (बिज़नेस), लाइसेंस, और विज्ञापन के लिए।<br />
                        व्यवसाय, परवाना आणि जाहिरातींसाठी।
                        <br /><br />
                        <strong className="sub-header"> Rushabh Vasa<br />
                            Tel: </strong><a href="tel:+91-9819880110">+91-9819880110</a> <br />
                        <strong className="sub-header">Mail: </strong> <a href="mailto:rushabh@aawaz.com">rushabh@agrahyah.com</a><br />
                        For creators, artiste, and producers.<br />
                        सृजक, कलाकारों, या निर्माता के लिए।<br />
                        कलाकार आणि निर्मात्यांसाठी।
                        <br /><a href="https://www.aawaz.com/">aawaz.com</a> is a product of <a href="http://agrahyah.com/" target="_blank">Agrahyah Technologies Pvt. Ltd.</a></p>
                </Col>
                <Col lg={6} xs={12}>
                    <h2 className="page-header">Contact Us </h2>
                    Fields marked with an <span className="asterisk"> *</span> are required
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="form-group">
                            <label htmlFor="exampleInputName">Name<span className="asterisk"> *</span></label>
                            <input type="text" className="form-control form-control-lg" name="name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail">Email<span className="asterisk"> *</span></label>
                            <input type="email" className="form-control form-control-lg" name="email" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputLocation">Location</label>
                            <input type="text" className="form-control form-control-lg" name="location" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputLanguage">Language</label>
                            <input type="text" className="form-control form-control-lg" name="language" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputContact">Contact No</label>
                            <input type="number" className="form-control form-control-lg" name="contactNumber" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputProfile">Profile<span className="asterisk"> *</span></label>
                            <select className="form-control form-control-lg" name="profile" required >
                                <option value="writer" defaultValue>Writer</option>
                                <option value="voice-over-artist">Voice Over Artist</option>
                                <option value="content-creator">Content Creator</option>
                                <option value="sound-editor">Sound Editor</option>
                                <option value="business">Business</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPortfolio">Portfolio Link</label>
                            <input type="text" className="form-control form-control-lg" name="portfolioLink" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputSubject">Subject<span className="asterisk"> *</span> </label>
                            <input type="text" className="form-control form-control-lg" name="subject" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputMessage" required >Message<span className="asterisk"> *</span> </label>
                            <textarea id="exampleInputMessage" className="form-control form-control-lg" name="message" required></textarea>
                        </div>



                        <button type="submit" style={{
                            backgroundColor: "#eb1163",
                            color: "white",
                            borderRadius: "35px",
                            padding: "10px 21px",
                            textTransform: "uppercase",
                            fontSize: "14px",
                            border: "none"
                        }}>Submit</button>
                    </form>
                </Col>
            </Row>

        </Container>

    );
};

export default Contact;