import React from "react";
import { Navbar, Tab, Tabs, Nav, Dropdown, Col, Row } from "react-bootstrap";
import { NavLink, matchPath } from "react-router-dom";
import Logo from "../../../img/logo.png";
// import Logo from "../../../img/festive/diwali/diwali-logo.gif"; 
// import { Nav, Navbar, Col, Dropdown, ToggleButtonGroup, Button } from 'react-bootstrap'
import userLogin from "../../../img/user-profile.svg";
import "../../../css/pages.css";
import { withRouter } from "react-router-dom";
import LiveBtn from "../../../img/live-g-icon.gif";
import languageIcon from "../../../img/icons/language-icon.svg";
import readIcon from "../../../img/read-pink.png";
import readPink from "../../../img/svg/read-pink.png";
import readGrey from "../../../img/svg/read-grey.png";
import languageActiveIcon from "../../../img/icons/language-active.svg";
import SearchIcon from "./ic_nav_search.svg";
import ProfileIcon from "./ic_nav_profile.svg";
import ActiveProfileIcon from "./ic_nav_profile_active.svg";
import ActiveSearchIcon from "./ic_nav_search_active.svg";
import FlagLogo from "../../../img/flagLogo.jpeg";
import firebase from "firebase/app";
import { YoloProvider } from "react-googleyolo";
import GanpatiLogo from "../../../img/GaneshFestival/Ganpatilogo7.png";
import Garland from "../../../img/Janmastmi/Garland2.png";

const HeaderBrand = (props) => {
  let pushTo = (tab) => {
    switch (tab) {
      case "Category":
        // props.history.push('/nav-tab/0')
        // props.history.push(`/Search`)
        break;
      case "Channel":
        // props.history.push(`/nav-tab/1`)
        props.history.push(`/Search`);
        break;
      case "Live":
        // props.history.push(`/nav-tab/2`)
        props.history.push(`/Profile`);
        break;
      default:
        props.history.push("/");
    }
  };
  // pushTo(e.target.name)
  return (
    <div
      className="mobile-only header-nav"
      style={{
        display: !!matchPath(
          props.location.pathname,
          "/show/:name1/episode/:name2"
        )
          ? "none"
          : "inherit",
      }}
    >
      <Navbar bg="white" variant="white" className="sticky-navbar mobile-only">
        {/* <React.Fragment> */}
        <Nav
          className="mobile-only nav"
          activeKey="/"
          exact="true"
          onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
        >
          <div
            style={{
              width: "87vw",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Nav.Item>
                <NavLink to="/" exact={true}>
                  <img
                    src={window.location.pathname === "/" ? Logo : Logo}
                    alt="Home"
                    width="124px"
                  />
                </NavLink>
              </Nav.Item>
            </div>
            {/* <Nav.Item  style={{ padding: "10px 0px" }}>
                                <NavLink to="/read" exact={true} >
                                    <img src={window.location.pathname === "/read" ? readPink : readGrey} alt="Home" width="90"/>
                                </NavLink>
                            </Nav.Item> */}
            <div className="mobi-right-bar">
              {/* <Col xs={2}> */}
              <Nav.Item style={{ padding: "10px 0px" }}>
                <NavLink to="/Search" exact={true}>
                  <img
                    src={
                      window.location.pathname === "/Search"
                        ? ActiveSearchIcon
                        : SearchIcon
                    }
                    alt="Home"
                  />
                </NavLink>
                {/* <NavLink to="/nav-tab/2" exact={true} > */}
                <span
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Select your Language"
                  onClick={() => props.userLangPrefBottomPrompt()}
                  style={{ cursor: "pointer", paddingRight: "21px" }}
                >
                  {" "}
                  <img
                    src={
                      props.defaultUserLangPref
                        ? props.defaultUserLangPref.length >= 1 &&
                          props.defaultUserLangPref.length < 4
                          ? languageActiveIcon
                          : languageIcon
                        : languageIcon
                    }
                    alt="img"
                  />
                </span>
                {/* </NavLink> */}
                <NavLink to="/Profile" exact={true}>
                  <img
                    src={
                      window.location.pathname === "/Profile"
                        ? ActiveProfileIcon
                        : ProfileIcon
                    }
                    alt="Profile"
                  />
                </NavLink>
              </Nav.Item>
              {/* <Nav.Item>
                                </Nav.Item> */}
              {/* </Col> */}
            </div>
          </div>
        </Nav>
        {/* </React.Fragment> */}
      </Navbar>
    </div>
  );
};

export default withRouter(HeaderBrand);
