import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import AlokRajan from './img/clients/Being-CEO-Ep-Alok-Ranjan.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestsAlokRajan extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={AlokRajan} alt="Alok-Bansal" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-whites">

                                    <Card.Title className="m-2 text-white font-weight-bold">Best of BeingCeo - The Anniversary Episode</Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted"> Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        It’s been One year! Tune into this special anniversary episode of BeingCEO as we showcase management lessons from some of India's best known business leaders. Packed with laughs, memorable life lessons and insights as our host Deepali Naair recounts and celebrates a year of connecting with guests and looks back at the best moments on the show, putting her journey on the spotlight this time around!
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2021/08/BEING-CEO-BUMPER-EP-FINAL.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                    <Card.Text>Last updated 3 mins ago</Card.Text>

                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                </body>




            </React.Fragment>
        );
    }
}

export default withRouter(GuestsAlokRajan);