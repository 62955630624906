import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import AlokBansal from './img/clients/Being-CEO-Ep-Alok-Bansal.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestsAlok extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={AlokBansal} alt="Alok-Bansal" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Alok Bansal, MD & CEO, BFSI Business, Visionet Systems</Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted"> Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        Alok Bansal's career trajectory testifies to the fact that clarity and planning lead to success. An IIT Bombay and Havard Business School alumnus, Alok is someone who believes in creating tech-driven solutions to solve complex problems. He started his career with Reliance and has held leadership positions in IT firms like Altisource and XL Dynamics. With more than two decades of experience in developing businesses by leading strategy management, financial services, and global BPM operations Alok is someone who has mastered a unique leadership approach. We chat with him about his journey from being a chemical engineer to leading a software company, his hiring philosophy, entrepreneurial culture and more.
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2021/06/Ep-29-_-Alok-_-28.06-32-mins.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                    <Card.Text>Last updated 3 mins ago</Card.Text>

                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestsAlok);