import React, { Component } from 'react';
import Logo from '../../../img/logo.png';
import GuestStyle from './css/guest_style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Nandinidias from './img/clients/GuestNandinidias.jpg';
import AdsBanner from './img/clients/banner.jpg';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


class GuestNandinidias extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <div className="guest-header">
                    <img src={Logo} alt="Aawaz.com logo" srcset="Aawaz.com logo" />
                </div>
                <body className="guest-body">
                    <Container className="guest-sections">
                        <Row>
                            <Col>
                                <img src={Nandinidias} alt="Hiralchandrana" className='guest-img' />
                            </Col>
                            <Col>
                                <Card className="mb-2 card bg-dark text-white">

                                    <Card.Title className="m-2 text-white font-weight-bold">Nandini Dias, CEO, Lodestar UM

                                    </Card.Title>
                                    <h6 className="m-2 font-weight-bold text-muted"> Being CEO with Deepali Naair</h6>
                                    <Card.Text className="m-2 text-justify text-muted">
                                        Touted as one of the most prominent faces in India’s advertising industry, Nandini Dias is currently the CEO of Lodestar UM, a leading marketing agency in the country. Nandini has the distinction of having launched over 400 brands in media and managed media investments for over 175 clients. She garnered her credentials as an influential businesswoman with the strategic acumen she developed in a well-orchestrated professional journey. Tune in to the inside scoop of her leadership style and how she maintains a work-life balance.
                                    </Card.Text>
                                    <AudioPlayer
                                        src="https://storage.googleapis.com/aawaz-stateless/2020/07/Ep-3_-Nandini-and-Deepali-_-02-July-_-29m.mp3"
                                        layout="horizontal"
                                        showJumpControls={false}
                                        customVolumeControls={[]}
                                        onPlay={e => console.log("onPlay")}
                                    // other props here
                                    />
                                </Card>
                                <img src={AdsBanner} alt="Guest page ads banner" onClick={() => this.props.history.push('/show/being-ceo-with-deepali-naair')} />
                            </Col>
                        </Row>
                    </Container>
                    <section id="body">

                    </section>
                </body>


            </React.Fragment>
        );
    }
}

export default withRouter(GuestNandinidias);